import { useRecoilValue } from 'recoil';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useSchemaToFormFields } from '../../../util/schema-utils';
import { FieldsWrapper, usePanelPagination } from '../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { selectedFundStateFP } from '../state/FPState';
import { Panel } from '../../CompanyProfiles2/Summary/Panel';
import { Fund } from '../../../schemas/Fund.schema';
import { FDMap } from '../../../util/data-structure/FDMap';
import { createForm } from '../../../view-models/form.view-model';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';
import { useUpdateFund } from '../state/useUpdateFund';
import { useFPDigestSchema } from './useFPDigestSchema';

export function FPDigestPanel() {
  const schemaToFormFields = useSchemaToFormFields();
  const fund = useRecoilValue(selectedFundStateFP);
  const updateFund = useUpdateFund();

  const schema = useFPDigestSchema(fund);
  const fields = schemaToFormFields(schema);
  const { paginationConfig, visibleFields } = usePanelPagination({ fields, itemsPerPage: 10 });

  const methods = useForm({
    values: fund ?? undefined,
  });

  const onChange = useCallback(
    (data: Partial<Fund>) => {
      if (!fund) return;
      updateFund({ ...fund, ...data });
    },
    [fund, updateFund]
  );

  const form = createForm<Fund>({
    fields: FDMap.fromArray(visibleFields, 'key'),
    variant: 'form-inline',
  });

  return (
    <Panel title={'Digest'} paginationConfig={paginationConfig} showContentOverflow={true}>
      <FormProvider {...methods}>
        <FieldsWrapper style={{ minHeight: '22rem' }}>
          <FormFactoryNoLayout form={form} onChange={onChange} />
        </FieldsWrapper>
      </FormProvider>
    </Panel>
  );
}
