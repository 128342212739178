import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { fundDataStateFP, selectedFundStateFP } from '../state/FPState';
import { FundSettingsButton } from '../FundForm/FundSettingsButton';
import { FundFormModal } from '../FundForm/FundFormModal';
import { useUpdateFund } from '../state/useUpdateFund';
import { toFundViewModel } from '../../../schemas/FundViewModel.schema';
import { Fund } from '../../../schemas/Fund.schema';
import { useFundMetricsFP } from '../useFundMetricsFP';
import { FundMetricsSummary } from './FundMetricsSummary';
import { WaterfallModelling } from './WaterfallModelling';

export function FPWaterfall() {
  const fund = useRecoilValue(selectedFundStateFP);
  const fundMetrics = useFundMetricsFP();
  useRecoilValue(fundDataStateFP(fund?.id ?? -1));
  if (!fund || !fundMetrics) return null;
  return (
    <>
      <FundSettings fund={fund} />
      <FundMetricsSummary fundMetrics={fundMetrics.metrics} />
      <WaterfallModelling />
    </>
  );
}

function FundSettings({ fund }: { fund: Fund }) {
  const updateFund = useUpdateFund();
  const defaultValues = useMemo(() => toFundViewModel(cloneDeep(fund)), [fund]);
  return (
    <>
      <FundSettingsButton />
      <FundFormModal
        onSubmit={updateFund as (fund: Partial<Fund>) => Promise<Fund>}
        defaultValues={defaultValues}
      />
    </>
  );
}
