import { createContext, FC, useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ComponentWithChildren, TransactionAudit } from '../../../types';
import { useCompanyMetrics } from '../../../services/queries-hooks/MaggieMetricsQueriesHooks';
import {
  useCompanyTransactions,
  useTransactionHistory,
} from '../../../services/queries-hooks/MaggieTransactionQueriesHooks';
import { ICompanyMetricsDataModel } from '../../../data-models/metrics.data-model';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';

interface FinanceHistoryContextInterface {
  isLoading: boolean;
  isFetching: boolean;
  companyId: number;
  transactions: ITransactionDataModel[] | undefined;
  activeTransaction: ITransactionDataModel | null;
  transactionHistory: TransactionAudit[] | undefined;
  companyMetrics: ICompanyMetricsDataModel | null;
}

const FinanceHistoryContext = createContext<FinanceHistoryContextInterface | undefined>(undefined);

const FinanceHistoryDataProviderImp: FC<ComponentWithChildren> = ({ children }) => {
  const { companyId: compId } = useParams();
  const [search] = useSearchParams();
  const transId = search.get('transaction');
  const companyId = useMemo(() => (compId ? Number(compId) : 0), [compId]);
  const activeTransactionId = useMemo(() => (transId ? transId : null), [transId]);

  const {
    isLoading: isLoadingTransactions,
    isFetching: isFetchingTransactions,
    data: transactions,
  } = useCompanyTransactions(companyId);

  const {
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
    data: transactionHistory,
  } = useTransactionHistory(companyId);

  const {
    isLoading: isLoadingMetrics,
    isFetching: isFetchingMetrics,
    data: companyMetrics,
  } = useCompanyMetrics(companyId);

  const activeTransaction = useMemo(() => {
    if (!transactions || activeTransactionId === null) return null;
    return transactions.find((t) => String(t.id) === activeTransactionId) ?? null;
  }, [activeTransactionId, transactions]);

  const value = useMemo(
    () => ({
      isLoading: isLoadingHistory || isLoadingMetrics || isLoadingTransactions,
      isFetching: isFetchingHistory || isFetchingMetrics || isFetchingTransactions,
      companyId,
      activeTransaction,
      transactionHistory,
      companyMetrics: companyMetrics || null,
      transactions,
    }),
    [
      activeTransaction,
      companyId,
      companyMetrics,
      isFetchingHistory,
      isFetchingMetrics,
      isFetchingTransactions,
      isLoadingHistory,
      isLoadingMetrics,
      isLoadingTransactions,
      transactionHistory,
      transactions,
    ]
  );

  return <FinanceHistoryContext.Provider value={value}>{children}</FinanceHistoryContext.Provider>;
};

export const FinanceHistoryDataProvider = FinanceHistoryDataProviderImp as unknown as FC<object>;

export const useFinanceHistoryData = (): FinanceHistoryContextInterface => {
  const ctx = useContext(FinanceHistoryContext);

  if (typeof ctx === 'undefined') {
    throw new Error('useFinanceHistoryData cannot be used outside FinanceHistoryDataProvider');
  }
  return ctx;
};
