import * as yup from 'yup';
import { greaterThenMsg, positiveNumMsg, requiredMsg, typeMsg } from '../../utils/validationMessages';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import {
  integerField,
  percentField,
  usdField,
  pricePerShareField,
} from '../../../../../../../schemas/common-schema-defs';

export function tokenSaftFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    investmentType: yup.string().optional(),
    pricePerShare: pricePerShareField()
      .required()
      .positive(positiveNumMsg('Price Per Token'))
      .nullable()
      .typeError(typeMsg('Price Per Token', 'number')),
    noOfShares: integerField()
      .typeError(typeMsg('Shares Purchased', 'number'))
      .required(requiredMsg('Shares Purchased'))
      .min(0, positiveNumMsg('Shares Purchased')),
    valuationCap: usdField().optional().positive(positiveNumMsg('Valuation Cap')).nullable(),
    discountRate: percentField().min(0, greaterThenMsg('Discount rate', 0)).optional().nullable(),
  };
}

export function tokenSaftFormSchema() {
  return yup.object().shape(tokenSaftFields());
}

export function tokenSaftGridSchema() {
  return yup.object().shape(tokenSaftFields()).omit(['investmentType']);
}
