import * as yup from 'yup';
import { positiveNumMsg, requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { usdField } from '../../../../../../../schemas/common-schema-defs';

export function miscPaymentFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema().required(requiredMsg('Payment Date')),
    miscPaymentAmount: usdField().required(requiredMsg('Amount')).positive(positiveNumMsg('Amount')),
    currencyid: transactionCurrencyIdField(),
    miscPaymentType: yup.string().required(requiredMsg('Please choose payment type from the options')),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function miscPaymentFormSchema() {
  return yup.object().shape(miscPaymentFields());
}
