export enum PreferenceScope {
  USER = 'user',
  ORGANIZATION = 'organization',
}

export const PreferenceKey = {
  companyInvestments: 'company-profiles.summary.company-investments',
  companySummaryDetails: 'company-profiles.summary.company-details',
  companySummaryInvestments: 'company-profiles.summary.investments',
} as const;
export type PreferenceKey = (typeof PreferenceKey)[keyof typeof PreferenceKey] | string;

export type PanelPreferences = {
  selectedFields: string[];
};

export interface IPreferencesV3DataModel<T> {
  scope: PreferenceScope;
  preferenceKey: PreferenceKey | string;
  entityId: number | null;
  type: string | null;
  value: T;
}

export function createPreferenceV3DataModel<T>(
  overrides: Partial<IPreferencesV3DataModel<T>> = {}
): IPreferencesV3DataModel<T> {
  return {
    scope: PreferenceScope.USER,
    preferenceKey: '',
    entityId: null,
    type: null,
    value: {} as T,
    ...overrides,
  };
}
