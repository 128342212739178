import * as yup from 'yup';
import { merge } from 'lodash-es';
import { KPIAggFunc } from '../pages/PortfolioOverview/FundReporting/FundsKPIs';
import { FundProfileLinkRenderer } from '../pages/PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/FundProfileLinkRenderer';
import { multiplierField, percentField, usdShortField } from './common-schema-defs';
import { companyFundMetricsFormSchema } from './CompanyFundMetrics.schema';

export function fundMetricsByFundFields() {
  return {
    activeCompanies: yup
      .array()
      .of(companyFundMetricsFormSchema())
      .nullable()
      .label('Active Companies')
      .default([]),
    date: yup.string().required().label('Date').default(''),
    fundId: yup.number().required().label('Fund').default(0),
    metrics: yup.object().shape(fundMetricsFields()).required().default({}),
    totalCompanies: yup.number().default(0),
  };
}

export function fundMetricsByFundFormSchema() {
  return yup.object().shape(fundMetricsByFundFields());
}

export type FundMetricsByFund = yup.InferType<ReturnType<typeof fundMetricsByFundFormSchema>>;

export function createFundMetricsByFund(overrides: Partial<FundMetricsByFund> = {}): FundMetricsByFund {
  return merge({}, fundMetricsByFundFormSchema().getDefault(), overrides);
}

export function fundMetricsByFundGridSchema() {
  const { date, fundId } = fundMetricsByFundFields();
  return yup.object().shape({
    activeCompanies: yup.array().of(companyFundMetricsFormSchema()).required().default([]),
    date,
    fundId: fundId.gridMeta({
      cellRenderer: FundProfileLinkRenderer,
      formatter: 'fund',
    }),

    metrics: fundMetricsGridSchema(),
    totalCompanies: yup.number().required().default(0),
  });
}

export function fundMetricsFields() {
  return {
    activationDate: yup.date().nullable().default(null),
    amountAvailableForLpGpSplit: yup.number().nullable().default(null),
    availableForGpCatchup: yup.number().nullable().default(null),
    availableForSuperReturn: yup.number().nullable().default(null),
    capitalCalledPercent: percentField().nullable().default(null),
    capitalLossRatio: multiplierField().nullable().default(null),
    contributions: usdShortField().required().label('Total Contributions').nullable().default(null),
    contributionsForCalculation: usdShortField().nullable().default(null),
    distributableProceeds: usdShortField()
      .required()
      .label('Distributable Proceeds')
      .nullable()
      .default(null),
    distributions: usdShortField().required().label('Total Distributions').nullable().default(null),
    dpi: multiplierField().required().label('DPI').nullable().default(null),
    escrowReceivable: usdShortField().nullable().default(null).label('Escrow Receivable'),
    fmv: usdShortField().label('Total Unrealized Value').nullable().default(null),
    followOnInvestmentAmount: usdShortField().nullable().default(null).label('Follow-On Investments'),
    gpContributions: usdShortField().required().label('GP Contributions').nullable().default(null),
    gpDistributions: usdShortField().required().label('GP Distributions').nullable().default(null),
    gpIncentive: usdShortField().required().label('GP Incentive (Carry)').nullable().default(null),
    gpIncentiveFromGpCatchup: usdShortField().nullable().default(null),
    gpIncentiveFromLpGpSplit: usdShortField().nullable().default(null),
    gpIncentiveFromSuperReturn: usdShortField().nullable().default(null),
    grossIRR: percentField().nullable().label('Gross IRR').nullable().default(null),
    gpTotal: usdShortField().nullable().default(null),
    grossMOIC: multiplierField().nullable().label('Gross MOIC').nullable().default(null),
    initialInvestmentAmount: usdShortField().nullable().label('Initial Investment').nullable().default(null),
    investmentAmount: usdShortField().nullable().label('Total Invested').nullable().default(null),
    lossRatio: multiplierField().nullable().nullable().default(null),
    lpContributions: usdShortField().required().label('LP Contributions').nullable().default(null),
    lpDistributions: usdShortField().required().label('LP Distributions').nullable().default(null),
    lpNav: usdShortField().required().label('LP NAV').nullable().default(null),
    lpNavFromLpGpSplit: usdShortField().nullable().default(null),
    lpNavFromSuperReturn: usdShortField().nullable().default(null),
    navFromSuperReturnGP: usdShortField().nullable().default(null),
    navFromSuperReturnLP: usdShortField().nullable().default(null),
    navLPPortion: usdShortField().nullable().default(null),
    navGPPortion: usdShortField().nullable().default(null),
    managementFee: usdShortField().required().label('Management Fee').nullable().default(null),
    navFromLpGpSplitGp: usdShortField().nullable().default(null),
    navFromLpGpSplitLP: usdShortField().nullable().default(null),
    netAssets: usdShortField().label('Remaining Cash Balance').nullable().default(null),
    netIRR: percentField().required().label('Net IRR').nullable().default(null),
    netMOIC: multiplierField().required().label('Net MOIC').nullable().default(null),
    netReturn: usdShortField().required().label('Net Return').nullable().default(null),
    realizedGL: usdShortField().nullable().label('Realized Gain/Loss').nullable().default(null),
    rvpi: multiplierField().required().label('RVPI').nullable().default(null),
    totalValue: usdShortField().required().label('Total Value').nullable().default(null),
    ttvpi: multiplierField().required().label('TVPI').nullable().default(null),
    unrealizedGL: usdShortField().nullable().label('Unrealized Gain/Loss').nullable().default(null),
    unrealizedValue: usdShortField().nullable().label('Total Unrealized Value').nullable().default(null),
  };
}

export function fundMetricsFormSchema() {
  return yup.object().shape(fundMetricsFields());
}

export function createFundMetrics(overrides: Partial<FundMetrics> = {}): FundMetrics {
  return merge({}, fundMetricsFormSchema().getDefault(), overrides);
}

export function fundMetricsGridSchema() {
  const baseFields = fundMetricsFields();
  return yup.object().shape({
    ...baseFields,
    totalValue: baseFields.totalValue.gridMeta({
      formatter: 'usdRound',
    }),
    distributableProceeds: baseFields.distributableProceeds.gridMeta({
      formatter: 'usdRound',
    }),
    contributions: baseFields.contributions.gridMeta({
      formatter: 'usdRound',
    }),
    escrowReceivable: baseFields.escrowReceivable.gridMeta({
      formatter: 'usdRound',
    }),
    gpContributions: baseFields.gpContributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    lpContributions: baseFields.lpContributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    distributions: baseFields.distributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    lpDistributions: baseFields.lpDistributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    gpDistributions: baseFields.gpDistributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    managementFee: baseFields.managementFee.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    fmv: baseFields.fmv.gridMeta({
      formatter: 'usdRound',
    }),
    netAssets: baseFields.netAssets.gridMeta({
      formatter: 'usdRound',
    }),
    netReturn: baseFields.netReturn.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    lpNav: baseFields.lpNav.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    netMOIC: baseFields.netMOIC.gridMeta({
      formatter: 'multiplier',
      aggFunc: KPIAggFunc.moic,
    }),
    netIRR: baseFields.netIRR.gridMeta({
      formatter: 'percent2dpAsIs',
      aggFunc: KPIAggFunc.sum,
    }),
    gpIncentive: baseFields.gpIncentive.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    ttvpi: baseFields.ttvpi.gridMeta({
      formatter: 'multiplier',
      aggFunc: KPIAggFunc.tvpi,
    }),
    dpi: baseFields.dpi.gridMeta({
      formatter: 'multiplier',
      aggFunc: KPIAggFunc.dpi,
    }),
    rvpi: baseFields.rvpi.gridMeta({
      formatter: 'multiplier',
      aggFunc: KPIAggFunc.rvpi,
    }),
  });
}
export type FundMetrics = yup.InferType<ReturnType<typeof fundMetricsFormSchema>>;
