import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import {
  createInvestment,
  deleteInvestment,
  updateInvestment,
} from '../../../services/queries/MaggieTransactionQueries';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { allInvestmentRoundsState, roundsByCompanyIdState } from '../state/FinanceState';
import { getErrorMessage } from '../../../services/queryHelpers';

type Action = 'update' | 'delete' | 'create';

export function useInvestmentActions(next?: () => void) {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const updateInvestments = useRecoilCallback(
    ({ set, snapshot }) =>
      (updatedInvestment: IFinanceRoundDataModel, action: Action) => {
        if (snapshot.getLoadable(roundsByCompanyIdState(updatedInvestment.companyId)).state !== 'hasValue')
          return;

        if (action === 'update') {
          set(allInvestmentRoundsState, (oldInvestments) => {
            if (!oldInvestments) return oldInvestments;
            return oldInvestments?.map((inv) => {
              if (inv.id === updatedInvestment.id) {
                return updatedInvestment;
              }

              return inv;
            });
          });
        } else if (action === 'delete') {
          set(allInvestmentRoundsState, (oldInvestments) => {
            if (!oldInvestments) return oldInvestments;
            return oldInvestments?.filter((inv) => inv.id !== updatedInvestment.id);
          });
        } else if (action === 'create') {
          set(allInvestmentRoundsState, (oldInvestments) => {
            if (!oldInvestments) return oldInvestments;
            return [...oldInvestments, updatedInvestment];
          });
        }
      },
    []
  );

  const handleUpdateInvestment = useCallback(
    async (investment: Partial<IFinanceRoundDataModel>) => {
      try {
        const data = await updateInvestment(investment);
        updateInvestments(data, 'update');
        pushSuccessToast({ message: 'Investment updated' });
        return data;
      } catch (error) {
        console.error(error);
        const message = getErrorMessage(error, 'Failed to update investment');
        pushErrorToast({ message });
      } finally {
        next?.();
      }
    },
    [next, pushErrorToast, pushSuccessToast, updateInvestments]
  );

  const handleDeleteInvestment = useCallback(
    async (investment: IFinanceRoundDataModel) => {
      try {
        await deleteInvestment(investment.id);
        updateInvestments(investment, 'delete');
        pushSuccessToast({ message: 'Investment deleted' });
        next?.();
      } catch (error) {
        console.error(error);
        const message = getErrorMessage(error, 'Failed to delete investment');
        pushErrorToast({ message });
      }
    },
    [next, pushErrorToast, pushSuccessToast, updateInvestments]
  );

  const handleCreateInvestment = useCallback(
    async (investment: Partial<IFinanceRoundDataModel>) => {
      try {
        const data = await createInvestment(investment);
        updateInvestments(data, 'create');
        pushSuccessToast({ message: 'Investment successfully created' });

        return data;
      } catch (err) {
        console.log(err);
        const message = getErrorMessage(err, 'Failed to create investment');
        pushErrorToast({ message });

        return null;
      } finally {
        next?.();
      }
    },
    [next, pushErrorToast, pushSuccessToast, updateInvestments]
  );

  return { handleUpdateInvestment, handleDeleteInvestment, handleCreateInvestment };
}
