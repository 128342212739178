import * as yup from 'yup';
import { positiveNumMsg, requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import {
  conversionRatioField,
  integerField,
  usdField,
} from '../../../../../../../schemas/common-schema-defs';

export function noteSafeConversionFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema().required(requiredMsg('Transaction Date')),
    convertedPrincipalAmount: usdField()
      .required(requiredMsg('Converted Principal'))
      .min(0, positiveNumMsg('Converted Principal')),
    convertedSharesNo: integerField()
      .required(requiredMsg('Number of Converted Shares'))
      .positive(positiveNumMsg('Number of Converted Shares')),
    currencyid: transactionCurrencyIdField(),
    fullyDilutedShares: integerField().nullable().optional(),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    postMoneyValuation: usdField().min(0, positiveNumMsg('Post Money Valuation')).optional().nullable(),
    raiseAmount: usdField().min(0, positiveNumMsg('Raise Amount')).optional().nullable(),
    security: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    leadInvestor: yup.string().optional().nullable(),
    coInvestors: yup.array().optional().nullable(),
    conversionRatio: conversionRatioField(),
  };
}

export function noteSafeConversionFormSchema() {
  return yup.object().shape(noteSafeConversionFields());
}

export function noteSafeConversionGridSchema() {
  return yup
    .object()
    .shape(noteSafeConversionFields())
    .omit(['fullyDilutedShares', 'raiseAmount', 'coInvestors', 'leadInvestor', 'postMoneyValuation']);
}
