import { useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { styled } from '@mui/material';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { selectedCompanyIdProfile } from '../state/UIState';
import { CPWrapper } from '../components/CompanyProfilesWrapper';
import { RecentCommentariesPanel } from '../components/ActivityDrawer/Commentaries/RecentCommentariesPanel';
import { Statistics } from './components/Statistics/Statistics';
import { DateSettings, DateSettings2 } from './components/DateSettings/DateSettings';
import { MilestonesSection } from './components/Milestones/MilestonesSection';
import { ObjectivesSection } from './components/Objectives/ObjectivesSection';
import { PerformanceCharts } from './components/Charts/PerformanceCharts';
import { PerfChartsSkeletonLoader } from './components/Charts/PerfChartsSkeletonLoader';
import { Projections } from './components/Projections/Projections';
import { ChartModal } from './components/Charts/ChartModal';
import { RubricSection } from './components/Rubrics/RubricSection';

const HorizontalWrapper = styled('div')`
  margin-bottom: 2rem;
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: min-content;
`;

export const CompanyProfilePerformance = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const { showFinancialsDatePicker, showRubricConfig, showCommentary } = useFlags<MaggieFeatureFlags>();

  return (
    <CPWrapper key={companyId}>
      <GenericFallbacksWrapper>
        <ChartModal />
      </GenericFallbacksWrapper>
      {showFinancialsDatePicker ? <DateSettings2 /> : <DateSettings />}

      <GenericFallbacksWrapper>
        <Statistics />
      </GenericFallbacksWrapper>

      <GenericFallbacksWrapper suspenseFallback={<PerfChartsSkeletonLoader />}>
        <PerformanceCharts />
      </GenericFallbacksWrapper>

      {showRubricConfig && (
        <GenericFallbacksWrapper>
          <RubricSection companyId={companyId} />
        </GenericFallbacksWrapper>
      )}

      <HorizontalWrapper>
        <GenericFallbacksWrapper>
          <Projections />
        </GenericFallbacksWrapper>

        {showCommentary && (
          <GenericFallbacksWrapper>
            <RecentCommentariesPanel companyId={companyId} />
          </GenericFallbacksWrapper>
        )}
      </HorizontalWrapper>

      <GenericFallbacksWrapper>
        <ObjectivesSection />
      </GenericFallbacksWrapper>

      <GenericFallbacksWrapper>
        <MilestonesSection />
      </GenericFallbacksWrapper>
    </CPWrapper>
  );
};
