import { Controller, FieldValue, FieldValues, UseControllerProps, useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { RendererType } from '../../data-models/field.data-model';
import { IFormField } from '../../view-models/form.view-model';
import { FundTypeSelect } from './FundTypeSelect/FundTypeSelect';
import { FieldFactory, hasFieldRenderer } from './FieldFactory';

export interface IFormItemFactoryProps<T> {
  formField: IFormField<unknown>;
  initialValue?: T;
}

export function FormFieldFactory<T extends FieldValues, ValueType>(props: IFormItemFactoryProps<ValueType>) {
  const { setValue } = useFormContext(); // retrieve all hook methods
  const { formField, initialValue = undefined } = props;
  const { key, required, renderer } = formField;

  const setValueFromInput = useCallback(
    (data: T) => {
      setValue(key, data as FieldValue<T>);
    },
    [key, setValue]
  );

  const fieldRules: UseControllerProps['rules'] = {
    required: {
      value: required ?? false,
      message: 'Field is required',
    },
  };

  switch (true) {
    case hasFieldRenderer(renderer):
      return (
        <Controller
          name={key}
          rules={fieldRules}
          render={({ field: reactHookField }) => (
            <FieldFactory formField={formField} formProps={reactHookField} />
          )}
        />
      );
    case renderer === RendererType.fundType:
      return (
        <FundTypeSelect
          fullWidth
          key={key}
          initialValue={initialValue as string}
          handleChange={(data) => {
            setValueFromInput(data as FieldValue<T>);
          }}
        />
      );
    default:
      return <div key={key}>Unknown type ${renderer}</div>;
  }
}
