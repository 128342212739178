import { FinanceHistoryDataProvider } from './providers/FinanceHistoryDataProvider';
import { FinanceHistoryContent } from './components/FinanceHistoryContent';

export const FinanceHistory = () => {
  return (
    <FinanceHistoryDataProvider>
      <FinanceHistoryContent />
    </FinanceHistoryDataProvider>
  );
};
