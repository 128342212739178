import { useSetRecoilState } from 'recoil';
import { IconButton } from '@mui/material';
import { css } from '@emotion/react';
import SettingsIcon from '@mui/icons-material/Settings';
import { showFundFormState } from '../state/FPState';

const SettingsButtonContainer = css`
  height: 0;
  overflow: visible;
  display: grid;
  justify-content: end;
`;

const SettingsButton = css`
  transform: translateY(-100%);
  margin-right: auto;
`;

export function FundSettingsButton() {
  const setOpenFundForm = useSetRecoilState(showFundFormState);

  return (
    <div css={SettingsButtonContainer}>
      <IconButton onClick={() => setOpenFundForm(true)} css={SettingsButton}>
        <SettingsIcon color='secondary' />
      </IconButton>
    </div>
  );
}
