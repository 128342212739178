import { useCallback } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useRecoilValue } from 'recoil';
import { rubricConfigState } from '../../../services/state/RubricsState';
import { RubricConfig } from '../../../schemas/Rubric.schema';
import { NoRubricsMessage } from './NoRubricsMessage';
import { useRubricConfigFormData } from './useRubricConfigFormData';
import { RubricConfigFormModal } from './RubricConfigFormModal';
import { RubricConfigGrid } from './RubricConfigGrid';
import { RubricConfigConfirmDelete } from './RubricConfigConfirmDelete';

export function RubricsConfigLoader() {
  const configs = useRecoilValue(rubricConfigState);
  if (!configs) return null;
  return <RubricsConfig data={configs} />;
}

interface IRubricConfigProps {
  data: RubricConfig[];
}
export function RubricsConfig({ data }: IRubricConfigProps) {
  return (
    <>
      <RubricConfigFormModal />
      <RubricConfigConfirmDelete />
      <Box width={'100%'} p={'1rem 2rem'}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={'1rem'}>
          <Typography variant={'h3'}>Rubrics</Typography>
          <CreateRubricConfig />
        </Stack>
        <Stack>{data.length === 0 ? <NoRubricsMessage /> : <RubricConfigGrid data={data} />}</Stack>
      </Box>
    </>
  );
}

export function CreateRubricConfig() {
  const { setSelectedAction } = useRubricConfigFormData();
  const handleClick = useCallback(() => {
    setSelectedAction('create');
  }, [setSelectedAction]);
  return (
    <Button
      variant={'contained'}
      color={'secondary'}
      startIcon={<AddIcon />}
      size={'large'}
      onClick={handleClick}
    >
      Create Rubric
    </Button>
  );
}
