import { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { IconTypes } from '../../../Icon';
import { ChartTitleWrapper } from '../../ChartTitleWrapper';
import { MappedTransaction } from '../../../../pages/PortfolioOverview/hooks/useMostRecentRounds';
import { ChartWrapper } from '../../ChartWrapper/ChartWrapper';
import { Chart, ChartSizes } from '../../chart-utils';
import { MostRecentRoundsRow } from './MostRecentRoundsRow';

interface Props {
  id: string;
  width: number;
  data: MappedTransaction[];
}

const RowsWrapper = styled('ul')`
  display: grid;
  gap: 0.5rem;
  @supports (grid-template-columns: subgrid) {
    grid-template-columns: 1fr auto 1fr;
    justify-content: space-between;
  }
`;

export function MostRecentRoundsChart({ id, width, data }: Props): ReactElement {
  const [showMore, setShowMore] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [widthState, setWidthState] = useState<string | number>(width);
  const [size, setSize] = useState('1/2 Screen');
  const ref = useRef(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [size]);

  return (
    <ChartWrapper
      id={id}
      onMouseLeave={() => {
        setShowMore(false);
        setIsHovering(false);
      }}
      onMouseEnter={() => setIsHovering(true)}
      dataTestid={'mostRecentRoundsChart'}
      width={widthState}
    >
      <ChartTitleWrapper
        isHovering={isHovering}
        title={'Most Recent Rounds'}
        showMore={showMore}
        setShowMore={setShowMore}
        id={id}
        icon={IconTypes.STATISTICS_1}
        handleSizeChange={setSize}
        size={size}
        refProp={ref}
      />
      <Chart width={'100%'} ref={ref}>
        <RowsWrapper>
          {data?.map((i, k) => (
            <MostRecentRoundsRow
              name={i?.name}
              logoURL={i?.logoURL}
              round={i?.round}
              date={new Date(i?.mostRecent)}
              key={k}
            />
          ))}
        </RowsWrapper>
      </Chart>
    </ChartWrapper>
  );
}
