import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { SelectItem } from '../../../types';
import {
  kpiConfigByIdMapState,
  kpiConfigByKeyMapState,
  kpiConfigState,
  kpiGroupsByIdState,
  universalAndSystemKPIState,
} from '../../../services/state/KPI/KPITemplatesState';
import { sortGroupableOptions } from '../utils/sortGroupableOptions';
import { PreferencesService } from '../../../util/preferences-service';
import { FieldEntity, IField } from '../../../data-models/field2.data-model';
import { PreferencesServiceV3 } from '../../../util/preferences-service-v3';
import { PanelPreferences, PreferenceScope } from '../../../data-models/preferences-v3.data-model';
import { mapIFieldToSelectItem } from '../Performance/components/Statistics/useKpiSelector';

export interface IKpiSelectItem extends SelectItem {
  id: number;
}

export const KPIoptionsState = selector<IKpiSelectItem[]>({
  key: 'KPIoptionsStateCP',
  get: ({ get }) => {
    const config = get(kpiConfigState);
    const kpiGroupsById = get(kpiGroupsByIdState);

    return sortGroupableOptions(
      config.map((ifield) => {
        return {
          id: ifield.id,
          value: ifield.displayName,
          // FIXME MAGGIE-6435: Remove once migration to groupId complete.
          group: kpiGroupsById.get(ifield?.kpiGroupId ?? -1)?.name ?? ifield.gridMeta?.group,
        };
      })
    ) as IKpiSelectItem[];
  },
});

export const allChartKpisState = selector<IField<unknown>[]>({
  key: 'allChartKpisState',
  get: ({ get }) => {
    return get(kpiConfigState).filter(
      (kpi) =>
        kpi.type === 'number' &&
        kpi.formMeta?.renderer?.type !== 'date' &&
        kpi.formMeta?.formatter?.type !== 'date'
    );
  },
});

const defaultChartKpisState = selector<IField<unknown>[]>({
  key: 'defaultChartKpisState',
  get: ({ get }) => {
    return get(allChartKpisState).filter(
      (kpi) => kpi.entity === FieldEntity.universalKPI || kpi.entity === FieldEntity.systemKPI
    );
  },
});

export const selectedChartKpisState = atomFamily<IField<unknown>[], number>({
  key: 'selectedChartKpisState',
  default: selectorFamily({
    key: 'selectedKpiChartsState/Default',
    get:
      (companyId) =>
      ({ get }) => {
        const preferencesState = PreferencesServiceV3.get().getPreferences<PanelPreferences>(
          getPrefKey(companyId, 'selectedKpiCharts')
        );
        const kpisByKey = get(kpiConfigByKeyMapState);
        const userPrefs = preferencesState?.selectedFields.reduce((curr, id) => {
          const kpi = kpisByKey.get(id);
          if (!kpi) return curr;
          return [...curr, kpi];
        }, [] as IField<unknown>[]);

        return userPrefs ?? get(defaultChartKpisState);
      },
  }),
  effects: (companyId) => [
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        if (newValue === oldValue) return;
        const newSelectedFields = newValue.map((kpi) => kpi.entityField);
        PreferencesServiceV3.get().setPreferences<PanelPreferences>(
          getPrefKey(companyId, 'selectedKpiCharts'),
          {
            selectedFields: newSelectedFields,
          },
          PreferenceScope.ORGANIZATION
        );
      });
    },
  ],
});

export const selectedKpisState = atomFamily<IField<unknown>[], number>({
  key: 'selectedKpisStateV3',
  default: selectorFamily({
    key: 'selectedKpisStateV3/Default',
    get:
      (companyId) =>
      ({ get }) => {
        const preferencesState = PreferencesServiceV3.get().getPreferences<PanelPreferences>(
          getPrefKey(companyId, 'selectedKpis')
        );
        const kpisByKey = get(kpiConfigByKeyMapState);
        const userPrefs = preferencesState?.selectedFields?.reduce((curr, id) => {
          const kpi = kpisByKey.get(id);
          if (!kpi) return curr;
          return [...curr, kpi];
        }, [] as IField<unknown>[]);

        return userPrefs ?? get(universalAndSystemKPIState);
      },
  }),
  effects: (companyId) => [
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        if (newValue !== oldValue) {
          const newSelectedFields = newValue.map(({ entityField }) => entityField);
          PreferencesServiceV3.get().setPreferences<PanelPreferences>(
            getPrefKey(companyId, 'selectedKpis'),
            { selectedFields: newSelectedFields },
            PreferenceScope.ORGANIZATION
          );
        }
      });
    },
  ],
});

export const selectedKpisItemState = selectorFamily<IKpiSelectItem[], number>({
  key: 'selectedKpisItemState/Default',
  get:
    (companyId) =>
    ({ get }) => {
      return get(selectedKpisState(companyId)).map(mapIFieldToSelectItem);
    },
  set:
    (companyId) =>
    ({ get, set }, newValue) => {
      const kpisById = get(kpiConfigByIdMapState);
      return set(
        selectedKpisState(companyId),
        (newValue as IKpiSelectItem[]).map((item) => kpisById.get(item.id)!)
      );
    },
});

export const selectedCustomFieldIdsState = atomFamily<number[], FieldEntity>({
  key: 'selectedCustomFieldIdsState',
  default: selectorFamily<number[], FieldEntity>({
    key: 'selectedCustomFieldIdsState/Default',
    get: (entity) => () => {
      const preferencesState = PreferencesService.get().getCurrentPreferences();
      return preferencesState?.modules?.profiles?.selectedEntityFields?.[entity]?.customFieldIds ?? [];
    },
  }),
  effects: (entity) => [
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        if (newValue === oldValue) return;
        PreferencesService.get().updatePreferences(
          `modules.profiles.selectedEntityFields.${entity}.customFieldIds`,
          newValue
        );
      });
    },
  ],
});

export const selectedInvestmentsState = atom<Set<string>>({
  key: 'selectedInvestmentsState',
  default: selector<Set<string>>({
    key: 'selectedInvestmentsState/Default',
    get: () => {
      const userPreferences = PreferencesService.get().getCurrentPreferences();
      return new Set(
        userPreferences?.modules?.profiles?.selectedEntityFields?.[FieldEntity.fundCompany]?.entityKeys ?? []
      );
    },
  }),
  effects: [
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        if (newValue === oldValue) return;
        PreferencesService.get().updatePreferences(
          `modules.profiles.selectedEntityFields.${FieldEntity.fundCompany}.entityKeys`,
          Array.from(newValue)
        );
      });
    },
  ],
});

function getPrefKey(companyId: number, suffix: string) {
  return `company-profiles.$${companyId}.performance.${suffix}`;
}
