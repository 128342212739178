import { useRecoilState, useRecoilValue } from 'recoil';
import { allChartKpisState, selectedChartKpisState } from '../../../state/ProfileViewSettings';
import { sortGroupableOptions } from '../../../utils/sortGroupableOptions';
import { mapIFieldToSelectItem } from '../Statistics/useKpiSelector';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { SelectItem } from '../../../../../types';
import { kpiConfigByIdMapState } from '../../../../../services/state/KPI/KPITemplatesState';

export function useKpiChartsMenu() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const [selectedKpis, setSelectedKPIs] = useRecoilState(selectedChartKpisState(companyId));
  const allChartKpis = useRecoilValue(allChartKpisState);
  const kpisById = useRecoilValue(kpiConfigByIdMapState);
  const chartOptions = sortGroupableOptions(allChartKpis.map((field) => mapIFieldToSelectItem(field)));
  const selectedChartOptions = selectedKpis.map(mapIFieldToSelectItem);

  const handleChange = (newValue: SelectItem[]) => {
    setSelectedKPIs(newValue.map((item) => kpisById.get(item.id as number)!));
  };

  return { chartOptions, selectedChartOptions, handleChange, selectedKpis };
}
