import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { IFundingDataModel } from '../../../../../data-models/company-fundings.data-model';
import { CompanyFinancingHistoryState } from '../../../state/CompanyFundingsState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { companyState } from '../../../../../services/state/CompanyState';
import { isPortfolioCompany } from '../../../../../data-models/company.data-model';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderRow,
  StyledTableRow,
} from '../../../components/Table/StyledGridComponents';
import { ListWithMoreChip } from '../../../Companies/CompanyCard/ListWithMoreChip';
import { RoundNameRenderer } from '../../../../../components/AgTable/cell-renderers/RoundRenderer';
import { FMT } from '../../../../../util/formatter-service';
import { NoDataMessage2 } from '../../../components/Messages/HelpMessage';

const columns = ['Stage', 'Round', 'Date', 'Raised', 'Post-Money', 'Lead Investors', 'Investors'];

const NumericColumns = new Set(['Raised', 'Post-Money', 'FMV', 'MOIC', 'IRR', 'Date']);

export const FinancingHistoryTable: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const isPortfolio = isPortfolioCompany(company!);
  const fundings = useRecoilValue(
    CompanyFinancingHistoryState({
      companyId,
      isPortfolioCompany: isPortfolio,
    })
  );
  const sortedFundings = [...fundings].sort(fundsByDateDescending);

  if (sortedFundings.length === 0) {
    return <NoDataMessage2 />;
  }

  return (
    <StyledTable
      style={{
        marginTop: '0.5rem',
        marginBottom: '1rem',
        gridTemplateColumns: 'repeat(7, 1fr)',
      }}
    >
      <StyledTableHeaderRow>
        {columns.map((c, i) => (
          <StyledTableCell
            key={c}
            style={
              i === 0
                ? { paddingLeft: '0.75rem' }
                : i === columns.length - 1
                  ? { paddingRight: '0.75rem' }
                  : undefined
            }
            type={NumericColumns.has(c) ? 'numeric' : 'text'}
            header
          >
            {c}
          </StyledTableCell>
        ))}
      </StyledTableHeaderRow>

      {sortedFundings.map((data, i) => (
        <StyledTableRow key={i} style={{ padding: '0 0.5rem' }} className='test'>
          <RoundNameRenderer
            name={data.fundingRoundCategory}
            style={{ fontSize: '0.85rem', margin: '0', minWidth: '8rem' }}
          />
          <StyledTableCell type='text' minWidth={'10rem'}>
            {data.fundingRound ?? ''}
          </StyledTableCell>
          <StyledTableCell type='numeric' minWidth={'10rem'}>
            {data.dealDate ? FMT.format('date', data.dealDate) : ''}
          </StyledTableCell>
          <StyledTableCell type='numeric' noWrap>{`${
            data.dealSizeInMillions ? FMT.format('usdShort', data.dealSizeInMillions * 1000000) : ''
          }`}</StyledTableCell>
          <StyledTableCell type='numeric'>{`${
            typeof data.valuationInMillions === 'number'
              ? FMT.format('usdShort', data.valuationInMillions * 1000000)
              : ''
          }`}</StyledTableCell>
          <StyledTableCell>
            <ListWithMoreChip
              title={'Lead Investors'}
              items={data.leadInvestors?.split(/\s*,\s*/) ?? ([] as string[])}
            />
          </StyledTableCell>

          {data.investors?.length ? (
            <ListWithMoreChip
              items={data.investors?.map((investor) => investor.orgName) ?? []}
              title={'Investors'}
              style={{ minWidth: '10rem' }}
            />
          ) : (
            <StyledTableCell />
          )}
        </StyledTableRow>
      ))}
    </StyledTable>
  );
};

export function fundsByDateDescending(funding1: IFundingDataModel, funding2: IFundingDataModel) {
  return new Date(funding2.dealDate).getTime() - new Date(funding1.dealDate).getTime();
}
