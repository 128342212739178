import { useRef, useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { useKPIRequestsFilters } from '../KPIRequests/hooks/useKPIRequestsFilters';
import { kpiTemplateAssignmentsListState } from '../../../../services/state/KPI/KPICompaniesState';
import { KPIRequestsSectorsFilter } from '../KPIFilters/KPIRequestsSectorsFilter';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { KPIRequestsTemplatesFilter } from '../KPIRequests/components/Filters/KPIRequestsTemplatesFilter';
import { KPIRequestsFrequenciesFilter } from '../KPIRequests/components/Filters/KPIRequestsFrequenciesFilter';
import { FilterSection } from '../KPIRequests/components/Filters/FilterSection';
import { useKPICompaniesAllColDefs } from './hooks/useKPICompaniesAllColDefs';
import { useAssignedFilter } from './hooks/useAssignedFilter';

/**@deprecated: replaced by AssignedCompanies */
export function KPICompaniesAllAssignedGrid() {
  const { columnDefs, defaultColDef } = useKPICompaniesAllColDefs();
  const portCompaniesAssigned = useRecoilValue(kpiTemplateAssignmentsListState);

  const {
    filteredRequests,
    selectedTemplates,
    selectedFrequencies,
    selectedSectors,
    onSelectSectorsChange,
    onSelectTemplateChange,
    onSelectFrequenciesChange,
  } = useKPIRequestsFilters(portCompaniesAssigned);
  const gridRef = useRef<AgGridReact>(null);
  const [searchCompany, setSearchCompany] = useState<string>('');
  const { onGridReady } = useAssignedFilter();

  const onSearchCompanyChange = useCallback((value: string) => {
    setSearchCompany(value);
  }, []);

  return (
    <Stack height={'100%'}>
      <Stack direction='row' alignItems='center' marginBottom={'1.5rem'} marginTop={'.5rem'}>
        <FilterSection marginTop='1rem' nFilters={4}>
          <SearchInput
            value={searchCompany}
            onChange={onSearchCompanyChange}
            placeholder='Search for a company'
          />
          <KPIRequestsSectorsFilter value={selectedSectors} onChange={onSelectSectorsChange} />
          <KPIRequestsTemplatesFilter value={selectedTemplates} onChange={onSelectTemplateChange} />
          <KPIRequestsFrequenciesFilter value={selectedFrequencies} onChange={onSelectFrequenciesChange} />
        </FilterSection>
      </Stack>
      <FadeInGridWrapper>
        <AgTable
          ref={gridRef}
          rowData={filteredRequests}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupIncludeTotalFooter={false}
          rowGroupPanelShow='always'
          groupDefaultExpanded={-1}
          suppressMovableColumns={true}
          suppressRowDrag={true}
          onGridReady={onGridReady}
          suppressRowGroupHidesColumns={false}
          quickFilterText={searchCompany}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
