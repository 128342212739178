import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { add, endOfMonth } from 'date-fns';
import {
  selectedEndDateFinancialsState,
  selectedFinancialsRangeState,
  selectedFrequencyFinancialsState,
} from '../../state/CompanyFinancialsDateState';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { KpiPeriod } from '../../../../data-models/company-financials.data-model';
import { companyState } from '../../../../services/state/CompanyState';
import { monthsInPeriod } from '../../Performance/components/DateSettings/DateSettings';
import { companyMetricsByIdState } from '../../../../services/state/CompanyMetricsByIdState';
import {
  defaultRangeForFrequency,
  endOfPreviousFiscalPeriod,
  financialsDateRangeLabel,
} from './financeDatePickerUtils';

export function useFinancialsDateSettings() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const [selectedFrequency, setSelectedFrequency] = useRecoilState(
    selectedFrequencyFinancialsState(companyId)
  );
  const [pastRange, setPastRange] = useState<number | null>(defaultRangeForFrequency(selectedFrequency));
  const [futureRange, setFutureRange] = useState<number | null>(null);
  const endDate = useRecoilValue(selectedEndDateFinancialsState(companyId));
  useSyncRangeAndEndDate(pastRange, futureRange);

  const handleFrequencyChange = useCallback(
    (frequency: KpiPeriod) => {
      setSelectedFrequency(frequency);
      setPastRange(defaultRangeForFrequency(frequency));
      setFutureRange(null);
    },
    [setSelectedFrequency]
  );

  const handleSelectRange = useCallback(
    (selectedRange: number, future: boolean) => {
      if (future) {
        if (futureRange === selectedRange) {
          if (pastRange != null) {
            setFutureRange(null);
          }
        } else {
          setFutureRange(selectedRange);
        }
      } else {
        if (pastRange === selectedRange) {
          if (futureRange != null) {
            setPastRange(null);
          }
        } else {
          setPastRange(selectedRange);
        }
      }
    },
    [futureRange, pastRange]
  );

  const label = financialsDateRangeLabel(selectedFrequency, pastRange, futureRange);

  return {
    label,
    selectedFrequency,
    pastRange,
    futureRange,
    handleSelectRange,
    handleFrequencyChange,
    endDate,
  };
}

function useSyncRangeAndEndDate(pastRange: number | null, futureRange: number | null) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;
  const selectedFrequency = useRecoilValue(selectedFrequencyFinancialsState(companyId));
  const setRange = useSetRecoilState(selectedFinancialsRangeState(companyId));
  const setEndDate = useSetRecoilState(selectedEndDateFinancialsState(companyId));

  const defaultEndDate = useMemo(() => {
    return endOfPreviousFiscalPeriod(fye - 1, selectedFrequency);
  }, [fye, selectedFrequency]);

  useEffect(() => {
    setRange((pastRange ?? 0) + (futureRange ?? 0));
  }, [pastRange, futureRange, setRange]);

  useEffect(() => {
    if (futureRange === null) {
      setEndDate(defaultEndDate);
    } else {
      setEndDate(
        endOfMonth(
          add(defaultEndDate, {
            months: monthsInPeriod[selectedFrequency] * futureRange,
          })
        )
      );
    }
  }, [defaultEndDate, setEndDate, futureRange, pastRange, selectedFrequency]);
}

export function useMostRecentReportDate() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const mostRecentReportDate = useRecoilValue(companyMetricsByIdState(companyId))?.metrics?.at(
    0
  )?.kpiLastReportedDateActual;

  return { company, mostRecentReportDate };
}
