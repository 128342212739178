import { useMemo } from 'react';
import { SchemaDescription } from 'yup';
import { Chip } from '@mui/material';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderRow,
  StyledTableRow,
} from '../../CompanyProfiles/components/Table/StyledGridComponents';
import { FMT } from '../../../util/formatter-service';
import { colors } from '../../../theme/colors';
import { useFundMetricsFP } from '../useFundMetricsFP';
import { WaterfallGridData, getWaterfallData, waterfallGridDataSchema } from './FPWaterfallData';
import { DistributionBar } from './DistributionBar';
import { WaterfallModellingSummaryRow } from './WaterfallModellingSummaryRow';

export function WaterfallModellingGrid() {
  const fundMetrics = useFundMetricsFP();
  const gridData = useMemo(() => {
    if (!fundMetrics) return [];
    return getWaterfallData(fundMetrics.metrics);
  }, [fundMetrics]);
  const fieldDescriptions = useMemo(() => {
    return Object.entries(waterfallGridDataSchema().omit(['lpPortion', 'gpPortion']).fields).map(
      ([fieldKey, value]) => ({
        key: fieldKey as keyof WaterfallGridData,
        desc: value.describe() as SchemaDescription,
      })
    );
  }, []);
  const rows = useMemo(() => {
    if (!gridData) return null;
    return gridData.map((row: WaterfallGridData) => (
      <StyledTableRow key={row.phase}>
        {fieldDescriptions.map((f) => {
          const value = row[f.key];
          let type: 'text' | 'numeric' = 'text';
          let formattedValue = String(value ?? '-');
          if (f.desc.type === 'number') {
            type = 'numeric';
            formattedValue = value != null ? FMT.format(f.desc.meta?.formatter || 'string', value) : '-';
          }
          if (f.key === 'visualization') {
            return <DistributionBar key={f.key} data={row} />;
          }
          if (f.key === 'phase') {
            return (
              <Chip
                key={f.key}
                label={formattedValue}
                style={{ background: colors.tertiary[20], marginLeft: '-0.75rem' }}
              />
            );
          }
          return (
            <StyledTableCell key={f.key} type={type!}>
              {formattedValue}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    ));
  }, [gridData, fieldDescriptions]);
  if (!fundMetrics) return null;

  return (
    <StyledTable style={{ gridTemplateColumns: '1fr repeat(5, 2fr) 3fr' }}>
      <StyledTableHeaderRow>
        {fieldDescriptions.map((f, i) => (
          <StyledTableCell
            key={f.key}
            style={i === 0 ? { paddingLeft: '1rem' } : undefined}
            type={f.desc.type === 'number' ? 'numeric' : 'text'}
            header
          >
            {f.desc.label}
          </StyledTableCell>
        ))}
      </StyledTableHeaderRow>
      {rows}

      <WaterfallModellingSummaryRow fundMetrics={fundMetrics.metrics} />
    </StyledTable>
  );
}
