import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import { Alert, Collapse } from '@mui/material';
import { IKpiCompanyData } from '../AllCompaniesData';
import { useUpdateCompanyAndState } from '../../../../../services/hooks/useUpdateCompanyAndState';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { useInvalidateKPIRequests } from '../../KPIRequests/KPIRequestResponse/hooks/useInvalidateKPIRequests';
import { ManageCompanyFieldsTypes } from '../../../../../schemas/template-assignment-schema';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';
import { useManageCompanyFormMethods } from './useManageCompanyFormMethods';
import { useRespondentChangeWarning } from './useRespondentChangeWarning';
import { ManageSingleCompanyFields } from './ManageSingleCompanyFields';

export interface IManageCompanyFormProps {
  defaultValues: ManageCompanyFieldsTypes;
  companyId: number;
}
export function ManageSingleCompanyForm({ defaultValues, companyId }: IManageCompanyFormProps) {
  const updateCompanyAndState = useUpdateCompanyAndState();
  const [loading, setLoading] = useState(false);
  const { resetUIState: handleCloseModal } = useAllKpiCompaniesActions();
  const invalidateKpiRequests = useInvalidateKPIRequests();
  const methods = useManageCompanyFormMethods(defaultValues!);

  const handleSubmit = useCallback(
    async (data: ManageCompanyFieldsTypes) => {
      const { fye, respondents, adjustHistoricKPIs } = data;
      setLoading(true);
      await updateCompanyAndState(companyId, {
        fye,
        respondents,
        adjustHistoricKPIs,
      } as Partial<IKpiCompanyData>);
      invalidateKpiRequests();
      setLoading(false);
      handleCloseModal();
    },
    [updateCompanyAndState, companyId, invalidateKpiRequests, handleCloseModal]
  );

  return (
    <FormProvider {...methods}>
      <ManageSingleCompanyFields companyId={companyId} />
      <RespondentWarning companyId={companyId} />
      <StickyFormButtons
        onCancel={handleCloseModal}
        onSubmit={methods.handleSubmit(handleSubmit)}
        loading={loading}
        style={{ padding: '0.5rem 0 2rem' }}
        submitLabel='Save Changes'
      />
    </FormProvider>
  );
}

function RespondentWarning({ companyId }: { companyId: number }) {
  const { control, formState } = useFormContext<ManageCompanyFieldsTypes>();
  const respondents = useWatch({ control: control, name: 'respondents' }) as string[] | null;
  const initialRespondents = useMemo(
    () => new Set(formState.defaultValues?.respondents ?? []) as Set<string>,
    [formState.defaultValues?.respondents]
  );
  const warning = useRespondentChangeWarning({ companyId, respondents, initialRespondents });
  return (
    <Collapse in={!!warning}>
      <Alert severity='warning' style={{ marginBottom: '1rem' }}>
        {warning}
      </Alert>
    </Collapse>
  );
}
