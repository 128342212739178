import { useCallback, useState } from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  SwitchProps,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  FinancialsSettingsKey,
  settingsKeyToLabelMap,
  useFinancialsGridSettings,
} from './FinancialsSettingsState';

const supportedOptions: FinancialsSettingsKey[] = [
  'showFuture',
  'reportedOnly',
  'showBudget',
  'showLTM',
  'showYTD',
  'showGrowth',
];

export function FinancialsSettings() {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const open = Boolean(anchor);

  const { displaySettings, handleSettingsChange, isOptionDisabled } = useFinancialsGridSettings();

  const _onChange = useCallback(
    (key: FinancialsSettingsKey) => {
      handleSettingsChange(key);
    },
    [handleSettingsChange]
  );

  return (
    <>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)} title={'display settings'}>
        <SettingsIcon color={'secondary'} />
      </IconButton>
      <Menu anchorEl={anchor} open={open} onClose={() => setAnchor(null)}>
        {open &&
          supportedOptions.map((key) =>
            isOptionDisabled(key) ? null : (
              <MenuItem
                key={key}
                divider={key === 'reportedOnly'}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '0.875rem',
                  },
                  padding: '0.5rem 1.5rem 0.5rem 0',
                }}
              >
                <SettingsMenuItem
                  checked={displaySettings[key]}
                  onChange={() => _onChange(key)}
                  label={settingsKeyToLabelMap[key]}
                />
              </MenuItem>
            )
          )}
      </Menu>
    </>
  );
}

interface ISettingsMenuItemProps
  extends Pick<SwitchProps, 'checked' | 'onChange' | 'disabled'>,
    Pick<FormControlLabelProps, 'label'> {}
export function SettingsMenuItem({ checked, onChange, label, disabled }: ISettingsMenuItemProps) {
  return (
    <FormControlLabel
      disabled={disabled}
      value={label}
      control={
        <Switch color='primary' checked={checked} onChange={onChange} size='small' disabled={disabled} />
      }
      label={label}
      labelPlacement='start'
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
      }}
    />
  );
}
