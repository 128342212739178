import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { styled } from '@mui/material/styles';
import { Breadcrumbs } from '../../../components/Breadcrumbs/Breadcrumbs';
import { companyState } from '../../../services/state/CompanyState';
import { useFinanceHistoryData } from '../providers/FinanceHistoryDataProvider';

const BreadcrumbsContainer = styled('div')`
  margin-bottom: 20px;
`;

export const FinanceHistoryBreadcrumbs: FC = () => {
  const { activeTransaction, companyMetrics } = useFinanceHistoryData();
  const company = useRecoilValue(companyState(activeTransaction?.companyId ?? -1));

  const companyName = companyMetrics?.company?.name || company?.name;

  const currentCompanyHistoryTitle = companyName ? `${companyName} Transactions History` : '';

  const breadcrumbs = [
    {
      label: 'All Transactions',
      url: '/finance',
    },
    {
      label: currentCompanyHistoryTitle,
    },
  ];

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </BreadcrumbsContainer>
  );
};
