import { set } from 'lodash-es';
import { NestedFieldPaths } from 'ag-grid-community';
import { IPreferencesDataModel } from '../data-models/preferences.data-model';
import { updatePreferencesDebounced, updatePreferences } from '../services/queries/MaggiePreferencesQueries';

const version = '0.1';

export class PreferencesService {
  static #instance: PreferencesService | undefined;
  #currentPreferencesState: IPreferencesDataModel;

  private constructor() {
    this.#currentPreferencesState = {};
  }

  init() {
    window.addEventListener('beforeunload', () => {
      updatePreferences(this.#currentPreferencesState);
    });
  }

  static initService(preferences: IPreferencesDataModel) {
    if (!this.#instance) {
      this.#instance = new PreferencesService();

      if (preferences.version !== version) {
        // might need to do some transformations here
      }
      this.#instance.#currentPreferencesState = preferences;
      this.#instance.init();
    }
  }

  static get() {
    if (!this.#instance) {
      throw new Error('Preferences service not initialized');
    }

    return this.#instance;
  }

  static destroyService() {
    this.#instance = undefined;
  }

  getCurrentPreferences() {
    return this.#currentPreferencesState;
  }

  updatePreferences(path: NestedFieldPaths<IPreferencesDataModel>, payload: unknown) {
    const newState = set(this.#currentPreferencesState, path, payload);

    updatePreferencesDebounced(newState);
  }
}
