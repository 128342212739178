import { RefObject, useCallback, useState } from 'react';
import { SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { IKpiCompanyData } from '../AllCompaniesData';

export function useAllCompaniesRowSelection(gridRef?: RefObject<AgGridReact<IKpiCompanyData>>) {
  const [selected, setSelected] = useState<IKpiCompanyData[]>([]);

  const selectAll = useCallback(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.selectAll();
      setSelected(gridRef.current.api.getSelectedRows());
    }
  }, [gridRef]);

  const deselectAll = useCallback(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api?.deselectAll();
      setSelected([]);
    }
  }, [gridRef]);

  const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    setSelected(selectedRows);
  }, []);

  return { selectAll, deselectAll, onSelectionChanged, selected };
}
