import * as yup from 'yup';
import { positiveNumMsg, requiredMsg, typeMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import {
  conversionRatioField,
  integerField,
  usdField,
} from '../../../../../../../schemas/common-schema-defs';

export function eqSecondaryPurchaseFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema().required(requiredMsg('Transaction Date')),
    investmentAmount: usdField()
      .nullable()
      .required(requiredMsg('Investment Amount'))
      .positive(positiveNumMsg('Investment Amount'))
      .typeError(typeMsg('Investment Amount', 'number')),
    noOfShares: integerField()
      .nullable()
      .typeError(typeMsg('New Shares Issued', 'number'))
      .required(requiredMsg('New Shares Issued'))
      .positive(positiveNumMsg('New Shares Issued')),
    currencyid: transactionCurrencyIdField(),
    valuationImpact: yup
      .boolean()
      .required(requiredMsg('Valuation Impact'))
      .transform((_, val) => (val ? val : false)),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    postMoneyValuation: yup
      .number()
      .nullable()
      .positive(positiveNumMsg('Post Money Valuation'))
      .optional()
      .transform((_, val) => (val === val ? val : null)),
    fmvBasis: yup.string().optional().nullable(),
    security: yup
      .string()
      .nullable()
      .optional()
      .transform((_, val) => (val === val ? val : null)),
    conversionRatio: conversionRatioField(),
  };
}

export function eqSecondaryPurchaseFormSchema() {
  return yup.object().shape(eqSecondaryPurchaseFields());
}

export function eqSecondaryPurchaseGridSchema() {
  return yup.object(eqSecondaryPurchaseFields()).omit(['fmvBasis', 'investmentAmount', 'postMoneyValuation']);
}
