import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { fiscalDateFormatter, formatDate } from '../../../util/formatters/DateFormatters';
import { IKPIRequestDataModel } from '../../../data-models/kpi-requests.data-model';
import { camelCaseToCapitalizedString } from '../../../util/stringUtils';

export function entityFormatter(params: ValueFormatterParams) {
  if (!params?.value) return '';
  // validation for customFields Grid
  if (params?.value === 'fundCompany') return 'Company & Fund';
  return camelCaseToCapitalizedString(params.value);
}

export function dateFormatter(params: ValueFormatterParams) {
  if (!params?.value) return '';
  return formatDate(params.value);
}

export function kpiFiscalDateFormatter(
  params: ValueFormatterParams<IKPIRequestDataModel> | ValueGetterParams<IKPIRequestDataModel>
) {
  if (params.data) {
    const { frequency, fye, period } = params.data;

    return fiscalDateFormatter(frequency, period, fye as string);
  }
  return '';
}
