import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Collapse, TextField, Typography } from '@mui/material';
import { add, compareAsc, isValid as isValidDate } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, FormField, FormFieldsContainer, Label } from '../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { ITrackCompanyPayload } from '../../../data-models/tracking.data-model';
import { InfoBox } from '../../Mappings/forms/InfoBox';
import { formatDate } from '../../../util/formatters/DateFormatters';
import { PermissionService } from '../../../services/PermissionService';
import { PermissionKey, RoleKey } from '../../../services/PermissionAndRolesKeys';
import { UserSelector } from '../../Finance/form-components/UserSelector';
import { shortcutsSx } from '../../../components/DatePicker/Calendar';

interface ITrackCompanyFormProps {
  onSubmit: (data: Partial<ITrackCompanyPayload>) => void;
  onClose: () => void;
  initialValues?: Partial<ITrackCompanyPayload>;
  showNotes?: boolean;
}

export const TrackCompanyForm: FC<ITrackCompanyFormProps> = ({
  onSubmit,
  onClose,
  initialValues,
  showNotes = true,
}) => {
  const defaultVals = initialValues ?? { snoozeDate: null, snoozeComment: null };
  // TODO: Remove role check MAGGIE-4945
  const isServiceProvider = PermissionService.get().hasRole(RoleKey.serviceProvider);
  const canAssignUserToTrackedDeal = PermissionService.get().hasPermission(
    PermissionKey.canAssignUserToTrackedDeal
  );

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaultVals,
    mode: 'onTouched',
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    defaultVals.snoozeDate ? new Date(defaultVals.snoozeDate) : null
  );
  const [selectedUser, setSelectedUser] = useState<number | null>(null);

  const handleDateChange = (date: Date | null) => {
    if (!date) {
      clearErrors('snoozeDate');
      setSelectedDate(null);
      return;
    }

    const dateValid = isValidDate(date);

    if (!dateValid || compareAsc(date!, new Date()) < 0) {
      setError('snoozeDate', { message: !dateValid ? 'Invalid date' : 'Date must be in the future' });
      return;
    }
    clearErrors('snoozeDate');
    setSelectedDate(date);
    setValue('snoozeDate', date?.toISOString() ?? null);
  };

  const handleUserSelected = (id: number | number[] | null) => {
    setValue('userId', id as number);
    setSelectedUser(id as number);
  };

  if (isServiceProvider || canAssignUserToTrackedDeal) {
    register('userId', { required: true });
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormFieldsContainer>
        {(isServiceProvider || canAssignUserToTrackedDeal) && (
          <FormField>
            <Label required>Assign to User</Label>
            <UserSelector
              name={'User selector'}
              value={selectedUser ?? undefined}
              onChange={handleUserSelected}
              disablePortal={false}
            />
          </FormField>
        )}
        <FormField>
          <Label>Remind</Label>
          <Box display='grid' alignContent={'center'} alignItems={'center'}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disablePast
                value={selectedDate}
                views={['year', 'month', 'day']}
                openTo='day'
                slotProps={{
                  textField: {
                    error: Boolean(errors.snoozeDate?.message),
                  },
                  shortcuts: { items: trackShortcuts, sx: shortcutsSx },
                }}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
            {errors.snoozeDate?.message ? (
              <Typography variant='caption' color='error'>
                {errors.snoozeDate.message}
              </Typography>
            ) : null}
          </Box>
          <Collapse in={Boolean(selectedDate) && !errors?.snoozeDate}>
            <InfoBox
              message={
                selectedDate && !errors?.snoozeDate
                  ? `${formatDate(
                      selectedDate.toISOString()
                    )} you'll receive a notification about this company`
                  : ''
              }
              style={{ marginTop: '0.5rem' }}
            />
          </Collapse>
        </FormField>

        {showNotes && (
          <FormField>
            <Label>Your Notes</Label>
            <TextField multiline {...register('snoozeComment')} />
          </FormField>
        )}
      </FormFieldsContainer>
      <StickyFormButtons
        onCancel={onClose}
        onSubmit={() => handleSubmit(onSubmit)()}
        disabled={Boolean(errors.snoozeDate)}
        loading={isSubmitting}
        submitLabel='Save'
      />
    </Form>
  );
};

const trackShortcuts = [
  {
    label: 'in 30d',
    getValue: () => add(new Date(), { days: 30 }),
  },
  {
    label: 'in 60d',
    getValue: () => add(new Date(), { days: 60 }),
  },
  {
    label: 'in 90d',
    getValue: () => add(new Date(), { days: 90 }),
  },
];
