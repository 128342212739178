import {
  IKPIRequestDataModel,
  IKPIRequestResponse,
  IKPIResponseActionPayload,
  IKPIResponseForm,
  KPIRequestResponse,
  KPIRequestsResponse,
} from '../../data-models/kpi-requests.data-model';
import { MaggieHttp } from '../MaggieHttp';
import { ICompanyFinancialsDataModel } from '../../data-models/company-financials.data-model';
import { CreateKpiRequestPayload } from '../../pages/KPI/components/KPICompanies/AllCompaniesForms/useCreateKpiRequest';

export async function sendKPIRequests(requestsId: number[]) {
  return await MaggieHttp.maggie.put<{ requestIds: number[] }, IKPIRequestDataModel[]>(
    `/kpiRequest/bulkSend`,
    {
      requestIds: requestsId,
    }
  );
}

export async function createKpiRequest(request: CreateKpiRequestPayload) {
  const { data } = await MaggieHttp.maggie.post<Partial<IKPIRequestDataModel>, IKPIRequestDataModel>(
    `/kpiRequest`,
    request
  );
  return data;
}

export async function deleteKPIRequest(requestId: number) {
  const { data } = await MaggieHttp.maggie.delete<Partial<IKPIRequestDataModel>>(`/kpiRequest/${requestId}`);

  return data;
}

export async function fetchKPIRequests(): Promise<KPIRequestsResponse> {
  const { data } = await MaggieHttp.maggie.get<KPIRequestsResponse>('/kpiRequest');

  return data;
}

export async function fetchKPIRequestById(requestId: number): Promise<KPIRequestResponse> {
  const { data } = await MaggieHttp.maggie.get<KPIRequestResponse>(`/kpiRequest/${requestId}`);

  return data;
}

export async function createKPIRequestResponse(
  requestId: string,
  responseData: Partial<IKPIResponseActionPayload>,
  autosave?: boolean
) {
  const { data } = await MaggieHttp.maggie.post<Partial<IKPIResponseActionPayload>, IKPIRequestResponse>(
    `/kpiRequest/${requestId}/response`,
    responseData,
    {
      params: {
        autosave,
      },
    }
  );

  return data;
}

export async function acceptKPIRequestResponse(requestId: string) {
  const { data } = await MaggieHttp.maggie.put<Partial<IKPIResponseForm>, IKPIRequestResponse>(
    `/kpiRequest/${requestId}/accept`
  );

  return data;
}

export async function rejectKPIRequestResponse(requestId: string) {
  const { data } = await MaggieHttp.maggie.put<Partial<IKPIResponseForm>, IKPIRequestDataModel>(
    `/kpiRequest/${requestId}/reject`
  );

  return data;
}

export async function updateKpiRequest(requestId: number, payload: Partial<IKPIRequestDataModel>) {
  const { data } = await MaggieHttp.maggie.put<Partial<IKPIRequestDataModel>, IKPIRequestDataModel>(
    `/kpiRequest/${requestId}`,
    payload
  );

  return data;
}

export async function updateKPIRequestResponse(
  requestId: string,
  responseFormData: Partial<IKPIResponseActionPayload>,
  autosave?: boolean
) {
  const { data } = await MaggieHttp.maggie.put<Partial<IKPIResponseActionPayload>, IKPIRequestResponse>(
    `/kpiRequest/${requestId}/response`,
    { ...responseFormData },
    { params: { autosave } }
  );

  return data;
}

export async function fetchKPIRequestResponseById(requestId: number): Promise<IKPIRequestResponse> {
  const { data } = await MaggieHttp.maggie.get<IKPIRequestResponse>(`/kpiRequest/${requestId}/response`);

  return data;
}
export async function fetchAssignedKPIRequests() {
  const { data } = await MaggieHttp.maggie.get<IKPIRequestDataModel[]>(`/kpiRequest/assigned`);

  return data;
}

interface IFileS3URL {
  companyId: number;
  fileId: string;
  contentType: string;
  fileName?: string;
}
interface IFileUploadResponse {
  companyId: number;
  fileId: string;
  signedUrl: string;
}

export async function fetchKPIRequestS3FileUploadUrl({
  contentType,
  companyId,
}: Partial<IFileS3URL>): Promise<IFileUploadResponse> {
  const { data } = await MaggieHttp.maggie.post<Partial<IFileS3URL>, IFileUploadResponse>(`/file-upload`, {
    companyId,
    contentType,
  });

  return data;
}

export async function fetchKPIRequestUploadedFileFromS3({
  companyId,
  fileId,
  contentType,
  fileName,
}: IFileS3URL): Promise<string> {
  const { data } = await MaggieHttp.maggie.post<IFileS3URL, string>('file-upload/retrieve', {
    companyId,
    contentType,
    fileId,
    fileName,
  });

  return data;
}

interface IBulkKpiRequestUpdate {
  action: 'accept' | 'reject';
  requestIds: number[];
}

export async function bulkAcceptResponses(ids: number[]) {
  await MaggieHttp.maggie.put<IBulkKpiRequestUpdate, unknown>(`/kpiRequest/bulkUpload`, {
    action: 'accept',
    requestIds: ids,
  });
}

export type ICompanyFinancialsDataModelCreatePayload = Omit<
  ICompanyFinancialsDataModel,
  'id' | 'iLevelId' | 'updatedAt' | 'createdAt'
>;
export async function createCustomKPIData(kpiData: ICompanyFinancialsDataModelCreatePayload) {
  const { data } = await MaggieHttp.maggie.post<
    ICompanyFinancialsDataModelCreatePayload,
    ICompanyFinancialsDataModel
  >(`/kpiCustomData`, kpiData);
  return data;
}
export async function updateCustomKPIData(id: number, kpiData: Partial<ICompanyFinancialsDataModel>) {
  const { data } = await MaggieHttp.maggie.put<
    Partial<ICompanyFinancialsDataModel>,
    ICompanyFinancialsDataModel
  >(`/kpiCustomData/${id}`, kpiData);
  return data;
}

export async function deleteCustomKPIData(id: number) {
  await MaggieHttp.maggie.delete(`/kpiCustomData/${id}`);
}
