import { useCallback, useEffect } from 'react';
import { GridApi, RowHeightParams } from 'ag-grid-community';
import { IFinancialsDisplayOptions } from '../components/FinancialsSettingsState';

const singleRowHeight = 42;

export function useFinancialsGridRowHeight(gridApi: GridApi | undefined, options: IFinancialsDisplayOptions) {
  const { showBudget, showLTM, showYTD } = options;
  const getRowHeight = useCallback(
    (params: RowHeightParams) => {
      const innerRowCount = getRowCount({ showBudget, showLTM, showYTD });
      return params.node.group || innerRowCount === 1 ? singleRowHeight : innerRowCount * singleRowHeight;
    },
    [showBudget, showLTM, showYTD]
  );

  useEffect(() => {
    if (!gridApi) return;
    gridApi.resetRowHeights();
  }, [gridApi, showBudget, showLTM, showYTD]);

  return { getRowHeight };
}

export function getRowCount(options: IFinancialsDisplayOptions) {
  const { showBudget, showLTM, showYTD } = options;
  let innerRowCount = 1;
  if (showLTM) innerRowCount++;
  if (showYTD) innerRowCount++;
  if (showBudget) innerRowCount++;
  return innerRowCount;
}
