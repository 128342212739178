import { Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { cardStyles } from '../../../theme/component-styles';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { isEditingFundDataState, selectedFundIdStateFP } from '../state/FPState';
import { colors } from '../../../theme/colors';
import { WaterfallModellingGrid } from './WaterfallModellingGrid';
import { WaterfallSettingsToggle, WaterfallSettings } from './WaterfallSettings';
import { EditFundData } from './EditFundData';

const Card = css({
  ...cardStyles,
});

const MainContent = css`
  ${Card};
  padding: 1rem;
  &.editing {
    border-left: 4px solid ${colors.secondary[40]};
  }
`;

export function WaterfallModelling() {
  const fundId = useRecoilValue(selectedFundIdStateFP);
  const isEditingFundData = useRecoilValue(isEditingFundDataState);

  if (!fundId) return null;
  return (
    <GenericFallbacksWrapper>
      <Stack direction={'row'} gap='0.5rem'>
        <WaterfallSettingsToggle />
        <Typography variant='subtitle1'>Waterfall Modelling</Typography>
      </Stack>
      <Stack display={'grid'} gridTemplateColumns={'min-content 1fr'} mt='0.5rem'>
        <GenericFallbacksWrapper>
          <WaterfallSettings />
        </GenericFallbacksWrapper>
        <div css={MainContent} className={isEditingFundData ? 'editing' : ''} key={String(isEditingFundData)}>
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            key={String(isEditingFundData)}
          >
            {isEditingFundData ? <EditFundData fundId={fundId} /> : <WaterfallModellingGrid />}
          </motion.div>
        </div>
      </Stack>
    </GenericFallbacksWrapper>
  );
}
