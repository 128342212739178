import { useCallback, SyntheticEvent } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { SelectedCompanyParam } from '../finance-constants';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { getDetailsSubsection } from '../FinanceTabs';

export const FinanceDetailsBasePath = `/${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}/${ROUTES.FINANCE_DETAILS}`;

export function useHandleFilterChange() {
  const { showFinanceSecurity } = useFlags<MaggieFeatureFlags>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const setSearchParams = useSearchParams()[1];

  const handleFilterChange = useCallback(
    (_: SyntheticEvent, companyId: number | null) => {
      const subsection = getDetailsSubsection(pathname);
      // if on Finance main page, navigate to default company-specific finance subsection
      if (!subsection && companyId) {
        const search = createSearchParams({ [SelectedCompanyParam]: String(companyId) }).toString();
        if (showFinanceSecurity) {
          navigate({ pathname: `${FinanceDetailsBasePath}/${ROUTES.FINANCE_SECURITY}`, search });
        } else {
          navigate({ pathname: `${FinanceDetailsBasePath}/${ROUTES.FINANCE_TRANSACTIONS}`, search });
        }
      } else {
        setSearchParams({ [SelectedCompanyParam]: companyId?.toString() ?? '' });
      }
    },
    [navigate, pathname, setSearchParams, showFinanceSecurity]
  );

  return { handleFilterChange };
}
