import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Alert, Collapse } from '@mui/material';
import { useRecoilValueLoadable } from 'recoil';
import { FormFieldWithLabelFactory } from '../../../../../components/Form/FormFieldAndLabelFactory';
import {
  ManageCompanyFieldsTypes,
  manageSingleCompanyFormSchema,
} from '../../../../../schemas/template-assignment-schema';
import { schemaToFormFields } from '../../../../../util/schema-utils';
import { allCompanyFinancialsState } from '../../../../CompanyProfiles/state/CompanyFinancialsState';

export function ManageSingleCompanyFields({ companyId }: { companyId: number }) {
  const fields = useMemo(
    () => new Map(schemaToFormFields(manageSingleCompanyFormSchema()).map((f) => [f.key, f])),
    []
  );
  const showFyePrompt = useShowFyePrompt(companyId);

  return (
    <>
      <div>
        <FormFieldWithLabelFactory key={'fye'} formField={fields.get('fye')!} />
        <Collapse in={showFyePrompt} sx={showFyePrompt ? { mt: '1rem' } : undefined}>
          {showFyePrompt ? (
            <>
              <Alert severity='warning' sx={{ mb: '0.5rem' }}>
                This company already has KPI data entered for previous periods.
                <br /> Would you like to adjust those KPIs to the new FYE?
              </Alert>
              <FormFieldWithLabelFactory key={'fye'} formField={fields.get('adjustHistoricKPIs')!} />
            </>
          ) : (
            <div style={{ height: '3rem' }} />
          )}
        </Collapse>
      </div>
      <FormFieldWithLabelFactory key={'respondents'} formField={fields.get('respondents')!} />
    </>
  );
}

export function useShowFyePrompt(companyId: number) {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext<ManageCompanyFieldsTypes>();
  const fye = useWatch({ name: 'fye', control });
  const hasExistingKPIs = useRecoilValueLoadable(allCompanyFinancialsState(companyId)).contents?.length > 0;

  return hasExistingKPIs && fye !== defaultValues?.fye;
}
