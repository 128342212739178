import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLoadingBarState } from './LoadingBarContext';

// Placeholder is the same width & height as the Loading bar and here to avoid
// unwanted jumpiness when the bar is hidden / shown.
const LoadingBarPlaceholder = styled('div')`
  height: 4px;
  width: 100%;
`;

export function LoadingBar() {
  const { state } = useLoadingBarState();

  if (state.isLoading) {
    return <LinearProgress variant='indeterminate' />;
  }

  return <LoadingBarPlaceholder />;
}
