import { styled } from '@mui/material';

export const StyledGridWrapper = styled('div')`
  .ag-theme-alpine .ag-root-wrapper {
    background: transparent;

    .ag-row.ag-row-footer {
      background-color: ${({ theme }) => theme.colors.secondary[5]};
      box-shadow: ${({ theme }) => `0 -0.1rem ${theme.colors.neutral[20]}`};
    }
  }
`;

export const CaptableGridContainer = styled(StyledGridWrapper)`
  .type-header,
  .type-header:hover {
    background-color: ${({ theme }) => theme.colors.primary[10]} !important;
  }
  .total-header {
    justify-content: center;
  }
  .investment-header,
  .investment-header:hover {
    background-color: ${({ theme }) => theme.colors.primary[5]} !important;
  }

  .ag-header-group-cell.ag-header-group-cell-with-group.type-header {
    border-top: 1px solid transparent;
  }
`;
