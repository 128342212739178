import { Chip, Container, Stack, styled, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { add } from 'date-fns';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { formatDate } from '../../../../../util/formatters/DateFormatters';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { FormatterService } from '../../../../../util/formatter-service';

const StickyHeaderWrapper = styled('div')`
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export interface IKPIRecipientViewHeaderProps {
  company: ICompanyDataModel;
  kpiRequest: IKPIRequestDataModel;
}

export function KPIRecipientViewHeader(props: IKPIRecipientViewHeaderProps) {
  const { company, kpiRequest } = props;

  return (
    <StickyHeaderWrapper>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '2rem',
          px: '2rem !important',
          paddingLeft: '2.5rem !important',
        }}
      >
        <Stack direction={'row'} gap={'1rem'} alignItems='center'>
          <Stack direction='row' alignItems={'center'} justifyContent={'center'} gap='.5rem'>
            <MUIAvatar
              nameParts={company.name.split(' ') as string[]}
              src={company.logoUrl as string}
              size={'medium'}
            />
            <Typography variant='h5'>{company.name}</Typography>
          </Stack>
          <HeaderInfo kpiRequest={kpiRequest} />
        </Stack>
      </Container>
    </StickyHeaderWrapper>
  );
}

const HeaderInfo = ({ kpiRequest }: { kpiRequest: IKPIRequestDataModel | null }) => {
  const respondentFormatter = FormatterService.get().getFormatterForId('userByEmail');
  const { colors } = useTheme();

  const deadLineDate = useMemo(() => {
    if (kpiRequest?.status !== KPIRequestStatus.Sent || !kpiRequest?.sentAt) return null;

    const sentAt = new Date(kpiRequest.sentAt);
    return formatDate(add(sentAt, { days: 14 }).toISOString());
  }, [kpiRequest?.status, kpiRequest?.sentAt]);

  return kpiRequest?.status === KPIRequestStatus.Sent ? (
    <Chip
      size='medium'
      label={`Deadline ${deadLineDate || 'N/A'}`}
      sx={{
        marginTop: '0.25rem',
        '& .MuiChip-label': {
          display: 'block',
          color: colors.secondary[80],
          fontSize: '1rem',
          whiteSpace: 'normal',
        },
      }}
    />
  ) : (
    <Stack direction='row' style={{ paddingTop: '.25rem' }} gap='1rem'>
      <Typography variant='body1' sx={{ color: colors.gray[700] }}>
        <b>From:</b> {respondentFormatter(kpiRequest?.respondent ?? [])}
      </Typography>
    </Stack>
  );
};
