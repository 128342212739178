import { FC, useCallback, useMemo, useState } from 'react';
import { Button, SelectChangeEvent, styled, Typography, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { useUpdateCompanyAndState } from '../../../services/hooks/useUpdateCompanyAndState';
import { ListWithMoreChip } from '../Companies/CompanyCard/ListWithMoreChip';
import { formatLocation } from '../../../util/location-formatter';
import { formatDate } from '../../../util/formatters/DateFormatters';
import { formatUSDShort } from '../../../util/formatters/NumericFormatters';
import { SectorSelector } from '../../../components/Form/SectorSelector';
import { UserSelector } from '../../../components/Form/UserSelector';
import { useTrackingData } from '../hooks/useTrackingData';
import { TrackCompanyModal } from '../forms/TrackCompanyModal';
import { IListDataModel, ListType } from '../../../data-models/list.data-model';
import { CompanyLinkRenderer } from '../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/CompanyLinkRenderer';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { CompanyPeopleNames } from './People/CompanyPeopleNames';
import { LabelAndValue } from './CommonStyledComponents';

const DetailsSection = styled('div')`
  background: ${({ theme }) => theme.colors.neutral[2]};
  border-radius: 4px;
  padding: 1rem 1.5rem;
  display: grid;
  gap: 0.25rem;
`;
const DetailsSectionContainer = styled('div')`
  display: grid;
  gap: 1rem;
`;

interface CompanyDetailsProps {
  company: ICompanyDataModel;
  activeList: IListDataModel;
}

export const CompanyDetails: FC<CompanyDetailsProps> = ({ company, activeList }) => {
  const {
    assignee,
    ceoName,
    foundedDate,
    latestFundingAmount,
    latestFundingDate,
    latestFundingRound,
    latestInvestors,
    raisedToDate,
    sectorId,
    totalPIC,
  } = company ?? {};

  const totalRaised = company.companyType === 'portfolio' ? raisedToDate : totalPIC;

  const isTrackingList = activeList.name === 'Tracked Companies';
  const isDiscoveryList = activeList.type === ListType.DISCOVERY;
  const { colors } = useTheme();
  const { showCEOPanel, showFoundersList } = useFlags<MaggieFeatureFlags>();
  const updateCompanyAndState = useUpdateCompanyAndState();

  const handleSectorChange = useCallback(
    async (event: SelectChangeEvent<unknown>) => {
      if (!company) return;
      const sectorId = event.target.value as number;
      if (!sectorId) return;
      return updateCompanyAndState(company.id, { sectorId });
    },
    [company, updateCompanyAndState]
  );

  const fundRaisingData =
    latestFundingRound && latestFundingAmount
      ? `${latestFundingRound} - ${formatUSDShort(latestFundingAmount)}`
      : '';

  const locations = useMemo(() => {
    if (!company) return [];
    const primaryLoc = formatLocation({
      city: company.city ?? '',
      state: company.state,
      country: company.country,
    });
    const otherLocs = company.locations.map((loc) => formatLocation(loc));
    return primaryLoc ? [primaryLoc, ...otherLocs] : otherLocs;
  }, [company]);

  if (!company) return null;
  return (
    <DetailsSectionContainer>
      {isTrackingList ? (
        <DetailsSection>
          <TrackingInfo company={company} />
        </DetailsSection>
      ) : null}
      <DetailsSection>
        {isDiscoveryList && (
          <LabelAndValue
            label='Owner'
            value={
              <UserSelector
                onChange={(newValue) => {
                  updateCompanyAndState(company.id, { assignee: newValue as number });
                }}
                value={assignee}
                style={{ maxWidth: '16rem' }}
              />
            }
          />
        )}

        <LabelAndValue
          label='Sector'
          value={
            <SectorSelector
              value={sectorId ?? null}
              onChange={handleSectorChange}
              style={{ maxWidth: '16rem' }}
            />
          }
        />

        <LabelAndValue
          label='Website'
          value={
            <CompanyLinkRenderer
              domain={company.domain ?? null}
              website={company.website}
              style={{ fontSize: '0.85rem', color: colors.primary[60] }}
            />
          }
        />

        <LabelAndValue
          label='CEO'
          value={
            showCEOPanel ? (
              <CompanyPeopleNames company={company} names={ceoName} peopleData={company.ceoIds} />
            ) : (
              <Typography variant='body2'>{ceoName ? ceoName : ''}</Typography>
            )
          }
        />

        {showFoundersList && (
          <LabelAndValue
            label='Founders'
            value={
              showCEOPanel ? (
                <CompanyPeopleNames
                  company={company}
                  names={company.founderName}
                  peopleData={company.founderIds}
                />
              ) : (
                <Typography variant='body2'>{company.founderName ?? ''}</Typography>
              )
            }
          />
        )}

        <LabelAndValue label='Founded' value={<Typography variant='body2'>{foundedDate ?? ''}</Typography>} />

        <LabelAndValue
          label='Co-Investors'
          value={
            <Typography variant='body2' component='div'>
              {latestInvestors?.length ? (
                <ListWithMoreChip items={latestInvestors} title='Co-Investors' />
              ) : (
                ''
              )}
            </Typography>
          }
        />

        <LabelAndValue
          label='Location'
          value={
            <Typography variant='body2' component='div'>
              {locations?.length ? <ListWithMoreChip items={locations} title={'Locations'} /> : ''}
            </Typography>
          }
        />
      </DetailsSection>

      <DetailsSection>
        <LabelAndValue
          label='Total Raised'
          value={
            <Typography variant='body2'>
              {typeof totalRaised === 'number' ? formatUSDShort(totalRaised) : ''}
            </Typography>
          }
        />

        <LabelAndValue
          label='Latest Fundraise'
          value={<Typography variant='body2'>{fundRaisingData ? fundRaisingData : ''}</Typography>}
        />

        <LabelAndValue
          label='Latest Round Date'
          value={
            <Typography variant='body2'>{latestFundingDate ? formatDate(latestFundingDate) : ''}</Typography>
          }
        />
      </DetailsSection>
    </DetailsSectionContainer>
  );
};

function TrackingInfo({ company }: { company: ICompanyDataModel }) {
  const { hasExpired, snoozeDate } = useTrackingData(company.id);

  const [showTrackModal, setShowTrackModal] = useState(false);

  return (
    <>
      <TrackCompanyModal
        open={showTrackModal}
        onSubmit={() => setShowTrackModal(false)}
        onClose={() => setShowTrackModal(false)}
        company={company}
        showNotes={false}
        initialValues={{ snoozeDate }}
      />
      <LabelAndValue
        label='Reminder'
        value={
          <Button
            onClick={() => setShowTrackModal(true)}
            color={hasExpired ? 'error' : 'secondary'}
            style={{ width: '100%', maxWidth: '16rem' }}
            variant='outlined'
          >
            {snoozeDate ? `${hasExpired ? 'Expired ' : ''}${formatDate(snoozeDate)}` : '-'}
          </Button>
        }
      />
    </>
  );
}
