import { styled } from '@mui/material/styles';

import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';
import { CSSProperties } from 'react';
import { KPI } from '../../../../components/Card/KPI';
import { Card } from '../../../../components/Card/Card';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';

const List = styled('ul')`
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 0.4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

const ListItem = styled('li')`
  margin: 0;
  padding: 0;
`;

export interface ICardProps {
  id: string;
  title: string;
  value: string;
  secondaryValue?: string;
  indicator: number | null;
  description?: string;
}

interface ICardListProps {
  kpis: ICardProps[];
  onDelete: (cardId: string) => void;
  onReorder: (itemIds: string[]) => void;
  style?: CSSProperties;
}

interface SortableItemProps {
  kpi: ICardProps;
  onDelete: (cardId: string) => void;
}

const SortableItem = ({ kpi, onDelete }: SortableItemProps): JSX.Element => {
  const { setNodeRef, transform, transition, listeners, attributes } = useSortable({ id: kpi.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <GenericFallbacksWrapper style={{ maxWidth: '500px', maxHeight: '200px' }}>
      <ListItem ref={setNodeRef} style={style} key={kpi.id} id={String(kpi.id)}>
        <Card>
          <KPI
            title={kpi.title}
            value={kpi.value}
            attributes={attributes}
            listeners={listeners}
            indicator={kpi.indicator}
            secondaryValue={kpi.secondaryValue}
            onDelete={() => onDelete(kpi.id)}
            description={kpi.description}
          />
        </Card>
      </ListItem>
    </GenericFallbacksWrapper>
  );
};

export const CardList = ({ kpis, onReorder, onDelete, style }: ICardListProps): JSX.Element => {
  const handleReorder = (e: DragEndEvent) => {
    const { active, over } = e;
    if (active.id !== over?.id) {
      const kpiIds = kpis.map((k) => k.id);
      const oldIndex = kpiIds.indexOf(String(active.id));
      const newIndex = kpiIds.indexOf(over?.id ? String(over?.id) : '');
      const reOrderedKpiIds = arrayMove(kpiIds, oldIndex, newIndex);
      onReorder(reOrderedKpiIds);
    }
  };

  return (
    <List style={style}>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleReorder}>
        <SortableContext items={kpis}>
          {kpis.map((kpi, i) => (
            <SortableItem key={i} kpi={kpi} onDelete={onDelete} />
          ))}
        </SortableContext>
      </DndContext>
    </List>
  );
};
