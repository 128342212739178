import { useRecoilState } from 'recoil';
import { useCallback } from 'react';
import { FieldPath } from 'react-hook-form';
import { CompanyDetailsPanel, FieldsPerPage } from '../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { useAllCompanyDetailsFields } from '../../CompanyProfiles2/Summary/useCompanyDetailsFields';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { selectedDetailsFieldsOrgState } from './DataDisplayState';
import { useDetailsConfigActions } from './useDataDisplayConfigActions';
import { DisplayConfig, IDummySectionProps } from './DisplayConfig';
import { DummyCompanyId } from './DummyData';
import { useLoadSelectedDetailsFields } from './data-display-utils';

export function CompanyDetailsConfig() {
  const { loaded } = useLoadSelectedDetailsFields();
  const allFields = useAllCompanyDetailsFields();
  const [selectedFields, setSelectedFields] = useRecoilState(selectedDetailsFieldsOrgState);
  const { handleSave, handleReset } = useDetailsConfigActions();

  const renderDummySection = useCallback(
    ({ defaultPage, key }: IDummySectionProps) => {
      return (
        <CompanyDetailsPanel
          companyId={DummyCompanyId}
          editable={false}
          fieldsToShow={selectedFields as FieldPath<ICompanyDataModel>[]}
          key={key}
          defaultPage={defaultPage}
        />
      );
    },
    [selectedFields]
  );

  if (!loaded) return null;
  return (
    <DisplayConfig
      title='Company Details Section'
      allFields={allFields}
      selectedFields={selectedFields}
      setSelectedFields={setSelectedFields}
      handleResetConfig={handleReset}
      handleSaveChanges={handleSave}
      fieldsPerPage={FieldsPerPage}
      renderDummySection={renderDummySection}
    />
  );
}
