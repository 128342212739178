import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { UseQueryResult } from '@tanstack/react-query';
import { ComponentWithChildren } from '../../../types';
import { useCompaniesQuery } from '../../../services/queries-hooks/MaggieCompanyQueriesHooks';
import { chartsColorScheme } from '../../CompetitiveIntelligence/components/CompanyList/CompanyList';
import { ICompanyDataModel } from '../../../data-models/company.data-model';

interface ICompanies {
  companies: ICompanyDataModel[];
  activeCompanies: (number | string)[];
  setActiveCompanies: Dispatch<SetStateAction<number[]>>;
}

export const CompaniesContext = createContext<ICompanies | undefined>(undefined);

export const CompaniesProvider: FC<ComponentWithChildren> = ({ children }) => {
  const [search] = useSearchParams();
  const primaryCompany = search.get('primary');
  const competitors = search.get('competitors');

  useEffect(() => {
    if (!primaryCompany) {
      throw new Error("'primary' parameter is required");
    }
  }, [competitors, primaryCompany]);

  const getCompanyIds = useCallback((): number[] => {
    if (!primaryCompany) return [];
    const primaryId = parseInt(primaryCompany);
    const competitorIds = competitors
      ? competitors
          .split(',')
          .map((id) => parseInt(id))
          .filter(Boolean)
      : [];
    return primaryId ? [primaryId, ...competitorIds] : [];
  }, [competitors, primaryCompany]);

  const companyIds = getCompanyIds();

  const companiesData: UseQueryResult<ICompanyDataModel>[] = useCompaniesQuery(companyIds);
  const companies = useMemo(
    () =>
      companiesData.some(({ data }) => !data)
        ? []
        : companiesData.map(
            ({ data }, index) => ({ ...data, color: chartsColorScheme[index] } as ICompanyDataModel)
          ),
    [companiesData]
  );

  const [activeCompanies, setActiveCompanies] = useState<number[]>(companyIds);

  const value = useMemo(
    () => ({
      companies,
      activeCompanies,
      setActiveCompanies,
    }),
    [activeCompanies, companies]
  );

  return <CompaniesContext.Provider value={value}>{children}</CompaniesContext.Provider>;
};

export const useCompanies = (): ICompanies => {
  const context = useContext(CompaniesContext);

  if (context === undefined) {
    throw new Error('useCompanies cannot be used outside CompaniesProvider');
  }

  return context;
};
