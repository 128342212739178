import { FC, useMemo } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { defaultColDef } from '../common-grid-defs/defaultColDefs';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';
import { AgTable } from '../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { Loader } from '../../../components/Loader/Loader';
import { ErrorOccurredMessage } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { useSecurityColDefs } from './useSecurityColDefs';
import { SecurityAggFuncs, filterOutSecurityDataTemp, useSecurityUtils } from './securityUtils';
import { SecurityTableStyleWrapper } from './SecurityTableStyleWrapper';

export const SecurityTable: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdStateFinance);
  const companyMetricsLoadable = useRecoilValueLoadable(companyMetricsByIdState(companyId ?? -1));
  const { securityProcessCellCallback, autoGroupColumnDef } = useSecurityUtils();

  const transactions = useMemo(
    () => filterOutSecurityDataTemp(companyMetricsLoadable.valueMaybe()?.metrics?.at(0)?.transactions ?? []),
    [companyMetricsLoadable]
  );

  const columnDefs = useSecurityColDefs();
  const defaultColDefs = useMemo(() => ({ ...defaultColDef, minWidth: 150 }), []);
  const exportParams = useMemo(
    () => ({ processCellCallback: securityProcessCellCallback }),
    [securityProcessCellCallback]
  );

  if (companyMetricsLoadable.state === 'loading') return <Loader height='80%' />;
  if (companyMetricsLoadable.state === 'hasError') return <ErrorOccurredMessage />;

  return (
    <FadeInGridWrapper key={companyId}>
      <SecurityTableStyleWrapper>
        <AgTable
          className='security-table'
          key={companyId}
          columnDefs={columnDefs}
          rowData={transactions}
          defaultColDef={defaultColDefs}
          defaultExcelExportParams={exportParams}
          defaultCsvExportParams={exportParams}
          groupDefaultExpanded={2}
          autoGroupColumnDef={autoGroupColumnDef}
          aggFuncs={SecurityAggFuncs}
          suppressAggFuncInHeader
        />
      </SecurityTableStyleWrapper>
    </FadeInGridWrapper>
  );
};
