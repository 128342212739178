import { CSSProperties, FC, ReactNode, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { Column } from 'react-table';
import { Loader } from '../../../components/Loader/Loader';
import { useFinanceHistoryData } from '../providers/FinanceHistoryDataProvider';
import { useTransactionHistoryColumns } from '../hooks/useTransactionHistoryColumns';
import { ICompanyMetricsDataModel } from '../../../data-models/metrics.data-model';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { TransactionAudit, TransactionHistoryTableData } from '../../../types';
import {
  CompanyTransactionsPanel,
  PANEL_WIDTH_IN_PX,
} from './CompanyTransactionsPanel/CompanyTransactionsPanel';
import { FinanceHistoryBreadcrumbs } from './FinanceHistoryBreadcrumbs';
import { HistoryOfUpdatesSection } from './HistoryOfUpdatesSection';
import { MainTableSection } from './MainTableSection';

const ViewContainer = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: ${PANEL_WIDTH_IN_PX}px 1fr;
  min-height: calc(100vh - 5rem); // 4rem is the height of the new sticky header
  max-height: calc(100vh - 5rem); // 4rem is the height of the new sticky header
  overflow: auto;
  padding: 20px 20px 20px 40px;
`;

const LoaderContainer = styled('div')`
  margin: 8rem auto;
`;

const Container = styled('section')`
  margin: 0 0 20px 40px;
  max-width: calc(100vw - 500px);
  overflow-y: scroll;
`;

export const FinanceHistoryContent = () => {
  const { isLoading, isFetching, companyMetrics, activeTransaction, transactionHistory, transactions } =
    useFinanceHistoryData();
  const getTransactionHistoryColumns = useTransactionHistoryColumns();

  const tableColumns = useMemo(
    () => (!activeTransaction ? [] : getTransactionHistoryColumns(activeTransaction)),
    [activeTransaction, getTransactionHistoryColumns]
  );

  if (isLoading || isFetching)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <ViewContainer>
      <FinanceHistoryPage
        transactions={transactions}
        selectedTransaction={activeTransaction}
        colDefs={tableColumns}
        companyMetrics={companyMetrics}
        transactionHistory={transactionHistory}
        breadCrumbsComponent={<FinanceHistoryBreadcrumbs />}
      />
    </ViewContainer>
  );
};

interface IFinanceHistoryPageProps {
  transactions: ITransactionDataModel[] | undefined;
  selectedTransaction: ITransactionDataModel | null;
  colDefs: Column<TransactionHistoryTableData>[];
  companyMetrics: ICompanyMetricsDataModel | null;
  transactionHistory: TransactionAudit[] | undefined;
  breadCrumbsComponent?: ReactNode;
  sidebarStyles?: CSSProperties;
  mainContentStyles?: CSSProperties;
}

export const FinanceHistoryPage: FC<IFinanceHistoryPageProps> = ({
  transactions,
  selectedTransaction,
  colDefs,
  companyMetrics,
  transactionHistory,
  breadCrumbsComponent,
  sidebarStyles,
  mainContentStyles,
}) => {
  const { colors } = useTheme();
  return (
    <>
      {transactions && (
        <>
          <CompanyTransactionsPanel transactions={transactions} style={sidebarStyles} />
          <Container style={mainContentStyles}>
            {breadCrumbsComponent}
            {selectedTransaction ? (
              <>
                <MainTableSection
                  key={selectedTransaction.id}
                  activeTransaction={selectedTransaction}
                  columns={colDefs}
                  companyMetrics={companyMetrics}
                />
                {transactionHistory ? (
                  <HistoryOfUpdatesSection
                    tableColumns={colDefs}
                    activeTransaction={selectedTransaction}
                    transactionHistory={transactionHistory}
                  />
                ) : null}
              </>
            ) : (
              <Typography variant='h4' color={colors.neutral[50]}>{`No data`}</Typography>
            )}
          </Container>
        </>
      )}
    </>
  );
};
