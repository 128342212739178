import { AutocompleteProps, AutocompleteRenderInputParams, TextField } from '@mui/material';

export interface IIdSelectorProps
  extends Omit<
    AutocompleteProps<number, boolean | undefined, boolean | undefined, boolean | undefined>,
    | 'options'
    | 'getOptionLabel'
    | 'renderOption'
    | 'renderTags'
    | 'filterOptions'
    | 'groupBy'
    | 'isOptionEqualToValue'
    | 'defaultValue'
    | 'renderInput'
    | 'onChange'
    | 'onHighlightChange'
    | 'getOptionKey'
  > {
  value: number | number[] | null;
  onChange: (newValue: number | number[] | null) => void;
  getOptionDisabled?: (id: number) => boolean;
}

export const renderInput = (params: AutocompleteRenderInputParams) => {
  return <TextField {...params} sx={{ margin: 0 }} />;
};

export const popperSx = { fontSize: '0.825rem' };
