import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { createFormFromFieldsArray, IForm } from '../../../view-models/form.view-model';
import {
  createTransactionViewModel,
  ITransactionViewModel,
  toTransactionDataModel,
} from '../../../view-models/transaction-form.view-model';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { useGetTransactionCategoryAndSubtype } from '../../../services/state/AppConfigState';
import { StepperFormFieldsContainer } from '../../../components/Form/FormComponents';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { useFinanceActions } from '../hooks/useFinanceActions';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { formatISODateOnly } from '../../../util/formatters/DateFormatters';
import { metricsFundDataByFundIdState } from '../../../services/state/CompanyMetricsByIdState';
import {
  currentStepState,
  investmentDataState,
  selectedCompanyIdState,
  transactionDataState,
  transactionPreviewState,
} from './TransactionFormUIState';
import {
  getSchemaForTransaction,
  useConversionRatioField,
  useTransactionFields,
  useTransactionSelectorFields,
} from './TransactionFormUtils';
import { StepperFormButtons } from './StepperFormButtons';

export function TransactionFormStep() {
  const companyId = useRecoilValue(selectedCompanyIdState);
  const [transactionData, setTransactionData] = useRecoilState(transactionDataState);
  const resetTransactionData = useResetRecoilState(transactionDataState);
  const investmentData = useRecoilValue(investmentDataState);
  const setCurrentStep = useSetRecoilState(currentStepState);
  const { handleGetTransactionPreview, handleCreateTransaction } = useFinanceActions();
  const setPreview = useSetRecoilState(transactionPreviewState);
  const resolver = useTransactionResolver();
  const { showTransactionSummary2 } = useFlags<MaggieFeatureFlags>();
  const companyFundIds = Array.from(useRecoilValue(metricsFundDataByFundIdState(companyId!))?.keys() ?? []);

  const methods = useForm({
    defaultValues: createTransactionViewModel(
      transactionData ?? {
        fundId: companyFundIds?.at(0),
        id: undefined,
        companyId: companyId ?? -1,
        transactionDate: formatISODateOnly(new Date()),
      }
    ),
    mode: 'all',
    resolver,
  });

  const { reset } = methods;

  const handleSubmitAndAdd = useCallback(async () => {
    if (showTransactionSummary2) return;
    const formIsValid = await methods.trigger();
    if (!formIsValid) return;

    const payload = preparePayload(companyId!, methods.getValues(), investmentData);
    await handleCreateTransaction(payload);
    resetTransactionData();
    reset(
      createTransactionViewModel(
        transactionData ?? {
          id: undefined,
          companyId: companyId ?? -1,
          transactionTypeId: undefined,
        }
      )
    );
  }, [
    companyId,
    handleCreateTransaction,
    investmentData,
    methods,
    reset,
    resetTransactionData,
    showTransactionSummary2,
    transactionData,
  ]);

  const onSubmit = useCallback(async () => {
    const formIsValid = await methods.trigger();
    if (!formIsValid) return false;

    const payload = preparePayload(companyId!, methods.getValues(), investmentData);
    if (showTransactionSummary2) {
      setTransactionData(payload);
      const preview = await handleGetTransactionPreview(payload);
      if (!preview) return false;
      setPreview(preview);
      return true;
    } else {
      await handleCreateTransaction(payload);
      return true;
    }
  }, [
    companyId,
    handleCreateTransaction,
    handleGetTransactionPreview,
    investmentData,
    methods,
    setPreview,
    setTransactionData,
    showTransactionSummary2,
  ]);

  const handleGoBack = useCallback(() => {
    setTransactionData(methods.getValues());
    setCurrentStep((step) => step - 1);
  }, [methods, setCurrentStep, setTransactionData]);

  const nextButtonLabel = showTransactionSummary2 ? 'Next' : 'Submit';

  return (
    <FormProvider {...methods}>
      <StepperFormFieldsContainer>
        <TransactionForm />
      </StepperFormFieldsContainer>
      <StepperFormButtons
        stepIsValid={onSubmit}
        handleGoBack={handleGoBack}
        nextButtonLabel={nextButtonLabel}
        secondaryActionProps={
          showTransactionSummary2
            ? undefined
            : { handler: handleSubmitAndAdd, label: 'Submit and Add Another' }
        }
      />
    </FormProvider>
  );
}

type ITransactionFormProps = Omit<IForm, 'fields'>;
export function TransactionForm({
  title = 'Enter Transaction(s) Information',
  variant = 'form',
}: ITransactionFormProps) {
  const transactionSelectorFields = useTransactionSelectorFields();
  const transactionFields = useTransactionFields();

  const rerender = useConversionRatioField();

  const form = createFormFromFieldsArray([...transactionSelectorFields, ...transactionFields], {
    title,
    variant,
  });

  return <FormFactoryWithStandardLayout form={form} key={rerender} />;
}

function preparePayload(
  companyId: number,
  transaction: Partial<ITransactionViewModel>,
  financeRound: Partial<IFinanceRoundDataModel> | null
) {
  const newTransaction = toTransactionDataModel(transaction);
  delete newTransaction.round;
  newTransaction.companyId = companyId;
  newTransaction.investmentRoundId = financeRound?.id;

  return newTransaction;
}

export function useTransactionResolver() {
  const getTransactionCategoryAndSubtype = useGetTransactionCategoryAndSubtype();

  const resolver: Resolver<Partial<ITransactionDataModel>> = useCallback(
    (values: Partial<ITransactionViewModel>, context, options) => {
      const { transactionCategory, transactionSubType } = getTransactionCategoryAndSubtype(
        values.transactionTypeId ?? -1
      );
      const schema = getSchemaForTransaction(transactionCategory, transactionSubType);
      return yupResolver(schema)(values, context, options);
    },
    [getTransactionCategoryAndSubtype]
  );

  return resolver;
}
