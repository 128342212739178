import { styled } from '@mui/material/styles';
import { colors } from '../../../theme/colors';

export const GridStyleWrapper = styled('div')`
  padding-top: 2rem;
  height: 70vh;
  .ag-theme-alpine .ag-root-wrapper {
    background: transparent;
    --header-container-bg-color: ${colors.primary[5]};
  }
  .ag-header:not(.security-table .ag-header) {
    border-top: solid thin ${colors.neutral[20]};
  }
`;

export const CollapsibleGridWrapper = styled(GridStyleWrapper)`
  padding: 0;
  height: 50vh;
`;
