import { useCallback, useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { addMonths, endOfMonth, endOfQuarter, endOfYear } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  selectedEndDateFinancialsState,
  selectedFrequencyFinancialsState,
} from '../../../state/CompanyFinancialsDateState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';

export interface IFinancialsDisplayOptions {
  showBudget?: boolean;
  showLTM?: boolean;
  showYTD?: boolean;
  showGrowth?: boolean;
}
export type FinancialsSettingsKey =
  | keyof IFinancialsDisplayOptions
  | 'reportedOnly'
  | 'showFuture'
  | 'showPinnedYears';

export const settingsKeyToLabelMap: Record<FinancialsSettingsKey, string> = {
  reportedOnly: 'Only Reported KPIs',
  showBudget: 'Show Budget',
  showLTM: 'Show LTM',
  showYTD: 'Show YTD',
  showGrowth: 'Show Delta Trend',
  showFuture: 'Show Future Dates',
  showPinnedYears: 'Show Years',
};

export const showReportedOnlyState = atom<boolean>({
  key: 'showReportedOnlyState',
  default: true,
});

export const showBudgetState = atom<boolean>({
  key: 'showBudgetState',
  default: true,
});

export const showLTMState = atom<boolean>({
  key: 'showLTMState',
  default: false,
});

export const showYTDState = atom<boolean>({
  key: 'showYTDState',
  default: false,
});

export const showGrowthState = atom<boolean>({
  key: 'showGrowthState',
  default: false,
});

export const showFutureState = atom<boolean>({
  key: 'showFutureState',
  default: false,
});

export type FinancialsDisplaySettings = Record<FinancialsSettingsKey, boolean>;

export function useFinancialsGridSettings() {
  const [showReportedOnly, setShowReportedOnly] = useRecoilState(showReportedOnlyState);
  const [showBudget, setShowBudget] = useRecoilState(showBudgetState);
  const [showLTM, setShowLTM] = useRecoilState(showLTMState);
  const [showYTD, setShowYTD] = useRecoilState(showYTDState);
  const [showGrowth, setShowGrowth] = useRecoilState(showGrowthState);
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const selectedPeriod = useRecoilValue(selectedFrequencyFinancialsState(companyId));
  const [showFuture, setShowFuture] = useRecoilState(showFutureState);
  const resetEndDate = useResetRecoilState(selectedEndDateFinancialsState(companyId));
  const setEndDate = useSetRecoilState(selectedEndDateFinancialsState(companyId));
  const { showFinancialsPinnedYears, showFinancialsDatePicker } = useFlags<MaggieFeatureFlags>();

  const handleToggleShowFuture = useCallback(() => {
    if (showFuture) {
      resetEndDate();
    } else {
      const endDate = addMonths(new Date(), 12);
      switch (selectedPeriod) {
        case KpiPeriod.month: {
          setEndDate(endOfMonth(endDate));
          break;
        }
        case KpiPeriod.quarter: {
          setEndDate(endOfQuarter(endDate));
          break;
        }
        case KpiPeriod.year: {
          setEndDate(endOfYear(endDate));
          break;
        }
      }
    }
    setShowFuture((prev) => !prev);
  }, [resetEndDate, selectedPeriod, setEndDate, setShowFuture, showFuture]);

  const handleSettingsChange = useCallback(
    (key: FinancialsSettingsKey) => {
      switch (key) {
        case 'reportedOnly':
          setShowReportedOnly((prev) => !prev);
          break;
        case 'showBudget':
          setShowBudget((prev) => !prev);
          break;
        case 'showLTM':
          setShowLTM((prev) => !prev);
          break;
        case 'showYTD':
          setShowYTD((prev) => !prev);
          break;
        case 'showGrowth':
          setShowGrowth((prev) => !prev);
          break;
        case 'showFuture':
          handleToggleShowFuture();
          break;
      }
    },
    [handleToggleShowFuture, setShowBudget, setShowGrowth, setShowLTM, setShowReportedOnly, setShowYTD]
  );

  const showPinnedYears = useMemo(() => {
    return showFinancialsPinnedYears && selectedPeriod !== KpiPeriod.year && !showFuture;
  }, [selectedPeriod, showFuture, showFinancialsPinnedYears]);

  const displaySettings: FinancialsDisplaySettings = useMemo(() => {
    return {
      reportedOnly: showReportedOnly,
      showBudget,
      showLTM,
      showYTD,
      showGrowth: showGrowth,
      showFuture,
      showPinnedYears,
    };
  }, [showReportedOnly, showBudget, showLTM, showYTD, showGrowth, showFuture, showPinnedYears]);

  const isOptionDisabled = useCallback(
    (key: FinancialsSettingsKey) => {
      switch (key) {
        case 'showFuture':
          return showFinancialsDatePicker;
        default:
          return false;
      }
    },
    [showFinancialsDatePicker]
  );

  return {
    displaySettings,
    handleSettingsChange,
    isOptionDisabled,
  };
}
