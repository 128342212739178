import * as yup from 'yup';
import { positiveNumMsg, requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import {
  futureDateSchema,
  transactionDateSchema,
} from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { validateRequiredForNoOfWarrants } from '../../../../../../../schemas/transactions/TransactionSchemaUtil';
import { WarrantsType } from '../../../../../../../view-models/transaction-form.view-model';
import { pricePerShareField, usdField } from '../../../../../../../schemas/common-schema-defs';

export function warrantIssuanceFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    currencyid: transactionCurrencyIdField(),
    warrantExpiryDate: futureDateSchema().required(requiredMsg('Expiration Date')),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    security: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    pricePerShare: pricePerShareField()
      .nullable()
      .transform((_, val) => (val === val ? val : null))
      .when('warrantVested', {
        is: true,
        then: (schema) => schema.positive(positiveNumMsg('Vested PPS')).required(requiredMsg('Vested PPS')),
      }),

    optionsExercisePrice: usdField().nullable().optional(),
    _viewModel: yup.object().shape({
      warrantsType: yup.string().nullable().required(requiredMsg('Warrants Type')),
    }),
    aggregatedValue: usdField()
      .nullable()
      .test(
        'aggregatedValue-required',
        requiredMsg('Total Value of Warrants'),
        (value: number | null | undefined, context: yup.TestContext<yup.AnyObject>) => {
          if (context.parent?._viewModel?.warrantsType === WarrantsType.totalValueOfWarrants) {
            return value != null;
          } else {
            return true;
          }
        }
      ),
    warrantAcquiredPrice: usdField()
      .nullable()
      .min(0, positiveNumMsg('Acquired Price Per Share'))
      .test(
        'warrantAcquiredPrice-required',
        requiredMsg('Acquired Price Per Share'),
        validateRequiredForNoOfWarrants
      ),
    warrantsIssued: yup
      .number()
      .nullable()
      .positive(positiveNumMsg('Warrants Issued'))
      .test('warrantsIssued-required', requiredMsg('Warrants Issued'), validateRequiredForNoOfWarrants),
  };
}

export function warrantIssuanceFormSchema() {
  return yup.object().shape(warrantIssuanceFields());
}

export function warrantIssuanceGridSchema() {
  return yup.object().shape(warrantIssuanceFields()).omit(['_viewModel']);
}
