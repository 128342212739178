import { useMemo } from 'react';
import { ColDef, ValueFormatterFunc, ValueGetterParams } from 'ag-grid-community';
import { capitalize } from 'lodash-es';
import { UNKNOWN_GROUP_NAME } from '../../../../../components/AgTable/tableConstants';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { CompanyCellRenderer } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { kpiFiscalDateFormatter } from '../../../common/GridFormatters';
import { FormatterService } from '../../../../../util/formatter-service';
import { DateFormattersConfig } from '../../../../../util/formatters/DateFormatters';
import { AgFormatterService } from '../../../../../util/ag-formatter-service';
import { StringListCellRenderer } from '../../../../../components/AgTable/cell-renderers/StringListCellRenderer';
import { IKpiRequestGridData } from './useKPIRequestsFilters';

export function companyComparator(a?: { name?: string }, b?: { name: string }) {
  return (a?.name ?? '').localeCompare(b?.name ?? '');
}

export function useCommonKpiColDefs() {
  const respondentFormatter = AgFormatterService.get().getFormatterForId('userByEmail');

  const commonCompanyDefs: ColDef<IKpiRequestGridData> = useMemo(
    () => ({
      field: 'company',
      headerName: 'Company',
      keyCreator: (params) => params.value?.name,
      cellRenderer: CompanyCellRenderer,
      getQuickFilterText: (params) => {
        return params.data?.company?.name ?? '';
      },
      comparator: companyComparator,
    }),
    []
  );

  const nonSelectableCompanyColDef: ColDef<IKpiRequestGridData> = useMemo(
    () => ({
      ...commonCompanyDefs,
      enableRowGroup: true,
      comparator: companyComparator,
    }),
    [commonCompanyDefs]
  );

  const respondentColDef: ColDef<IKpiRequestGridData> = useMemo(
    () => ({
      field: 'respondent',
      headerName: 'Respondent',
      valueFormatter: respondentFormatter as ValueFormatterFunc,
      cellRenderer: StringListCellRenderer,
      cellRendererParams: {
        title: 'Respondents',
      },
      minWidth: 200,
    }),
    [respondentFormatter]
  );

  const periodColDef: ColDef<IKPIRequestDataModel> = useMemo(
    () => ({
      colId: 'period',
      headerName: 'Period',
      valueGetter: (params: ValueGetterParams<IKPIRequestDataModel>) =>
        params.data && kpiFiscalDateFormatter(params),
      enableRowGroup: true,
      keyCreator: (params) => {
        if (!params.value) return '';
        return params.value ?? UNKNOWN_GROUP_NAME;
      },
    }),
    []
  );

  const templateColDef: ColDef<IKpiRequestGridData> = useMemo(
    () => ({
      field: 'template',
      headerName: 'Template',
      enableRowGroup: true,
      valueGetter: (params) => {
        if (!params.data?.template) return '';
        return params.data?.template.name;
      },
    }),
    []
  );

  const fyeColDef: ColDef<IKPIRequestDataModel> = useMemo(
    () => ({
      field: 'fye',
      headerName: 'FYE',
      valueGetter: (params) => params.data?.fye && new Date(params.data?.fye),
      valueFormatter: (params) => {
        return params.data?.fye
          ? FormatterService.get().getFormatterForModel(DateFormattersConfig.monthLong)(
              params.data?.fye as string
            )
          : '';
      },
    }),
    []
  );

  const frequencyColDef: ColDef<IKPIRequestDataModel> = useMemo(
    () => ({
      field: 'frequency',
      headerName: 'Frequency',
      valueFormatter: (params) => {
        return capitalize(params.value ?? '');
      },
      enableRowGroup: true,
    }),
    []
  );

  const granularityColDef: ColDef<IKPIRequestDataModel> = {
    field: 'granularity',
    headerName: 'Granularity',
    valueFormatter: (params) => {
      return capitalize(params.value ?? '');
    },
    enableRowGroup: true,
  };

  return {
    commonCompanyDefs,
    fyeColDef,
    nonSelectableCompanyColDef,
    templateColDef,
    frequencyColDef,
    respondentColDef,
    periodColDef,
    granularityColDef,
  };
}
