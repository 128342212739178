import { useMemo } from 'react';
import { noop } from 'lodash-es';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';
import { fundMetricsFormSchema, FundMetrics } from '../../../schemas/FundMetrics.schema';
import { FDMap } from '../../../util/data-structure/FDMap';
import { useSchemaToFormFields } from '../../../util/schema-utils';
import { createForm } from '../../../view-models/form.view-model';
import { usePanelPagination, FieldsWrapper } from '../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { Panel } from '../../CompanyProfiles2/Summary/Panel';
import { UnstyledFieldset } from '../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { useFundMetricsFP } from '../useFundMetricsFP';
import { FundCompanyData, fundCompanyDataFormSchema, useFundCompanyData } from './useFundCompanyData';

const metricsFields: FieldPath<FundMetrics>[] = [
  'activationDate',
  'ttvpi',
  'dpi',
  'investmentAmount',
  'unrealizedValue',
  'grossIRR',
  'netIRR',
  'lossRatio',
  'capitalLossRatio',
  'capitalCalledPercent',
  'distributions',
  'contributions',
  'initialInvestmentAmount',
  'followOnInvestmentAmount',
  'grossMOIC',
  'netMOIC',
  'realizedGL',
  'unrealizedGL',
];

export function FundMetricsPanel() {
  const schemaToFormFields = useSchemaToFormFields();

  const fundCompanySchema = fundCompanyDataFormSchema();
  const fundCompanyFields = schemaToFormFields(fundCompanySchema);
  const fundCompanyData = useFundCompanyData();

  const fundMetricsSchema = fundMetricsFormSchema().pick(metricsFields);
  const fundMetricsFields = schemaToFormFields(fundMetricsSchema);

  const fields = useMemo(
    () => [...fundCompanyFields, ...fundMetricsFields].map((f) => ({ ...f, required: false })),
    [fundCompanyFields, fundMetricsFields]
  );

  const { paginationConfig, visibleFields } = usePanelPagination({
    fields,
    itemsPerPage: 10,
  });
  const metrics = useFundMetricsFP();

  const methods = useForm({
    values: { ...(metrics?.metrics ?? {}), ...fundCompanyData },
  });

  if (!metrics) return null;

  const form = createForm<FundMetrics | FundCompanyData>({
    fields: FDMap.fromArray(visibleFields, 'key'),
    data: methods.getValues() ?? {},
    variant: 'form-inline',
  });

  return (
    <Panel title={'Fund Metrics'} paginationConfig={paginationConfig} showContentOverflow={true}>
      <UnstyledFieldset disabled>
        <FieldsWrapper style={{ minHeight: '22rem' }}>
          <FormProvider {...methods}>
            <FormFactoryNoLayout form={form} onChange={noop} />
          </FormProvider>
        </FieldsWrapper>
      </UnstyledFieldset>
    </Panel>
  );
}
