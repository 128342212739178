import * as yup from 'yup';
import { positiveNumMsg, requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import {
  conversionRatioField,
  integerField,
  usdField,
} from '../../../../../../../schemas/common-schema-defs';

export function noteConversionFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    convertedSharesNo: integerField()
      .required(requiredMsg('Number of Converted Shares'))
      .positive(positiveNumMsg('Number of Converted Shares'))
      .label('Number of Converted Shares'),
    coInvestors: yup.array().optional().nullable(),
    convertedPrincipalAmount: usdField()
      .required(requiredMsg('Converted Principal'))
      .positive(positiveNumMsg('Converted Principal')),
    convertedInterestAmount: usdField()
      .required(requiredMsg('Converted Interest'))
      .min(0, 'Converted Interest must be at least 0'),
    currencyid: transactionCurrencyIdField(),
    fullyDilutedShares: integerField().nullable().optional(),
    leadInvestor: yup.string().optional().nullable(),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    postMoneyValuation: usdField().min(0, positiveNumMsg('Post Money Valuation')).optional().nullable(),
    raiseAmount: usdField().min(0, positiveNumMsg('Raise Amount')).optional().nullable(),
    security: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    conversionRatio: conversionRatioField(),
  };
}

export function noteConversionFormSchema() {
  return yup.object().shape(noteConversionFields());
}

export function noteConversionGridSchema() {
  return yup
    .object()
    .shape(noteConversionFields())
    .omit(['fullyDilutedShares', 'postMoneyValuation', 'raiseAmount', 'coInvestors', 'leadInvestor']);
}
