import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { theme } from '../../../../theme';
import { CompanyLogoAndName } from '../../../grid-renderers/CompanyCellRenderer';

import { cardStyles } from '../../../../theme/component-styles';

interface Props {
  name: string;
  logoURL: string;
  round: string;
  date: Date;
}

const { critical, primary, neutral, warning, secondary } = theme.colors;

const RoundWrapper = styled(Typography)<{ backgroundColor: string }>`
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const DateWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IRoundMeta {
  backgroundColor: string;
  color: string;
  label: string;
}

const RoundMeta: Record<string, IRoundMeta> = {
  'Series A': {
    backgroundColor: critical[10],
    color: critical[50],
    label: 'A',
  },
  'Series B': {
    backgroundColor: critical[10],
    color: critical[50],
    label: 'B',
  },
  'Series C': {
    backgroundColor: primary[10],
    color: primary[60],
    label: 'C',
  },
  'Series D': {
    backgroundColor: critical[10],
    color: critical[50],
    label: 'D',
  },
  'Series E': {
    backgroundColor: critical[10],
    color: critical[50],
    label: 'E',
  },
  'Series F': {
    backgroundColor: warning[10],
    color: warning[60],
    label: 'F',
  },
  'Series G': {
    backgroundColor: secondary[10],
    color: secondary[60],
    label: 'G',
  },
};

function getRoundMeta(roundId: keyof typeof RoundMeta) {
  return (
    RoundMeta[roundId] ?? {
      backgroundColor: neutral[10],
      color: 'black',
      label: roundId,
    }
  );
}

const StyledRow = styled('li')`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: subgrid;
  @supports not (grid-template-columns: subgrid) {
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
  }
`;

export function MostRecentRoundsRow({ name, logoURL, round, date }: Props): ReactElement {
  const { backgroundColor, color, label } = getRoundMeta(round as keyof typeof RoundMeta);

  return (
    <StyledRow style={{ ...cardStyles, padding: '0.25rem 0.5rem' }}>
      <CompanyLogoAndName name={name} logoUrl={logoURL} />
      <RoundWrapper backgroundColor={backgroundColor} variant='caption' color={color} textAlign={'center'}>
        {label}
      </RoundWrapper>
      <DateWrapper>
        <Typography variant={'body2'} color={primary[100]}>
          {date.toDateString().split(' ').slice(1, 4).join(' ')}
        </Typography>
      </DateWrapper>
    </StyledRow>
  );
}
