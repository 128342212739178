import { PropsWithChildren } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { Label } from './Form/FormComponents';

const FieldBox = css`
  display: grid;
  gap: 0.25rem;
`;

interface ILabeledFieldBoxProps extends Pick<BoxProps, 'component' | 'className'>, PropsWithChildren {}
export function LabeledFieldBox({ children, component, className }: ILabeledFieldBoxProps) {
  return (
    <Box css={FieldBox} component={component ?? 'li'} className={className}>
      {children}
    </Box>
  );
}

interface ILabeledFieldProps extends Pick<ILabeledFieldBoxProps, 'className' | 'component'> {
  label: string;
  value: string;
  description?: string;
}
export function LabeledField({ label, component, className, value, description }: ILabeledFieldProps) {
  return (
    <LabeledFieldBox component={component ?? 'li'} className={className}>
      <Label description={description}>{label}</Label>
      <Typography color='text.primary' variant='body2' component={'div'}>
        {value}
      </Typography>
    </LabeledFieldBox>
  );
}

export const LabeledFieldContainer = css`
  all: unset;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, auto));
  gap: 1rem;
`;
