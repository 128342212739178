import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { FMT } from '../../../util/formatter-service';
import { NumericFormattersConfig } from '../../../util/formatters/NumericFormatters';

export interface IDigestPanelViewModel extends ICompanyDataModel {
  _viewModel: {
    ceoNames: string[];
    lastFunding?: string;
    raisedToDate?: number | null;
  };
}

export function fromCompanyDataModels(company: ICompanyDataModel): IDigestPanelViewModel {
  return {
    ...company,
    _viewModel: {
      ceoNames: company.ceoName ? company.ceoName.split(',').map((name) => name.trim()) : [],
      lastFunding: formatRoundData(company),
      raisedToDate: company.companyType === 'portfolio' ? company.raisedToDate : company.totalPIC,
    },
  };
}

export function toCompanyDataModels(viewModel: Partial<IDigestPanelViewModel>): Partial<ICompanyDataModel> {
  const { _viewModel, ...rest } = viewModel;
  const payload: Partial<ICompanyDataModel> = rest;

  if (_viewModel?.ceoNames) {
    payload.ceoName = _viewModel?.ceoNames.join(', ');
  }

  return payload;
}

function formatRoundData(company: ICompanyDataModel) {
  return `${company.latestFundingRound ? `${company.latestFundingRound} - ` : ''}${
    company.latestFundingAmount != null
      ? FMT.format(NumericFormattersConfig.usdShort, company.latestFundingAmount)
      : ''
  }`;
}
