import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IKpiSelectItem, selectedKpisItemState } from '../../../state/ProfileViewSettings';
import { kpiConfigByIdMapState } from '../../../../../services/state/KPI/KPITemplatesState';
import { IField } from '../../../../../data-models/field2.data-model';
import { selectedCompanyIdProfile } from '../../../state/UIState';

export function useKpiSelector() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const setSelectedStatistics = useSetRecoilState(selectedKpisItemState(companyId));
  const kpiMap = useRecoilValue(kpiConfigByIdMapState);

  const handleDelete = useCallback(
    (id: string) => {
      setSelectedStatistics((prev) => prev.filter((item) => String(item.id) !== id));
    },
    [setSelectedStatistics]
  );

  const handleReorder = useCallback(
    (ids: string[]) => {
      setSelectedStatistics(
        ids.map((id) => {
          const kpi = kpiMap.get(Number(id))!;
          return mapIFieldToSelectItem(kpi);
        })
      );
    },
    [kpiMap, setSelectedStatistics]
  );

  return { handleDelete, handleReorder };
}

export function mapIFieldToSelectItem(ifield: IField<unknown>): IKpiSelectItem {
  return {
    id: ifield.id,
    value: ifield.displayName,
    group: ifield.gridMeta?.group,
  };
}
