import { Stack, Typography } from '@mui/material';
import { cardStyles } from '../../../theme/component-styles';
import Archive from '../../../assets/icons/archive.svg?react';

export function NoRubricsMessage() {
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={cardStyles}
      minHeight={'70dvh'}
      alignSelf={'stretch'}
    >
      <Archive />
      <Stack textAlign={'center'}>
        <Typography variant='h4' mt='1rem' mb='1rem'>
          No Rubrics Created
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          You can create firm-wide rubrics.
          <br />
          Those will be your unique vectors to measure company performance against.
        </Typography>
      </Stack>
    </Stack>
  );
}
