import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import { CompanyColId } from './useKPICompaniesAllColDefs';

export function useAssignedFilter() {
  const companyFilter = useLocation().state?.companyName;

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      event.api?.getColumnFilterInstance(CompanyColId).then((companyFilterModel) => {
        if (companyFilterModel && companyFilter) {
          companyFilterModel.setModel({ values: [companyFilter] });
          event.api.onFilterChanged();
        }
      });
    },
    [companyFilter]
  );

  return { onGridReady };
}
