import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedFundStateFP, showFundFormState, useResetFundFormState } from '../state/FPState';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { FundForm, IFundFormProps } from './FundForm';

export function FundFormModal({ defaultValues, onSubmit }: IFundFormProps) {
  const fund = useRecoilValue(selectedFundStateFP);
  const [open, setOpen] = useRecoilState(showFundFormState);
  const resetFormState = useResetFundFormState();

  if (!fund) return null;

  return (
    <BasicDialog
      open={open}
      title={defaultValues?.name ? `Update ${defaultValues.name} Settings` : 'Create New Fund'}
      onClose={() => {
        setOpen(false);
        resetFormState();
      }}
    >
      {open && <FundForm defaultValues={defaultValues} onSubmit={onSubmit} />}
    </BasicDialog>
  );
}
