import { Fund } from '../../schemas/Fund.schema';
import { FundData } from '../../schemas/FundData.schema';
import { MaggieHttp } from '../MaggieHttp';

export interface IUpdateFundFields extends Partial<Fund> {
  id: number;
}

export async function updateFund(fund: IUpdateFundFields): Promise<Fund> {
  const response = await MaggieHttp.maggie.put<IUpdateFundFields, Fund>(`/fund/${fund.id}`, fund);
  return response.data;
}
export async function fetchFundData(fundId: number): Promise<FundData[]> {
  const response = await MaggieHttp.maggie.get<FundData[]>(`/fund/${fundId}/data`);
  return response.data;
}

export interface IUpdateFundDataPayload
  extends Partial<Pick<FundData, 'netAssets' | 'deemedContributions' | 'contributedSecurities'>>,
    Pick<FundData, 'id' | 'fundId' | 'date'> {}

export async function updateFundData(fundData: IUpdateFundDataPayload): Promise<FundData> {
  const response = await MaggieHttp.maggie.put<IUpdateFundDataPayload, FundData>(
    `/fund/data/${fundData.id}`,
    fundData
  );
  return response.data;
}
export interface ICreateFundDataPayload
  extends Partial<Pick<FundData, 'netAssets' | 'deemedContributions' | 'contributedSecurities'>>,
    Pick<FundData, 'fundId' | 'date'> {}
export async function createFundData(fundData: ICreateFundDataPayload): Promise<FundData> {
  const response = await MaggieHttp.maggie.post<ICreateFundDataPayload, FundData>(`/fund/data`, fundData);
  return response.data;
}
