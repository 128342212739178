import { useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { AgGridReact } from 'ag-grid-react';
import { GetRowIdParams } from 'ag-grid-community';
import { SecurityTableStyleWrapper } from '../Security/SecurityTableStyleWrapper';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { securityReportState } from '../state/FinanceState';
import { AgTable } from '../../../components/AgTable/AgTable';
import { useExtendedExportSettings } from '../../../components/AgTable/exportToExcelDefs';
import { SecurityReport } from '../../../schemas/SecurityReport.schema';
import {
  AllSecuritiesAutoGroupColDef,
  AllSecuritiesDefaultColDefs,
  useAllSecuritiesColDefs,
} from './AllSecuritiesColDefs';
import { useAllSecuritiesFilterState } from './AllSecuritiesFilterState';

export function AllSecuritiesGrid() {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = useAllSecuritiesColDefs();
  const data = useRecoilValue(securityReportState);
  const { exportParams } = useExtendedExportSettings(new Set(['companyId', 'fundId']), 'All Securities');
  const { doesExternalFilterPass, isExternalFilterPresent } = useAllSecuritiesFilterState(gridRef);

  const getRowId = useCallback((params: GetRowIdParams) => String(params.data?.restructureId), []);

  return (
    <>
      <SecurityTableStyleWrapper style={{ paddingTop: '1rem', height: '72vh' }}>
        <FadeInGridWrapper>
          <AgTable
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={data as SecurityReport[]}
            defaultColDef={AllSecuritiesDefaultColDefs}
            autoGroupColumnDef={AllSecuritiesAutoGroupColDef}
            defaultCsvExportParams={exportParams}
            defaultExcelExportParams={exportParams}
            groupDefaultExpanded={-1}
            doesExternalFilterPass={doesExternalFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            getRowId={getRowId}
          />
        </FadeInGridWrapper>
      </SecurityTableStyleWrapper>
    </>
  );
}
