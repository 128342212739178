import { styled } from '@mui/material/styles';
import {
  ColDef,
  ColumnResizedEvent,
  CsvExportParams,
  ExcelExportParams,
  GridOptions,
  GridReadyEvent,
  InitialGroupOrderComparatorParams,
  KeyCreatorParams,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { ForwardedRef, forwardRef, memo, useCallback, useMemo } from 'react';
import { GRID_ROW_HEIGHT } from '../../theme/gridStyle';
import { genericComparator } from '../../util/comparators';
import { customColumnTypes } from './customColumnTypes';
import { customTableNotation } from './customTableNotation';
import { commonProcessCb, getExportParams, useExportToExcelStyles } from './exportToExcelDefs';
import { UNKNOWN_GROUP_NAME } from './tableConstants';

const AgTableForwarded = forwardRef(function AgTable(
  props: AgGridReactProps,
  ref: ForwardedRef<AgGridReact<unknown>>
) {
  const initialGroupOrderComparator = useCallback(
    (params: InitialGroupOrderComparatorParams) => {
      if (typeof props?.initialGroupOrderComparator === 'function') {
        return props?.initialGroupOrderComparator(params);
      }

      const a = params.nodeA.key || '';
      const b = params.nodeB.key || '';
      return a.localeCompare(b, 'en', { sensitivity: 'base' });
    },
    [props]
  );

  const defaultColDef: ColDef = useMemo(
    () => ({
      width: 120,
      suppressSizeToFit: true,
      resizable: true,
      sortable: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      filter: true,

      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          // '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' + // comment out to hide actual filter icon in the header
          '  </div>' +
          '</div>',
      },
      wrapHeaderText: true,
      autoHeaderHeight: true,
      keyCreator: (params: KeyCreatorParams) => params.value ?? UNKNOWN_GROUP_NAME,
      ...(props?.defaultColDef ?? {}),
      filterParams: {
        buttons: ['reset'],
        newRowsAction: 'keep',
        convertValuesToStrings: true,
        ...props?.defaultColDef?.filterParams,
      },
    }),
    [props?.defaultColDef]
  );

  const onColumnResized = useCallback(
    (event: ColumnResizedEvent) => {
      if (typeof props.onColumnResized === 'function') {
        props.onColumnResized(event);
        return;
      }
    },
    [props]
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      props.gridOptions?.onGridReady?.(params);
    },
    [props.gridOptions]
  );

  const excelStyles = useExportToExcelStyles();

  const gridOptions: GridOptions = useMemo(
    () => ({
      ...customTableNotation,
      ...gridStyleOptions,
      columnTypes: { ...customColumnTypes },
      excelStyles: excelStyles,
      ...(props?.gridOptions ?? {}),
      onGridReady,
    }),
    [excelStyles, onGridReady, props?.gridOptions]
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      width: 200,
      sortable: true,
      pinned: 'left',
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      comparator: genericComparator,
      ...(props?.autoGroupColumnDef ?? {}),
    }),
    [props?.autoGroupColumnDef]
  );

  const defaultExcelExportParams = useMemo(() => {
    const customParams = getExportParams(props.defaultExcelExportParams as ExcelExportParams);
    return {
      processCellCallback: commonProcessCb,
      ...customParams,
    } as ExcelExportParams;
  }, [props.defaultExcelExportParams]);

  const defaultCsvExportParams = useMemo(() => {
    const customParams = getExportParams(props.defaultCsvExportParams);
    return {
      processCellCallback: commonProcessCb,
      ...customParams,
    } as CsvExportParams;
  }, [props.defaultCsvExportParams]);

  return (
    <StyledTableWrapper className='ag-theme-alpine' data-testid='ag-table'>
      <AgGridReact
        ref={ref}
        className='gc-grid'
        groupIncludeTotalFooter
        animateRows
        suppressDragLeaveHidesColumns
        {...props}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        gridOptions={gridOptions}
        initialGroupOrderComparator={initialGroupOrderComparator}
        onColumnResized={onColumnResized}
        defaultExcelExportParams={defaultExcelExportParams}
        defaultCsvExportParams={defaultCsvExportParams}
      />
    </StyledTableWrapper>
  );
});

const gridStyleOptions: GridOptions = {
  rowHeight: GRID_ROW_HEIGHT,
  rowGroupPanelShow: 'always',
  suppressMenuHide: true,
  groupDisplayType: 'singleColumn',
  icons: {
    // always show row group panel message:
    // (hacky, but does the job)
    rowGroupPanel: () => '<span>Drag Column here to set row groups</span>',
    smallRight: () => '<span></span>',
    smallLeft: () => '<span></span>',
  },
};

const StyledTableWrapper = styled('div')`
  margin: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0px -4px 40px rgb(255 255 255 / 6%), 0px 4px 24px rgb(16 37 62 / 4%),
    0px 24px 48px rgb(75 82 93 / 2%);

  & * ::-webkit-scrollbar-thumb {
    background: var(--thumbBG);
    border: thin solid #ffffff40;
    border-radius: 8px;
  }
`;

export const AgTable = memo(AgTableForwarded);
