import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';
import { useUpdateCompanyAndState } from '../../../services/hooks/useUpdateCompanyAndState';
import { ICompanyDataModel } from '../../../data-models/company.data-model';

export function useDebounceCompanyUpdates(companyId: number) {
  const updateCompany = useUpdateCompanyAndState();
  const [payload, setPayload] = useState<Partial<ICompanyDataModel>>();

  const savePayload = useMemo(() => {
    return debounce((data) => {
      setPayload(data);
    }, 500);
  }, []);

  useEffect(() => {
    if (payload) {
      updateCompany(companyId, payload);
    }
  }, [companyId, payload, updateCompany]);

  return useCallback(
    (data: Partial<ICompanyDataModel>) => {
      savePayload(data);
    },
    [savePayload]
  );
}
