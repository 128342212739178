import { FieldPath, FieldValues, PathValue, UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { get } from 'lodash-es';

interface ISyncFieldsParams<T extends FieldValues> {
  methods: UseFormReturn<T>;
  field1Path: FieldPath<T>;
  field2Path: FieldPath<T>;
  calculateField1Value: (field2Value?: PathValue<T, FieldPath<T>>) => PathValue<T, FieldPath<T>>;
  calculateField2Value: (field1Value?: PathValue<T, FieldPath<T>>) => PathValue<T, FieldPath<T>>;
}
export function useSyncFields<T extends FieldValues>({
  methods,
  field1Path,
  field2Path,
  calculateField1Value,
  calculateField2Value,
}: ISyncFieldsParams<T>) {
  const [forceRerenderField1, setForceRerenderField1] = useState(0);
  const [forceRerenderField2, setForceRerenderField2] = useState(0);

  const { watch, setValue } = methods;
  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (type !== 'change') return; // prevent infinite loop
      const val1 = get(data, field1Path);
      const val2 = get(data, field2Path);
      if (name === field1Path && val1 != null) {
        setValue(field2Path, calculateField2Value(val1), {
          shouldDirty: true,
          shouldValidate: true,
        });
        setForceRerenderField2((prev) => prev + 1);
      } else if (name === field2Path && val2 != null) {
        setValue(field1Path, calculateField1Value(val2), {
          shouldDirty: true,
          shouldValidate: true,
        });
        setForceRerenderField1((prev) => prev + 1);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  });

  return { forceRerenderField1, forceRerenderField2 };
}
