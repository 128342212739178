export enum UploadStatus {
  uploading = 'Uploading',
  success = 'Success',
  error = 'Error',
}

export const BulkUploadStatusEvent = 'processBulkUpload';

export interface IBulkUploadStatusMessageDataModel {
  fileName: string;
  status: UploadStatus;
  note?: string; // currently only comes back from BE for failed status
}
