import { useRecoilValue } from 'recoil';
import { css } from '@emotion/react';
import {
  rubricConfigState,
  mostRecentRubricsByCompanyIdState,
} from '../../../../../services/state/RubricsState';
import { SubSection } from '../../../Summary/components/SubSection';
import { selectedMetricsDateCPState } from '../../../state/CompanyFinancialsDateState';
import { getISODate } from '../../../../../services/queries/MaggieMetricsQueries';
import { ContainerName } from '../../../../../constants/styles';
import { RubricList } from './RubricList';

const RubricsContainer = css`
  container-name: ${ContainerName.rubricSectionContainer};
  container-type: inline-size;
`;

interface IRubricSectionProps {
  companyId: number;
}
export function RubricSection({ companyId }: IRubricSectionProps) {
  useRecoilValue(rubricConfigState);
  const date = useRecoilValue(selectedMetricsDateCPState(companyId));
  const rubrics = useRecoilValue(mostRecentRubricsByCompanyIdState({ companyId, date: getISODate(date) }));

  return (
    <div css={RubricsContainer}>
      <SubSection
        title={'Performance Assessment'}
        collapseProps={{ collapsible: true, defaultExpanded: true }}
        key={`${companyId}-${getISODate(date)}`}
      >
        <RubricList companyId={companyId} rubrics={rubrics} />
      </SubSection>
    </div>
  );
}
