import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Alert } from '@mui/material';
import { showModalStateFinance, selectedTransactionStateFinance } from '../state/FinanceUIState';
import { TransactionModals } from '../Transactions/modals/TransactionModals';
import { Loader } from '../../../components/Loader/Loader';
import { forceAllTransactionsByDateUpdate } from '../state/FinanceState';
import { AllTransactionsGrid } from './AllTransactionsGrid';
import { useAllTransactionsData } from './useAllTransactionsData';
import { AllTransactionFilters } from './AllTransactionFilters';
import { useResetTransactionFilters } from './AllTransactionsFilterState';

export function AllTransactions() {
  const resetFilters = useResetTransactionFilters();

  useEffect(() => {
    return resetFilters;
  }, [resetFilters]);

  return <AllTransactionsContent />;
}

export function AllTransactionsContent() {
  const resetModalState = useResetRecoilState(showModalStateFinance);
  const resetSelectedTransaction = useResetRecoilState(selectedTransactionStateFinance);
  const forceRefetchCount = useRecoilValue(forceAllTransactionsByDateUpdate);
  const { loading, error, allTransactions } = useAllTransactionsData({ forceRefetchCount });

  useEffect(() => {
    return () => {
      resetModalState();
      resetSelectedTransaction();
    };
  });

  if (loading) return <Loader />;
  if (error) return <Alert severity='error'>Error fetching transactions</Alert>;

  return (
    <>
      <AllTransactionFilters />
      <AllTransactionsGrid transactions={allTransactions ?? []} />
      <TransactionModals />
    </>
  );
}
