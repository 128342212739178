import { Audit } from '../types';
import { MetricsTransactionDataModel } from '../schemas/MetricsTransaction.schema';
import { CompanySearchResponse } from '../schemas/CompanySearchResponse.schema';
import { ICurrencyDataModel } from './currency.data-model';
import { IFinanceRoundDataModel } from './finance-round.data-model';
import { IFundDataModel } from './fund.data-model';
import { IRoundDataModel } from './round.data-model';

export enum ProceedsTypes {
  Cash = 'Cash',
  StockProceeds = 'Stock Proceeds',
}

export enum CouponType {
  Simple = 'Simple',
  Compound = 'Compound',
  None = 'None',
}

export enum CompoundingPeriod {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annual = 'Annual',
  None = 'None',
}

export enum ExitType {
  acquisition = 'Acquisition',
  IPO = 'IPO',
  soldBack = 'Sold Back',
  liquidated = 'Liquidated',
  tenderOfferSecondary = 'Tender Offer/Secondary',
}

export enum MiscPaymentType {
  capitalizedExpense = 'Capitalized Expense',
  cashReceived = 'Cash Received',
}

export enum OwnershipChangeReason {
  optionPoolTopUp = 'Option Pool Top-Up',
  correction = 'Correction',
}

export interface ITransactionDataModel {
  accruedInterest?: number;
  acquirer?: CompanySearchResponse | null;
  aggregatedValue?: number | null;
  amount?: number;
  amountNormalized?: number;
  audits: Audit[];
  cashReceived?: number;
  coInvestors?: string[];
  companyId: number;
  compoundingFrequency?: string | null;
  conversionRatio?: number | null;
  convertedInterestAmount?: number;
  convertedPricePerShare?: number;
  convertedPrincipalAmount?: number;
  convertedRound?: string | null;
  convertedRoundId?: number | null;
  convertedSharesNo?: number;
  coupon?: number;
  // FIXME: MAGGIE-5488 type of couponType should be CouponType
  couponType?: string | null;
  createdAt?: string;
  createdBy?: string;
  currency: ICurrencyDataModel | null;
  currencyid: number;
  currentFXRate?: number;
  currentInvestment?: number;
  deadLead: null;
  deadLeadId: null;
  discountRate: number | null;
  distributions?: number;
  escrowAmount: number;
  exchangedAmount: number | null;
  exchangedPricePerShare: number | null;
  exchangedRound: IRoundDataModel | null;
  exchangedRoundId: number | null;
  exchangedSharesNo: number | null;
  exchangeRate: number;
  exercisedWarrantsNo: number | null;
  exitOwnershipPercentage?: number;
  exitType: string | null;
  exitValuation: number | null;
  expirationDate?: Date;
  fmvBasis: null;
  fullyDilutedShares: number;
  fund?: IFundDataModel;
  fundAmount: number;
  fundId: number | null;
  id: number;
  interestPaymentAmount: number | null;
  investmentAmount?: number | null;
  investmentRoundId?: number | null;
  investmentType?: string | null;
  leadInvestor?: string | null;
  ledgerId: null;
  linkedNoteId: number | null;
  linkedOptionId: null;
  linkedTransactionId?: number | null;
  linkedWarrantIssuanceId?: number | null;
  maturityDate?: string | null;
  miscPaymentAmount?: number | null;
  miscPaymentType?: MiscPaymentType | null;
  newFullyDilutedShares?: number;
  newSharesIssued?: number | null;
  noOfShares?: number;
  noOfSharesNormalized?: number;
  notes?: null | string;
  optionsExercisedNo?: number | null;
  optionsExercisePrice?: number | null;
  optionsExpiryDate?: string | null;
  optionsIssuedNo?: number | null;
  optionsStrikePrice?: number | null;
  paymentInKind?: boolean;
  pikCadence?: string | null;
  pikEndDate?: string | null;
  pikStartDate?: string | null;
  position?: string;
  postMoneyValuation?: number | null;
  ppsShareClass: Record<string, number> | null;
  ppsSharesReceived?: null | number;
  pricePerShare: number;
  pricipalPaymentAmount?: number | null;
  principalAmount?: number | null;
  principalWriteOffAmount?: number | null;
  proceedsType?: string[] | null;
  quantity?: number;
  raiseAmount?: number;
  realizedInvestment?: number;
  reason: string | null;
  receivedAmount?: number;
  receivedPricePerShare?: number | null;
  receivedRound: IRoundDataModel | null;
  receivedRoundId?: null | number;
  receivedSharesNo?: number | null;
  remainingShares?: number;
  restructureId?: null | string;
  round?: Partial<IFinanceRoundDataModel> | null; // Remove this from here
  roundId: number | null;
  roundNormalized?: IRoundDataModel | null;
  security: string | null;
  shares?: number;
  sharesReceived?: null | number;
  status: string | null;
  transactionDate: string;
  transactionStatus?: string;
  transactionTypeId: number;
  transType?: string;
  triggerPrice?: number | null;
  unrealizedInvestment?: number;
  updatedAt?: string;
  updatedBy?: string | null;
  valuationAmount?: number;
  valuationCap?: number | null;
  valuationDate?: null;
  valuationImpact?: boolean;
  verified?: boolean;
  warrantAcquiredPrice?: number;
  warrantExercisePrice?: number;
  warrantExpiryDate?: string | null;
  warrantsIssued?: number | null;
  warrantVested?: boolean | null;
}

export function createITransactionDataModel(
  overrides: Partial<ITransactionDataModel> = {}
): ITransactionDataModel {
  return {
    accruedInterest: undefined,
    acquirer: null,
    amount: undefined,
    amountNormalized: undefined,
    audits: [],
    cashReceived: undefined,
    companyId: 0,
    compoundingFrequency: null,
    conversionRatio: null,
    convertedInterestAmount: undefined,
    convertedPricePerShare: undefined,
    convertedPrincipalAmount: undefined,
    convertedRound: null,
    convertedRoundId: null,
    convertedSharesNo: undefined,
    coupon: undefined,
    couponType: null,
    currency: null,
    currencyid: 1,
    currentFXRate: undefined,
    currentInvestment: undefined,
    deadLead: null,
    deadLeadId: null,
    discountRate: null,
    distributions: 0,
    escrowAmount: 0,
    exchangedAmount: null,
    exchangedPricePerShare: null,
    exchangedRound: null,
    exchangedRoundId: null,
    exchangedSharesNo: null,
    exchangeRate: 1,
    exercisedWarrantsNo: null,
    exitOwnershipPercentage: undefined,
    exitType: null,
    exitValuation: null,
    expirationDate: new Date(),
    fmvBasis: null,
    fullyDilutedShares: 0,
    fundAmount: 0,
    fundId: null,
    id: 0,
    interestPaymentAmount: null,
    investmentAmount: 0,
    ledgerId: null,
    linkedNoteId: null,
    linkedOptionId: null,
    linkedTransactionId: null,
    linkedWarrantIssuanceId: null,
    maturityDate: null,
    miscPaymentAmount: null,
    miscPaymentType: null,
    newFullyDilutedShares: undefined,
    newSharesIssued: null,
    noOfShares: undefined,
    noOfSharesNormalized: undefined,
    notes: '',
    optionsExercisedNo: null,
    optionsExercisePrice: null,
    optionsExpiryDate: null,
    optionsIssuedNo: null,
    optionsStrikePrice: null,
    paymentInKind: false,
    pikCadence: null,
    pikEndDate: null,
    pikStartDate: null,
    position: '',
    postMoneyValuation: null,
    ppsSharesReceived: null,
    ppsShareClass: null,
    pricePerShare: 0,
    pricipalPaymentAmount: null,
    principalAmount: null,
    principalWriteOffAmount: undefined,
    proceedsType: null,
    quantity: undefined,
    raiseAmount: undefined,
    realizedInvestment: undefined,
    receivedAmount: undefined,
    receivedPricePerShare: null,
    receivedRound: null,
    receivedRoundId: null,
    receivedSharesNo: null,
    remainingShares: undefined,
    reason: null,
    restructureId: '',
    roundId: null,
    investmentRoundId: null,
    security: null,
    shares: undefined,
    sharesReceived: null,
    status: null,
    transactionDate: '',
    transactionTypeId: 0,
    transType: '',
    triggerPrice: null,
    unrealizedInvestment: undefined,
    valuationAmount: undefined,
    valuationCap: null,
    valuationDate: null,
    valuationImpact: false,
    verified: false,
    warrantAcquiredPrice: undefined,
    warrantExpiryDate: null,
    warrantsIssued: null,
    warrantVested: null,
    ...overrides,
  };
}

export interface IExternalTransaction {
  amountNormalized: number;
  companyId: number;
  fundId: number | null;
  id: number;
  linkedTransactionId?: number | null;
  noOfSharesNormalized: number;
  position: string;
  pricePerShare: number;
  restructureId: string;
  roundId: number | null;
  roundNormalized?: IRoundDataModel | null;
  security: string | null;
  status: string;
  transactionDate: string;
  transactionTypeId: number;
}

export function createExternalTransactionDataModel(
  overrides?: Partial<IExternalTransaction>
): IExternalTransaction {
  return {
    id: 0,
    position: '',
    restructureId: '',
    fundId: null,
    companyId: 0,
    transactionDate: '',
    status: '',
    transactionTypeId: 0,
    roundId: null,
    noOfSharesNormalized: 0,
    pricePerShare: 0,
    amountNormalized: 0,
    roundNormalized: null,
    security: null,
    linkedTransactionId: null,
    ...overrides,
  };
}

export function isInternalTransaction(
  t: ITransactionDataModel | IExternalTransaction
): t is ITransactionDataModel {
  // some internal transactions don't have a linkedTransactionId (apparently those not returned when fetching all transactions)
  return t.id === t.linkedTransactionId || Number.isInteger(t.id); // won't be coerced to number
}

export function isRealizedCostTransaction(transactionType: string) {
  return (
    transactionType === 'Investment - Return of Capital' ||
    transactionType === 'Investment - Write Off' ||
    transactionType === 'Investment - Capitalized Expenses'
  );
}

export function isRestructureTransaction(transactionType: string) {
  return (
    transactionType === 'Investment - Restructure In' || transactionType === 'Investment - Restructure Out'
  );
}

export function isReturnOfCapital(transactionType: string) {
  return transactionType === 'Investment - Return of Capital' || transactionType === 'Investment - Write Off';
}

export function isEscrowTransaction(transactionType: string) {
  return (
    transactionType === 'Escrow Receivable - Cash Proceeds' ||
    transactionType === 'Escrow Receivable' ||
    transactionType === 'Proceed Receivable' ||
    transactionType === 'Proceed Received' ||
    transactionType === 'Escrow Receivable - Principal'
  );
}

export function isRealizedGainLoss(transaction: MetricsTransactionDataModel) {
  const transType = transaction?.transType?.toLowerCase();
  return transType === 'realized gain' || transType === 'realized loss';
}

export function hasPositionData(transaction: MetricsTransactionDataModel) {
  return transaction.restructureId && transaction.position;
}
