import { useRecoilValue } from 'recoil';
import { selectedDateFPState, selectedFundIdStateFP, selectedFundMetricsStateFP } from './state/FPState';

export function useFundMetricsFP() {
  const date = useRecoilValue(selectedDateFPState);
  const fundId = useRecoilValue(selectedFundIdStateFP);

  const metrics = useRecoilValue(selectedFundMetricsStateFP({ date, fundId }))?.metrics?.at(0);

  return metrics;
}
