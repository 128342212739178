import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { GridApi, IRowNode } from 'ag-grid-community';
import { transactionTypesMapState } from '../../../services/state/AppConfigState';
import { IExtendedTransactionDataModel } from './useAllTransactionsData';
import { fundFilterState, transactionTypeFilterState } from './AllTransactionsFilterState';

export function useAllTransactionsGridFilters(gridApi: GridApi | null) {
  const fundFilter = useRecoilValue(fundFilterState);
  const transactionTypeFilter = useRecoilValue(transactionTypeFilterState);

  const funds = useMemo(() => new Set(fundFilter?.map((f) => f.id)), [fundFilter]);
  const transactionTypes = useMemo(
    () => new Set(transactionTypeFilter?.map((t) => t.value)),
    [transactionTypeFilter]
  );
  const transactionSubtypesMap = useRecoilValue(transactionTypesMapState);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<IExtendedTransactionDataModel>) => {
      const { data } = node;
      if (!data) return false;
      return (
        (!fundFilter || funds.has(data.fundId ?? -1)) &&
        (!transactionTypeFilter ||
          transactionTypes.has(transactionSubtypesMap.get(data.transactionTypeId ?? -1)?.catergory ?? ''))
      );
    },
    [fundFilter, funds, transactionSubtypesMap, transactionTypeFilter, transactionTypes]
  );

  const isExternalFilterPresent = useCallback(
    () => Boolean(fundFilter?.length) || Boolean(transactionTypeFilter?.length),
    [fundFilter, transactionTypeFilter]
  );

  useEffect(() => {
    gridApi?.onFilterChanged();
  }, [fundFilter, transactionTypeFilter, gridApi]);

  return { doesExternalFilterPass, isExternalFilterPresent };
}
