import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';

export type MappedTransaction = {
  mostRecent: Date;
  name: string;
  logoURL: string;
  round: string;
};

export const useMostRecentRounds = (data: IMetricsDataModel[]): MappedTransaction[] => {
  const uniqueData = new Map<string, MappedTransaction>();
  const dataCopy = [...(data ?? [])];
  const getCompany = useGetCompanyIfSet();

  const sortedData = dataCopy
    .reduce((res, { companyId }) => {
      const company = getCompany(companyId);
      if (company && company.mostRecetRoundDate && company.mostRcentRound) {
        res.push({
          name: company.name,
          mostRecent: new Date(company.mostRecetRoundDate),
          round: company.mostRcentRound,
          logoURL: company.logoUrl ?? '',
        });
      }
      return res;
    }, [] as MappedTransaction[])
    .sort(({ mostRecent: a }, { mostRecent: b }) => {
      return b.getTime() - a.getTime();
    });

  for (let i = 0; i < sortedData.length && uniqueData.size < 5; i++) {
    uniqueData.set(sortedData[i].name, sortedData[i]);
  }

  return Array.from(uniqueData.values());
};
