import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TransactionAudit } from '../../types';
import { fetchTransactionHistory } from '../queries/MaggieTransactionQueries';
import { ITransactionDataModel } from '../../data-models/transaction.data-model';
import { useFetchTransactionsAndSaveCompanies } from '../hooks/useFetchTransactionsAndSaveCompanies';

export const useCompanyTransactions = (companyId: number): UseQueryResult<ITransactionDataModel[]> => {
  const fetchTransactionsAndSaveCompanies = useFetchTransactionsAndSaveCompanies(companyId);
  return useQuery(['transactions', companyId], () => fetchTransactionsAndSaveCompanies(), {
    enabled: Boolean(companyId),
    staleTime: Infinity,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export const useTransactionHistory = (companyId?: number): UseQueryResult<TransactionAudit[]> => {
  return useQuery(['transactionHistory', companyId], () => fetchTransactionHistory(companyId), {
    enabled: Boolean(companyId),
    staleTime: Infinity,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
