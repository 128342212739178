import { IBaseLocationDataModel } from '../data-models/location.data-model';

export function formatLocation(data: IBaseLocationDataModel) {
  const { city = '', state = '', country = '' } = data;

  if (country === 'United States' || country === 'USA') {
    return [city, state, country].filter((str) => str !== '' && str).join(', ');
  }

  return [city, country].filter((str) => str !== '' && str).join(', ');
}
