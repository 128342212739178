import { ReactElement, useEffect } from 'react';
import './custom-table-styles/ag-theme-custom.scss';
import { useRecoilValue } from 'recoil';
import { Outlet, useLocation } from 'react-router-dom';
import { Loader } from './components/Loader/Loader';
import { useInitApp } from './hooks/useInitApp';
import { LoadingStatus } from './types';
import { authenticatedUserState } from './services/state/AuthState';
import { NavWrapper } from './NavWrapper';
import { useTitle } from './hooks/useTitle';
import { PermissionService } from './services/PermissionService';
import { RoleKey } from './services/PermissionAndRolesKeys';
import { ROUTES } from './constants/RouteNameMapping';

export function AppLoader(): ReactElement {
  const user = useRecoilValue(authenticatedUserState);
  const location = useLocation();
  const loadStatus = useInitApp();
  const title = useTitle();

  const isExternalUser = PermissionService.get().hasRole(RoleKey.externalUser);

  useEffect(() => {
    document.title = `Foresight | ${title}`;
  }, [title]);

  const isMatchingKPIRequestFormPage = location.pathname.match(/^\/kpis\/response\/\d+$/);
  const isLoginSuccessPage = location.pathname.match(ROUTES.EXCEL_LOGIN);

  if (loadStatus.overallStatus === LoadingStatus.error) {
    return <div>Application Error occurred</div>;
  }

  if (user === undefined || loadStatus.overallStatus !== LoadingStatus.success) {
    return <Loader height='90vh' />;
  }

  return (
    <>{isMatchingKPIRequestFormPage || isExternalUser || isLoginSuccessPage ? <Outlet /> : <NavWrapper />}</>
  );
}
