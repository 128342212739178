import SettingsIcon from '@mui/icons-material/Settings';
import {
  Button,
  ButtonProps,
  Checkbox,
  Divider,
  FormControlLabel,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import React, {
  CSSProperties,
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SelectItem } from '../../types';
import { SearchInput } from '../../CoreComponents/base/SearchInput';

export interface IPopupMenuProps {
  icon?: React.JSX.Element;
  iconOpened?: React.JSX.Element;
  items: SelectItem[];
  onChange: (selectedElems: SelectItem[]) => void;
  selectedItems: SelectItem[];
  label?: string | JSX.Element;
  style?: CSSProperties;
  groupable?: boolean;
  searchable?: boolean;
  searchPlaceholder?: string;
  ActionComponent?: (props: Pick<ButtonProps, 'onClick' | 'children'>) => JSX.Element;
}

export function PopupMenu(props: IPopupMenuProps) {
  const {
    icon = <SettingsIcon />,
    items,
    label,
    onChange,
    selectedItems,
    style,
    searchPlaceholder,
    searchable,
    groupable,
    ActionComponent,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const selectedItemsSet = useMemo(() => new Set(selectedItems.map((item) => item.id)), [selectedItems]);
  const [filteredItems, setFilteredItems] = useState<SelectItem[]>(items);

  const { colors } = useTheme();

  const groupedItems = useMemo(() => {
    if (!groupable) return null;
    const grouped = new Map<string, SelectItem[]>();
    filteredItems.forEach((item) => {
      const group = item.group ?? 'Other';
      const groupItems = grouped.get(group) ?? [];
      groupItems.push(item);
      grouped.set(group, groupItems);
    });
    return grouped;
  }, [filteredItems, groupable]);

  const handleSelectionChange = (event: MouseEvent<HTMLLIElement>) => {
    const itemId = event.currentTarget.getAttribute('value')!;
    const selectedItem = items.find((item) => String(item.id) === itemId)!;
    const indexInSelection = selectedItems.findIndex((item) => item.id === selectedItem.id);
    const newSelection = [...selectedItems];

    if (indexInSelection >= 0) {
      newSelection.splice(indexInSelection, 1);
    } else {
      newSelection.push(selectedItem);
    }

    onChange(newSelection);
  };

  const handleGroupSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSelection = new Map(selectedItems.map((item) => [item.id, item]));

    if (event.target.checked) {
      groupedItems!.get(event.target.name)!.forEach((item) => {
        newSelection.set(item.id, item);
      });
    } else {
      groupedItems!.get(event.target.name)!.forEach((item) => {
        newSelection.delete(item.id);
      });
    }
    onChange(Array.from(newSelection.values()));
  };

  const handleAllSelectionChange = useCallback(() => {
    if (selectedItems.length === items.length) {
      onChange([]);
    } else {
      onChange([...items]);
    }
  }, [items, onChange, selectedItems.length]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuList = (
    <MenuList
      aria-labelledby={`${label}-menu`}
      dense
      style={{
        maxHeight: '40vh',
        maxWidth: '30ch',
        overflowY: 'auto',
        overflowX: 'hidden',
        minWidth: searchable ? '30ch' : '10ch',
      }}
    >
      {groupable ? (
        [...groupedItems!.entries()].map(([groupName, groupItems], i) => {
          const checked = groupItems.every((item) => selectedItemsSet.has(item.id));
          return (
            <section key={groupName} style={{ background: checked ? `${colors.secondary[5]}` : undefined }}>
              <FormControlLabel
                label={<Typography variant='overline'>{groupName}</Typography>}
                sx={{
                  ml: '0.2rem',
                  width: '100%',
                  background: `${colors.neutral[2]}`,
                }}
                control={
                  <Checkbox
                    size={'small'}
                    name={groupName}
                    checked={checked}
                    indeterminate={groupItems.some((item) => selectedItemsSet.has(item.id)) && !checked}
                    onChange={handleGroupSelectionChange}
                  />
                }
              />

              <MenuItems
                items={groupItems}
                selectedItemsSet={selectedItemsSet}
                onChange={handleSelectionChange}
                groupable={groupable}
              />
              {i < groupedItems!.size - 1 && <Divider />}
            </section>
          );
        })
      ) : (
        <MenuItems
          items={filteredItems}
          selectedItemsSet={selectedItemsSet}
          onChange={handleSelectionChange}
          groupable={groupable}
        />
      )}
    </MenuList>
  );

  const button = useMemo(
    () =>
      ActionComponent ? (
        <ActionComponent onClick={handleClick}>{props.label ?? ''}</ActionComponent>
      ) : (
        <Button color={'secondary'} endIcon={icon} onClick={handleClick} variant={'outlined'}>
          {props.label ?? ''}
        </Button>
      ),
    [ActionComponent, icon, props.label]
  );

  useEffect(() => {
    setFilteredItems(items);
  }, [items, open]);

  return (
    <div style={style}>
      {button}
      <Popover
        disableRestoreFocus
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id={`${label}-menu`}
        onClose={handleClose}
        open={open}
      >
        <div>
          {searchable && (
            <SearchInput
              fullWidth
              placeholder={searchPlaceholder}
              onChange={(newValue) =>
                setFilteredItems(
                  items.filter((opt) => opt.value.toLowerCase().includes(newValue.toLowerCase()))
                )
              }
              autoFocus
              sx={{ px: '0.5rem', position: 'sticky' }}
              showSearchIcon={false}
            />
          )}
          <Button
            onClick={handleAllSelectionChange}
            style={{ margin: groupable ? '0.25rem 0 -0.25rem 0.5rem' : '0.5rem 0 -0.5rem 0.5rem' }}
          >
            {selectedItems.length === items.length ? 'Deselect All' : 'Select All'}
          </Button>
          {menuList}
        </div>
      </Popover>
    </div>
  );
}

interface IOptionsListProps {
  items: SelectItem[];
  selectedItemsSet: Set<string | number>;
  onChange: (event: MouseEvent<HTMLLIElement>) => void;
  groupable?: boolean;
}

export const MenuItems: FC<IOptionsListProps> = ({ items, selectedItemsSet, onChange, groupable }) => {
  const { colors } = useTheme();
  return (
    <>
      {items.map((item) => {
        const { id, value } = item;
        const checked = selectedItemsSet.has(id);
        return (
          <MenuItem
            key={item.id}
            aria-label={item.value}
            value={item.id}
            onClick={onChange}
            sx={{ background: checked ? colors.primary[5] : undefined, pl: groupable ? '2rem' : '0.25rem' }}
          >
            <Checkbox size={'small'} checked={checked} />
            <ListItemText primary={<Typography variant='body2'>{value}</Typography>} />
          </MenuItem>
        );
      })}
    </>
  );
};
