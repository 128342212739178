import { NavLink, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Tabs, Tab } from '@mui/material';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../constants/RouteNameMapping';

const tabs = [
  {
    path: ROUTES.FP_SUMMARY,
    title: ROUTE_TITLE_MAPPING[ROUTES.FP_SUMMARY],
  },
  {
    path: ROUTES.FP_WATERFALL,
    title: ROUTE_TITLE_MAPPING[ROUTES.FP_WATERFALL],
  },
];

export function FundProfileTabs() {
  const { pathname } = useLocation();
  const selected = useMemo(() => {
    if (pathname.includes(ROUTES.FP_SUMMARY)) {
      return ROUTES.FP_SUMMARY;
    } else if (pathname.includes(ROUTES.FP_WATERFALL)) {
      return ROUTES.FP_WATERFALL;
    }
    return ROUTES.FP_SUMMARY;
  }, [pathname]);

  return (
    <Tabs variant='scrollable' scrollButtons='auto' value={selected} style={{ marginBottom: '1rem' }}>
      {tabs.map((t) => (
        <Tab
          component={NavLink}
          key={t.path}
          value={t.path}
          label={t.title}
          style={{ paddingLeft: 0, marginRight: '1rem' }}
          to={{ pathname: `./${t.path}` }}
        />
      ))}
    </Tabs>
  );
}
