import { useRecoilValue } from 'recoil';
import { merge } from 'lodash-es';
import { styled, Typography, TypographyProps } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { CSSProperties, memo } from 'react';
import { IMUIAvatarProps, MUIAvatar } from '../Avatar/MUIAvatar';
import { companyState } from '../../services/state/CompanyState';
import { groupingByField } from '../AgTable/cell-renderers/GenericIdRenderer';
import { formatExternalLink, toURLFriendlyStringWithDashes } from '../../util/url-utils';
import { ICompanyDataModel } from '../../data-models/company.data-model';

export interface ICompanyIdCellRendererParams<T> extends ICellRendererParams<T, number> {
  link?: 'profile' | 'website';
}

export function CompanyIdCellRenderer<T extends { companyId: number }>(
  params: ICompanyIdCellRendererParams<T>
) {
  const { node, data, value } = params;
  const { companyId } = data ?? {};
  const company = useRecoilValue(companyState(companyId ?? value ?? -1));

  let linkToProfile = '';
  let typographyProps;
  if (company && Boolean(params.link)) {
    linkToProfile =
      params.link === 'profile'
        ? `/company-profiles/${toURLFriendlyStringWithDashes(company.name)}-${company.id}/summary`
        : formatExternalLink(company.website ?? '');
    typographyProps = {
      component: 'a',
      color: 'secondary',
      href: linkToProfile,
      target: '_blank',
      rel: 'noreferrer',
    } as TypographyProps;
  }

  if (groupingByField(params)) {
    return <>{node.key}</>;
  }
  if (!company) return <></>;
  return (
    <CompanyLogoAndName
      name={company.name}
      logoUrl={company.logoUrl ?? ''}
      typographyProps={typographyProps}
    />
  );
}

const CellContainer = styled('div')`
  align-items: center;
  column-gap: 0.75rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  height: 100%;
`;

export interface ICompanyCellValue {
  name: string;
  logoUrl: string;
  role?: string;
  avatarProps?: Partial<IMUIAvatarProps>;
  typographyProps?: TypographyProps;
  style?: CSSProperties;
  url?: string;
}

export const CompanyCellRenderer = memo(function CompanyCellRenderer(
  props: ICellRendererParams<unknown, ICompanyDataModel>
) {
  if (!props.value) return null;

  if (groupingByField(props)) {
    return <>{props.value.name}</>;
  }

  const { name = '', logoUrl = '' } = props.value;

  return <CompanyLogoAndName name={name} logoUrl={logoUrl ?? ''} />;
});

export function CompanyLogoAndName({
  name,
  logoUrl,
  role,
  avatarProps,
  typographyProps = {},
  style,
  url,
}: ICompanyCellValue) {
  let mergedProps = typographyProps;
  if (url) {
    mergedProps = merge(
      {
        component: 'a',
        color: 'secondary',
        href: url,
        target: '_blank',
        rel: 'noreferrer',
      },
      { ...typographyProps }
    ) as TypographyProps;
  }
  return (
    <CellContainer role={role} style={style}>
      <MUIAvatar nameParts={name?.split(' ')} src={logoUrl} size={'medium'} {...avatarProps} />
      <Typography
        variant={'body2'}
        color={'text.primary'}
        style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        {...mergedProps}
      >
        {name}
      </Typography>
    </CellContainer>
  );
}
