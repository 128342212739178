import { AgGridReact } from 'ag-grid-react';
import { Stack, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { GetRowIdParams, RowClassParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { SecurityTableStyleWrapper } from '../../../Finance2/Security/SecurityTableStyleWrapper';
import { colors } from '../../../../theme/colors';
import { IKpiCompanyData, useAllCompaniesRowData } from './AllCompaniesData';
import { defaultColDef, useAllCompaniesColDefs } from './AllCompaniesColDefs';
import { useAllCompaniesRowSelection } from './hooks/useAllCompaniesRowSelection';
import { allCompaniesActionState } from './AllCompaniesState';
import { AssignModal } from './AllCompaniesForms/AssignModal';
import { ManageModal } from './AllCompaniesForms/ManageModal';
import { BulkAssignButtons } from './AllCompaniesForms/BulkAssignButtons';
import { useAllKpiCompaniesActions } from './AllCompaniesForms/useAllKpiCompaniesActions';
import { StopReportingModal } from './AllCompaniesForms/StopReportingModal';
import { TemplateAssignmentErrorsModal } from './AllCompaniesForms/TemplateAssignmentErrorsModal';

const StyledGridContainer = styled(SecurityTableStyleWrapper)`
  & .unassigned:not(.ag-pinned-right-cols-container .unassigned, .ag-pinned-left-cols-container .unassigned) {
    box-shadow: inset 2px 0 ${colors.warning[40]};
  }
  height: 100%;
`;

export function AllCompanies() {
  const actionState = useRecoilValue(allCompaniesActionState);
  const { resetUIState } = useAllKpiCompaniesActions();

  useEffect(() => {
    return resetUIState;
  }, [resetUIState]);

  return (
    <>
      <TemplateAssignmentErrorsModal />
      {actionState === 'assign' && <AssignModal />}
      {actionState === 'manage' && <ManageModal />}
      {actionState === 'confirmStopReporting' && <StopReportingModal />}
      <AllCompaniesGrid />
    </>
  );
}

export function AllCompaniesGrid() {
  const getColumnDefs = useAllCompaniesColDefs();
  const gridRef = useRef<AgGridReact>(null);
  const allCompaniesData = useAllCompaniesRowData();

  const { onSelectionChanged, selected, deselectAll } = useAllCompaniesRowSelection(gridRef);

  const columnDefs = useMemo(() => getColumnDefs(), [getColumnDefs]);

  const getRowId = useCallback(
    (data: GetRowIdParams<IKpiCompanyData>) => data.data?.id?.toString() ?? '',
    []
  );

  return (
    <Stack height={'100%'}>
      {selected.length > 0 && <BulkAssignButtons selected={selected} deselectAll={deselectAll} />}
      <StyledGridContainer>
        <FadeInGridWrapper>
          <AgTable
            ref={gridRef}
            rowData={allCompaniesData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            groupIncludeTotalFooter={false}
            rowGroupPanelShow='always'
            groupDefaultExpanded={-1}
            suppressMovableColumns={true}
            suppressRowDrag={true}
            getRowClass={getRowClass}
            rowSelection='multiple'
            rowMultiSelectWithClick
            groupSelectsChildren
            groupSelectsFiltered
            suppressRowClickSelection
            onSelectionChanged={onSelectionChanged}
            getRowId={getRowId}
          />
        </FadeInGridWrapper>
      </StyledGridContainer>
    </Stack>
  );
}

function getRowClass(params: RowClassParams<IKpiCompanyData>) {
  if (params.data?.assigned === false) {
    return ['unassigned'];
  }
}
