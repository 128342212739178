import { FC, useCallback, MouseEvent } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { add, endOfMonth, sub } from 'date-fns';
import { Stack } from '@mui/material';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import {
  selectedEndDateFinancialsState,
  selectedFrequencyFinancialsState,
} from '../../../state/CompanyFinancialsDateState';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { companyState } from '../../../../../services/state/CompanyState';
import { endOfFiscalPeriod } from '../../../utils/financialUtils';
import { ReportingFrequencyToggler } from '../../../Performance/components/DateSettings/ReportingFrequencyToggler';
import { FinancialDateSelector } from '../../../Performance/components/DateSettings/FinancialDateSelector';
import { monthsInPeriod } from '../../../Performance/components/DateSettings/DateSettings';
import { FyeChip } from './FyeChip';

export const FinancialsDateSettings: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const [selectedEndDate, setSelectedEndDate] = useRecoilState(selectedEndDateFinancialsState(companyId));

  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;
  const [reportingFrequency, setReportingFrequency] = useRecoilState(
    selectedFrequencyFinancialsState(companyId)
  );

  const onFrequencyChange = useCallback(
    (event: MouseEvent, value: KpiPeriod) => {
      if (value === null) return;
      setReportingFrequency(value);
      if (value === KpiPeriod.quarter || value === KpiPeriod.year) {
        setSelectedEndDate((curr) => endOfFiscalPeriod(curr, value, fye - 1));
      }
    },
    [fye, setSelectedEndDate, setReportingFrequency]
  );

  const onNextPeriodClick = useCallback(() => {
    setSelectedEndDate((current) => {
      return endOfMonth(add(current, { months: monthsInPeriod[reportingFrequency] }));
    });
  }, [reportingFrequency, setSelectedEndDate]);

  const onPreviousPeriodClick = useCallback(() => {
    setSelectedEndDate((current) => {
      return endOfMonth(sub(current, { months: monthsInPeriod[reportingFrequency] }));
    });
  }, [reportingFrequency, setSelectedEndDate]);

  return (
    <>
      <FinancialDateSelector
        label={'End Date'}
        onPreviousPeriodClick={onPreviousPeriodClick}
        onNextPeriodClick={onNextPeriodClick}
        fye={fye}
        selectedDate={selectedEndDate}
        selectedPeriod={reportingFrequency}
      />
      <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} gap={'0.5rem'}>
        <FyeChip fye={fye} />
        <ReportingFrequencyToggler value={reportingFrequency} onChange={onFrequencyChange} />
      </Stack>
    </>
  );
};
