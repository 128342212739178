import { useCallback, useMemo, useState, memo } from 'react';
import { useRecoilValue } from 'recoil';
import { ControllerRenderProps } from 'react-hook-form';
import { Stack } from '@mui/material';
import { FadeInGridWrapper } from '../../../../../components/grid-renderers/FadeInGridWrapper';
import { SubSection } from '../../../Summary/components/SubSection';
import { CapTableInvestmentType } from '../../../../../schemas/CaptableInvestment.schema';
import { InvestmentTypeToName } from '../../../../../view-models/captable.view-model';
import { RendererType } from '../../../../../data-models/field.data-model';
import { ISelectMeta } from '../../../../../data-models/field3.data-model';
import { createFormField } from '../../../../../view-models/form.view-model';
import { FieldSelect } from '../../../../../components/Form/Display/FieldSelect';
import {
  getFundsForCaptable,
  useInvestmentRowData,
} from '../../../CapTable/InvestmentDetails/CTInvestmentRowData';
import { fundsByIdMapState } from '../../../../../services/state/AppConfigState';
import { ChipSelector } from '../../../../../components/ChipSelector';
import {
  DefaultColDefs,
  useInvestmentColDefs,
} from '../../../CapTable/InvestmentDetails/CTInvestmentDetailsColDefs';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { ICaptableDataModel } from '../../../../../data-models/captable2.data-model';
import { StyledGridWrapper } from '../../../CapTable/SummaryGrid/CaptableGridContainer';
import { useStakeholderColumns } from './InvestmentDetailsStakeholderData';

const typesWithFunds = new Set<CapTableInvestmentType>(['share-class', 'warrant-block']);
interface IInvestmentDetailsProps {
  captable: ICaptableDataModel;
}
export const InvestmentDetails = memo(function InvestmentDetails({ captable }: IInvestmentDetailsProps) {
  const [selectedInvestmentType, setSelectedInvestmentType] = useState<CapTableInvestmentType>('share-class');
  const [selectedFund, setSelectedFund] = useState<number | null>(null);

  const showFundFilter = typesWithFunds.has(selectedInvestmentType);

  const fundOptions = useMemo(() => {
    return getFundsForCaptable(captable);
  }, [captable]);

  const rowData = useInvestmentRowData(captable, {
    investmentTypeFilter: selectedInvestmentType,
    fundFilter: selectedFund ? [selectedFund] : undefined,
  });

  const generalColDefs = useInvestmentColDefs(selectedInvestmentType);
  const getClientInvestmentColDefs = useStakeholderColumns(captable);

  const colDefs = useMemo(() => {
    return [
      ...getClientInvestmentColDefs(selectedInvestmentType, selectedFund ?? undefined),
      ...generalColDefs,
    ];
  }, [getClientInvestmentColDefs, selectedInvestmentType, selectedFund, generalColDefs]);

  return (
    <SubSection
      title={'Historical Investment Details'}
      noCard={true}
      collapseProps={{ collapsible: true, defaultExpanded: false }}
      childrenContainerStyle={{ padding: 0 }}
    >
      <Stack maxWidth={'10rem'} mb='0.25rem'>
        <InvestmentTypeSelector onChange={setSelectedInvestmentType} value={selectedInvestmentType} />
      </Stack>
      {showFundFilter && (
        <FundSelector value={selectedFund} onChange={setSelectedFund} fundOptions={new Set(fundOptions)} />
      )}
      <FadeInGridWrapper key={`${selectedInvestmentType}-${selectedFund}`}>
        <StyledGridWrapper style={{ height: '20rem' }}>
          <AgTable
            rowData={rowData}
            columnDefs={colDefs}
            rowGroupPanelShow='never'
            groupIncludeTotalFooter={selectedInvestmentType === 'share-class'}
            defaultColDef={DefaultColDefs}
          />
        </StyledGridWrapper>
      </FadeInGridWrapper>
    </SubSection>
  );
});

interface IInvestmentTypeSelectorProps {
  onChange: (val: CapTableInvestmentType) => void;
  value: CapTableInvestmentType;
}

export function InvestmentTypeSelector({ value, onChange }: IInvestmentTypeSelectorProps) {
  const investmentTypeField = useMemo(
    () =>
      createFormField<ISelectMeta<string>>({
        key: 'selected-investment-type',
        label: '',
        dataType: 'string',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.entries(InvestmentTypeToName).map(([key, value]) => ({
            displayName: value,
            value: key,
          })),
          multi: false,
        },
        disableClearable: true,
      }),
    []
  );

  return (
    <FieldSelect
      formField={investmentTypeField}
      formProps={
        {
          onChange,
          value,
        } as ControllerRenderProps
      }
    />
  );
}

const AllFundsOption = {
  name: 'All Funds',
  id: -1,
};

interface IFundSelectorProps {
  value: number | null;
  onChange: (val: number | null) => void;
  fundOptions: Set<number>;
}
export function FundSelector({ value, onChange, fundOptions }: IFundSelectorProps) {
  const fundsById = useRecoilValue(fundsByIdMapState);
  const funds = useMemo(
    () => Array.from(fundsById.values()).filter((f) => fundOptions.has(f.id)),
    [fundOptions, fundsById]
  );

  const options = useMemo(() => {
    return [AllFundsOption, ...funds].map((f) => ({
      displayName: f.name,
      value: f.id,
    }));
  }, [funds]);

  const _onChange = useCallback(
    (val: number) => {
      if (val === AllFundsOption.id) {
        onChange(null);
      } else {
        onChange(val);
      }
    },
    [onChange]
  );

  return <ChipSelector options={options} value={value ? value : AllFundsOption.id} onChange={_onChange} />;
}
