import { FC } from 'react';
import { MostRecentRoundsChart } from '../../../../components/Charts/Leaderboard/MostRecentRounds/MostRecentRoundsChart';
import { MappedTransaction } from '../../hooks/useMostRecentRounds';
import { CHART_COMPONENTS } from '../../../../types';
import { IInvestmentDataModel } from '../../../../data-models/investment.data-model';
import { IPortfolioChartProps } from './ChartComponent';

export const MostRecentRoundsRoundTracker: FC<IPortfolioChartProps<IInvestmentDataModel>> = (props) => {
  const data = calculateChartData(props.filteredMetrics);

  return <MostRecentRoundsChart id={CHART_COMPONENTS.MOST_RECENT_ROUNDS_RT} width={640} data={data} />;
};

export function calculateChartData(data: IInvestmentDataModel[]): MappedTransaction[] {
  const uniqueData = new Map<string, MappedTransaction>();
  const dataCopy = [...(data ?? [])];

  const sortedData = dataCopy
    .reduce((res, metric) => {
      if (metric.round) {
        res.push({
          name: metric.company,
          mostRecent: new Date(metric.investmentDate),
          round: metric.round,
          logoURL: metric.logoUrl ?? '',
        });
      }
      return res;
    }, [] as MappedTransaction[])
    .sort(({ mostRecent: a }, { mostRecent: b }) => {
      return b.getTime() - a.getTime();
    });

  for (let i = 0; i < sortedData.length && uniqueData.size < 5; i++) {
    uniqueData.set(sortedData[i].name, sortedData[i]);
  }

  return Array.from(uniqueData.values());
}
