import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { useFormContext } from 'react-hook-form';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { useUpdateCompanyAndState } from '../../../services/hooks/useUpdateCompanyAndState';
import { useCreateCompanyAndUpdateState } from '../../../services/hooks/useCreateCompanyAndUpdateState';
import { CompanySchema } from '../../Finance/components/TransactionModal/Forms/schemas/Company/CompanySchema';
import {
  companyWebsiteField,
  simpleMultilineTextField,
  simpleTextField,
  useSectorIdField,
  useUserIdField,
} from '../../../data-fields/CommonFields';
import { customFieldsByEntity } from '../../../services/state/AdminPanel/CustomFieldsState';
import { field2ToFormField, FieldEntity } from '../../../data-models/field2.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { VALID_URL_REGEX } from '../../../util/regex';
import { createFormField } from '../../../view-models/form.view-model';
import { fetchCompaniesByWebsite } from '../../../services/queries/MaggieCompanyQueries';

export function useCreateOrUpdateCompanyHandler() {
  const createCompany = useCreateCompanyAndUpdateState();
  const updateCompany = useUpdateCompanyAndState();

  return useCallback(
    async (data: Partial<ICompanyDataModel>) => {
      const customData = removeEmptyCustomData(data?.customData ?? {});
      const payload = { ...data, customData };
      let company;
      if (!payload.id) {
        company = await createCompany(payload);
      } else {
        company = await updateCompany(payload.id!, payload);
      }
      return company;
    },
    [createCompany, updateCompany]
  );
}

export const companyWebsiteSchema = yup
  .string()
  .nullable()
  .test('is-url', 'Invalid URL', (value) => {
    if (!value) return true;
    return value.match(VALID_URL_REGEX) !== null;
  })
  .test(checkDuplicateWebsite);

export const companySchema: ObjectSchema<Partial<ICompanyDataModel>> = CompanySchema.shape({
  country: yup.string().nullable(),
  city: yup.string().nullable(),
  location: yup
    .object()
    .nullable()
    .shape({
      city: yup.string().nullable().required('Location is a required field'),
      country: yup.string().nullable(),
      state: yup.string().nullable(),
    })
    .required(),
  website: companyWebsiteSchema,
  dealLeadId: yup
    .number()
    .nullable()
    .transform((_, val) => (val ? val : null)),
  dealTeamIds: yup.array().transform((_, val) => (val ? val : [])),
});

export function useCompanyFields() {
  const customFields = useRecoilValue(customFieldsByEntity);
  const companyCustomFields = (customFields.get(FieldEntity.company) ?? []).map((field) =>
    field2ToFormField(field)
  );
  const { formState } = useFormContext<Partial<ICompanyDataModel>>() ?? {};
  const isCrunchbase =
    formState?.defaultValues?.id != null && Boolean(formState?.defaultValues?.crunchbaseId);

  const sectorField = useSectorIdField({ required: true });
  const dealLeadField = useUserIdField({ key: 'dealLeadId', label: 'Deal Lead', required: false });
  const dealTeamField = useUserIdField({
    key: 'dealTeamIds',
    label: 'Deal Team',
    required: false,
    multi: true,
  });

  return useMemo(
    () => [
      simpleTextField({ key: 'name', label: 'Name', required: true, disabled: isCrunchbase }),
      companyWebsiteField({
        key: 'website',
        label: 'Website',
        disabled: isCrunchbase,
      }),
      createFormField<unknown>({
        key: 'location',
        label: 'Location',
        required: true,
        renderer: RendererType.location,
      }),
      simpleTextField({ key: 'ceoName', label: 'CEO Name', required: true }),
      sectorField,
      simpleMultilineTextField({ key: 'shortDescription', label: 'Internal Description' }),
      dealLeadField,
      dealTeamField,
      ...companyCustomFields,
    ],
    [companyCustomFields, dealLeadField, dealTeamField, isCrunchbase, sectorField]
  );
}

// react hook form creates undefined values for empty custom data fields - to avoid api calls when no data changed, remove them from payload
// (update handler will check if data changed before sending request)
export function removeEmptyCustomData(formData: ICompanyDataModel['customData']) {
  if (!formData) return formData;
  return Object.entries(formData).reduce((acc, [key, value]) => {
    if (value !== undefined) acc![key] = value;
    return acc;
  }, {} as ICompanyDataModel['customData']);
}

export const DuplicateWebsiteErrorType = 'duplicate';

export async function checkDuplicateWebsite(
  value: string | null | undefined,
  context: yup.TestContext<yup.AnyObject>
) {
  if (!value || !VALID_URL_REGEX.test(value)) return true;
  const companies = await fetchCompaniesByWebsite(value);

  const company = companies?.at(0);

  if (!company || company.id === context.parent?.id) {
    return true;
  } else {
    return context.createError({
      path: `website`,
      message: 'Duplicate website',
      type: DuplicateWebsiteErrorType,
      params: {
        company: { ...company },
      },
    });
  }
}
