import { useQueries } from '@tanstack/react-query';
import { fetchCompanyRaw } from '../queries/MaggieCompanyQueries';

export const COMPANY_QUERY_KEY = ['company'];
export const useCompaniesQuery = (ids: number[]) => {
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: [COMPANY_QUERY_KEY, id],
      queryFn: () => fetchCompanyRaw(id),
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
      staleTime: 60000,
    })),
  });
};
