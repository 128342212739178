import { FieldEntity } from '../../../data-models/field2.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { createField3DataModel, ITextMeta } from '../../../data-models/field3.data-model';

export function getFundFields() {
  return [
    createField3DataModel<ITextMeta>({
      id: 0,
      entity: FieldEntity.fund,
      entityKey: 'name',
      displayName: 'Name',
      format: RendererType.text,
      meta: {
        maxLength: -1,
      },
    }),
    createField3DataModel<ITextMeta>({
      id: 0,
      entity: FieldEntity.fund,
      entityKey: 'legalName',
      displayName: 'Legal Name',
      format: RendererType.text,
      meta: {
        maxLength: -1,
      },
    }),
    createField3DataModel({
      id: 0,
      entity: FieldEntity.fund,
      entityKey: 'fundType',
      displayName: 'Type',
      format: RendererType.fundType,
    }),
    createField3DataModel({
      id: 0,
      entity: FieldEntity.fund,
      entityKey: 'vintage',
      displayName: 'Vintage Year',
      format: RendererType.number,
    }),
  ];
}
