import { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { Button, Popover, PopoverProps, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IRangeShortcut, RangeShortcuts } from './RangeShortcuts';

export interface IRangeDatePickerProps {
  startingDate: Date | null;
  endingDate: Date | null;
  setStartingDate: Dispatch<SetStateAction<Date | null>>;
  setEndingDate: Dispatch<SetStateAction<Date | null>>;
  handleApply: () => void;
  minDate?: Date;
  maxDate?: Date;
  popoverProps: PopoverProps;
  shortcuts?: IRangeShortcut[];
}

export const RangeDatePicker = ({
  startingDate,
  endingDate,
  setEndingDate,
  setStartingDate,
  handleApply,
  minDate,
  shortcuts,
  maxDate = new Date(),
  popoverProps,
}: IRangeDatePickerProps): ReactElement => {
  const minDateMapped = useMemo(() => {
    if (minDate) return minDate;
    const today = new Date();
    today.setFullYear(today.getFullYear() - 19);
    return today;
  }, [minDate]);

  const resetDates = useCallback(() => {
    setStartingDate(null);
    setEndingDate(null);
  }, [setEndingDate, setStartingDate]);

  useEffect(() => {
    resetDates();
  }, [resetDates]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Popover {...popoverProps}>
        <Stack direction={'row'}>
          {shortcuts && (
            <RangeShortcuts
              setStartingDate={setStartingDate}
              setEndingDate={setEndingDate}
              handleApply={handleApply}
              shortcuts={shortcuts}
            />
          )}
          <Stack display='grid' gridTemplateColumns={'1fr 1fr'}>
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              value={startingDate}
              onChange={(val: Date | null) => setStartingDate(val)}
              minDate={minDateMapped}
              maxDate={endingDate ?? maxDate}
              shouldDisableYear={(year: Date) => {
                const currentYear = new Date().getFullYear();
                const yearNum = year.getFullYear();
                return yearNum > currentYear || yearNum <= currentYear - 20;
              }}
              autoFocus
            />
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              value={endingDate}
              onChange={(val: Date | null) => setEndingDate(val)}
              minDate={startingDate ?? minDateMapped}
              maxDate={maxDate}
              shouldDisableYear={(year: Date) => {
                const currentYear = new Date().getFullYear();
                const yearNum = year.getFullYear();
                return yearNum > currentYear || yearNum <= currentYear - 20;
              }}
            />

            <Stack direction={'row'} marginInline={'1rem'} mb={'1rem'} gap={'1rem'}>
              <Button variant={'outlined'} color={'secondary'} onClick={resetDates}>
                Clear
              </Button>
              <Button startIcon={<CheckIcon />} onClick={handleApply} variant='contained' color='secondary'>
                Apply
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Popover>
    </LocalizationProvider>
  );
};
