import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedInvestmentStateFinance, showModalStateFinance } from '../../state/FinanceUIState';
import { useInvestmentModalActions } from '../../hooks/useFinanceModalActions';
import { DeleteInvestmentModal } from './DeleteInvestmentModal';
import { EditInvestmentModal } from './EditInvestmentModal';

export const RoundModals: FC = () => {
  const showModalState = useRecoilValue(showModalStateFinance);
  const selectedInvestment = useRecoilValue(selectedInvestmentStateFinance);
  const { handleCloseModal } = useInvestmentModalActions();

  if (!selectedInvestment) return null;
  return (
    <>
      <EditInvestmentModal
        open={showModalState === 'editInvestment'}
        onClose={handleCloseModal}
        investment={selectedInvestment}
      />

      <DeleteInvestmentModal
        onClose={handleCloseModal}
        open={showModalState === 'deleteInvestment'}
        investment={selectedInvestment}
      />
    </>
  );
};
