import { FC, useCallback, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { useInvestmentActions } from '../../hooks/useInvestmentActions';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { IInvestmentFormModalProps } from './EditInvestmentModal';

export const DeleteInvestmentModal: FC<IInvestmentFormModalProps> = ({ open, onClose, investment }) => {
  const { handleDeleteInvestment } = useInvestmentActions();
  const [loading, setLoading] = useState(false);

  const { colors } = useTheme();

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await handleDeleteInvestment(investment);
    setLoading(false);
    onClose();
  }, [handleDeleteInvestment, onClose, investment]);

  return open ? (
    <ConfirmDeletionDialog
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      title='Are you sure you want to delete this investment?'
      loading={loading}
    >
      <Typography variant='body2' color={colors.neutral[60]}>
        {`The investment ${investment.name ?? ''} will be deleted permanently`}
      </Typography>
    </ConfirmDeletionDialog>
  ) : (
    <div style={{ height: '1rem' }} />
  );
};
