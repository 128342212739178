import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { GA, GARaw, MappedGA } from '../../types';
import { fetchRawGrowthAnalysis, getCompanyMetrics } from '../queries/MaggieMetricsQueries';
import { useCompanies } from '../../pages/GrowthAnalysis/providers/CompaniesProvider';
import { ICompanyMetricsDataModel } from '../../data-models/metrics.data-model';

const GROWTH_ANALYSIS_QUERY_KEY = 'growth-analysis';

export const gDataMapper = (rawData?: GARaw): GA => {
  return rawData?.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {} as GA) ?? ({} as GA);
};

export const useCompanyMetrics = (companyId?: number | null): UseQueryResult<ICompanyMetricsDataModel> => {
  return useQuery<ICompanyMetricsDataModel>(['metric', companyId], () => getCompanyMetrics(companyId), {
    enabled: Boolean(companyId),
    staleTime: Infinity,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

function useGrowthAnalysis(companies: number[]) {
  return useQueries({
    queries: companies.map((id) => ({
      queryKey: [GROWTH_ANALYSIS_QUERY_KEY, id],
      queryFn: () => fetchRawGrowthAnalysis(id),
      enabled: !!id,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    })),
  });
}

export function useCompanyGrowthAnalysis() {
  const { companies, activeCompanies } = useCompanies();

  const queries = useGrowthAnalysis(companies.map(({ id }) => id));

  return useMemo(() => {
    const isLoading = queries.some((q) => q.isLoading);
    const isEmpty = queries.some((q) => !q.data);

    const data = queries.map((q, i) => {
      const isActive = activeCompanies.includes(companies[i].id);

      return {
        companyId: companies[i].id,
        companyName: companies[i].name,
        isActive: isActive,
        logo: companies[i].logoUrl,
        data: gDataMapper(q.data),
      } as MappedGA;
    });

    return {
      isLoading,
      isEmpty,
      data,
    };
  }, [activeCompanies, companies, queries]);
}
