import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import {
  getCurrencyColDefs,
  getDateColDefs,
  getIntegerColDefs,
  genericKeyCreator,
} from '../common-grid-defs/commonColDefs';
import { ColoredNumericRenderer } from '../common-grid-defs/ColoredNumericRenderer';
import { fundsByIdMapState } from '../../../services/state/AppConfigState';
import { MetricsTransactionDataModel } from '../../../schemas/MetricsTransaction.schema';
import { eventFormatter } from './securityUtils';

export function useSecurityColDefs(): ColDef<MetricsTransactionDataModel>[] {
  const fundsById = useRecoilValue(fundsByIdMapState);

  const defs: ColDef<MetricsTransactionDataModel>[] = [
    {
      headerName: 'Position',
      field: 'position',
      rowGroup: true,
      enableRowGroup: true,
      allowedAggFuncs: [],
      minWidth: 200,
      keyCreator: genericKeyCreator,
    },
    {
      headerName: 'Event Date',
      field: 'transactionDate',
      valueGetter: (params: ValueGetterParams<MetricsTransactionDataModel>) => {
        return params.data?.transactionDate ? new Date(params.data.transactionDate) : null;
      },
      initialSort: 'desc',
      keyCreator: genericKeyCreator,
      ...getDateColDefs(),
    },

    {
      headerName: 'Event',
      field: 'transType',
      valueFormatter: (params) => {
        return eventFormatter(params.value ?? '');
      },
    },
    {
      headerName: 'Fund',
      valueGetter: (params: ValueGetterParams<MetricsTransactionDataModel>) => {
        return fundsById.get(params.data?.fundId ?? -1)?.name ?? '';
      },
      enableRowGroup: true,
      keyCreator: genericKeyCreator,
      minWidth: 200,
    },
    {
      headerName: 'Investment Amount',
      field: 'investmentAmount',
      aggFunc: 'sum',
      ...getCurrencyColDefs(),
      cellRenderer: ColoredNumericRenderer,
    },
    {
      headerName: 'FMV',
      field: 'currentInvestment',
      aggFunc: 'sum',
      ...getCurrencyColDefs(),
      cellRenderer: ColoredNumericRenderer,
    },
    {
      headerName: 'Realized Value',
      field: 'distributions',
      aggFunc: 'sum',
      cellRenderer: ColoredNumericRenderer,
      ...getCurrencyColDefs(),
    },
    {
      headerName: 'Contribution to Escrow',
      field: 'escrowAmount',
      aggFunc: 'sum',
      cellRenderer: ColoredNumericRenderer,
      ...getCurrencyColDefs(),
    },
    {
      headerName: 'Number of Shares',
      field: 'quantity',
      aggFunc: 'sum',
      cellRenderer: ColoredNumericRenderer,
      ...getIntegerColDefs(),
    },
    { headerName: 'PPS', field: 'pps', colId: 'pps', ...getCurrencyColDefs() },
    {
      headerName: 'Date Modified',
      valueGetter: (params: ValueGetterParams<MetricsTransactionDataModel>) => {
        return params.data?.dateModified ? new Date(params.data.dateModified) : null;
      },
      ...getDateColDefs(),
    },
  ];

  return defs;
}
