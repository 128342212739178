import * as yup from 'yup';
import { integerField, multiplierField, percentField, ppsField10dp, usdField } from './common-schema-defs';

export function scenarioRunFields() {
  return {
    cappedProceeds: usdField(0).label('Capped Proceeds').gridMeta({
      aggFunc: 'sum',
    }),
    commonProceeds: usdField(0).label('Converted Proceeds').gridMeta({
      aggFunc: 'sum',
    }),
    fmv: usdField(0).label('FMV').gridMeta({
      aggFunc: 'sum',
    }),
    fullyDilutedShares: integerField().label('Total FDS').gridMeta({
      aggFunc: 'sum',
    }),
    grossIRR: yup.number().label('Gross IRR'),
    grossMoic: multiplierField().label('Gross MOIC').gridMeta({
      aggFunc: 'grossMoicAggFunc',
    }),
    grossProceeds: usdField(0).label('Gross Proceeds').gridMeta({
      aggFunc: 'sum',
    }),
    impliedPricePerShare: usdField().label('Implied PPS'),
    investedAmount: usdField(0).label('Invested Amount').gridMeta({
      aggFunc: 'sum',
    }),
    liquidationProceeds: usdField(0).label('Liquidation Proceeds').gridMeta({
      aggFunc: 'sum',
    }),
    name: yup.string().label('Share Class/Scenario'),
    numberOfShares: yup.number().label('Number of Authorized Shares'),
    numberOfSharesOwned: integerField().label('Shares Held').gridMeta({
      aggFunc: 'sum',
    }),
    ownershipPercentage: percentField().label('Ownership Percentage').gridMeta({
      aggFunc: 'sum',
    }),
    partialInvestedAmount: usdField(0).label('Partial Invested Amount').gridMeta({
      aggFunc: 'sum',
    }),
    partialNumberOfShares: integerField().label('Partial Shares').gridMeta({
      aggFunc: 'sum',
    }),
    partialNumberOfSharesPercentage: percentField().label('Partial Shares Percentage').gridMeta({
      aggFunc: 'partialNumberOfSharesPercentageAggFunc',
    }),
    postMoneyValuation: usdField(0).label('Post-Money Valuation').gridMeta({
      aggFunc: 'postMoneyValuationAggFunc',
    }),
    prefix: yup.string(),
    pricePerShare: ppsField10dp().label('Original Issue Price'),
    remainingNumberOfSharesHeld: integerField().nullable().label('Remaining Shares Held').gridMeta({
      aggFunc: 'sum',
    }),
    seniority: integerField().label('Seniority'),
    source: yup.string().oneOf(['cap-table', 'user-input']).label('Source'),
    transactionDate: yup.date(),
    type: yup.string(),
  };
}

export function scenarioRunFieldsSchema() {
  return scenarioRunResponseSchema().omit(['transactionDate', 'type', 'breakdownByFund', 'prefix']);
}

export function scenarioRunResponseBaseSchema() {
  const fields = scenarioRunFields();
  return yup.object({
    ...fields,
    fmv: fields.fmv.required(),
    fullyDilutedShares: fields.fullyDilutedShares.required(),
    grossProceeds: fields.grossProceeds.required(),
    investedAmount: fields.investedAmount.required(),
    liquidationProceeds: fields.liquidationProceeds.required(),
    name: fields.name.required(),
    numberOfShares: fields.numberOfShares.required(),
    numberOfSharesOwned: fields.numberOfSharesOwned.required(),
    ownershipPercentage: fields.ownershipPercentage.required(),
    postMoneyValuation: fields.postMoneyValuation.required(),
    pricePerShare: fields.pricePerShare.required(),
    seniority: fields.seniority.required(),
    source: fields.source.required(),
  });
}

export function scenarioRunSchemaByFund() {
  return scenarioRunResponseBaseSchema().shape({
    fund: yup.string().required(),
  });
}

export function scenarioRunResponseSchema() {
  return scenarioRunResponseBaseSchema().shape({
    breakdownByFund: yup.array().of(scenarioRunSchemaByFund()),
  });
}

export type ScenarioRunByFundDataModel = yup.InferType<ReturnType<typeof scenarioRunSchemaByFund>>;
export type ScenarioRunDataModel = yup.InferType<ReturnType<typeof scenarioRunResponseSchema>>;
