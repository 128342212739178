import { createTemplateAssignment, TemplateAssignment } from '../schemas/template-assignment-schema';
import { KpiPeriod, KpiSection } from './company-financials.data-model';

export enum KPIRequestStatus {
  Archived = 'archived',
  NotSent = 'notSent',
  Sent = 'sent',
  Submitted = 'submitted',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

// FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-7630
export enum KPIRequestPeriod {
  Month = 'month',
  Quarter = 'quarter',
  Annual = 'annual',
  None = 'none',
}

export enum KPIRequestFrequency {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Annual = 'annually',
  None = 'none',
}

export const periodToFrequency: Record<KpiPeriod, KPIRequestFrequency> = {
  [KpiPeriod.month]: KPIRequestFrequency.Monthly,
  [KpiPeriod.quarter]: KPIRequestFrequency.Quarterly,
  [KpiPeriod.year]: KPIRequestFrequency.Annual,
};

export const frequencyToPeriod: Record<KPIRequestFrequency, KPIRequestPeriod> = {
  [KPIRequestFrequency.Quarterly]: KPIRequestPeriod.Quarter,
  [KPIRequestFrequency.Annual]: KPIRequestPeriod.Quarter,
  [KPIRequestFrequency.Monthly]: KPIRequestPeriod.Month,
  [KPIRequestFrequency.None]: KPIRequestPeriod.None,
};

export type KPIRequestsResponse = IKPIRequestDataModel[] | null;

export type KPIRequestResponse = IKPIRequestDataModel | null;

export enum ReminderType {
  manual = 'Additional',
  scheduled = 'Due date',
}
export interface IKPIRequestReminderDataModel {
  id: number;
  createdAt: string;
  updatedAt: string;
  kpiRequestId: number;
  type: keyof typeof ReminderType;
  sentAt: string;
}

export interface IKPIRequestDataModel extends Omit<TemplateAssignment, 'fye'> {
  archivedAt: string | null;
  deletedAt?: string | null;
  fye: string;
  kpiRequestReminders: IKPIRequestReminderDataModel[];
  period: string;
  sentAt: string | null;
  status: KPIRequestStatus | string;
  templateId: number;
}

export function createKpiRequestReminderDataModel(
  overrides: Partial<IKPIRequestReminderDataModel> = {}
): IKPIRequestReminderDataModel {
  return {
    id: 0,
    createdAt: '',
    updatedAt: '',
    kpiRequestId: 0,
    type: 'scheduled',
    sentAt: '',
    ...overrides,
  };
}

export interface IKPIRequestForm
  extends Pick<IKPIRequestDataModel, 'templateUuid' | 'frequency' | 'respondent' | 'reminderThreshold'> {
  fye: string;
}

export const createKPIRequestDataModel = (
  overrides: Partial<IKPIRequestDataModel> = {}
): IKPIRequestDataModel => ({
  ...createTemplateAssignment(),
  archivedAt: null,
  companyId: 1,
  frequency: KPIRequestFrequency.Quarterly,
  fye: '2021-12-31',
  id: 1,
  kpiRequestReminders: [],
  period: '',
  respondent: [],
  sentAt: null,
  status: KPIRequestStatus.NotSent,
  templateId: 0,
  templateUuid: '',
  ...overrides,
});

export interface IKPIResponseFormSectionData {
  sectionId: number;
  value: unknown;
}

export interface IKPIDataValue {
  kpiId: number;
  value: number;
  date: string;
  section: KpiSection;
  numberFormatValue?: string;
}

export interface IKPIData {
  period: KPIRequestPeriod;
  currencyId: number;
}

export interface IKPIResponseFormKPIData extends IKPIData {
  values: Array<IKPIDataValue[]>;
}

export interface IKPIResponseRequestKPIData extends IKPIData {
  values: IKPIDataValue[];
}

export type KPIFileUploadedType = {
  companyId?: number;
  fileId?: string;
  fileName: string;
  contentType: string;
  fileSize?: number;
};

export interface IKPIResponseForm {
  id?: number;
  lastFocusedMetric: {
    metricParentRowIdx: number;
    metricCeilColIdx: number;
    focused: boolean;
  };
  requestId?: number;
  responsedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  // FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-5684 (check meta property on IKPITemplateSectionDataModel)
  // unknown because weird bug with react-hook-form and typescript
  sections: unknown[] | null | undefined;
  sectionData: IKPIResponseFormSectionData[];
  kpiData: IKPIResponseFormKPIData;
  kpiRequest: unknown | null | undefined;
}

export interface IKPIResponseActionPayload {
  sectionData: IKPIResponseFormSectionData[];
  kpiData: IKPIResponseRequestKPIData;
}

export interface IKPIRequestResponse {
  createdAt?: string;
  updatedAt?: string;
  id: number;
  sectionData: IKPIResponseFormSectionData[];
  kpiData: IKPIResponseRequestKPIData;
  respondedAt?: string | null;
}

export function createKPIRequestResponseDataModel(
  overrides: Partial<IKPIRequestResponse> = {}
): IKPIRequestResponse {
  return {
    id: 0,
    sectionData: [],
    kpiData: {
      period: KPIRequestPeriod.Month,
      currencyId: 0,
      values: [],
    },
    ...overrides,
  };
}

export function periodFromGranularityAndFrequency(
  granularity: KPIRequestFrequency | null | undefined,
  frequency: KPIRequestFrequency = KPIRequestFrequency.Monthly
): KPIRequestPeriod {
  if (granularity === KPIRequestFrequency.None || !granularity) {
    return frequencyToPeriod[frequency];
  }
  if (granularity === KPIRequestFrequency.Annual && frequency === KPIRequestFrequency.Annual) {
    return KPIRequestPeriod.Annual;
  }

  return frequencyToPeriod[granularity];
}
