import { useRef, useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { SelectionChangedEvent } from 'ag-grid-community';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { filterKPIRequestsByStatuses } from '../../../../services/state/KPI/KPIRequestsState';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import useKPIResponsesColDefs from './hooks/useKPIRequestsResponsesColDefs';
import { KPIRequestsFrequenciesFilter } from './components/Filters/KPIRequestsFrequenciesFilter';
import { KPIRequestsTemplatesFilter } from './components/Filters/KPIRequestsTemplatesFilter';
import { useKPIRequestsFilters } from './hooks/useKPIRequestsFilters';
import { BulkActionButtons } from './BulkAcceptResponse';
import { useKPIRequestResponseActions } from './KPIRequestResponse/hooks/useKPIRequestResponseActions';
import { RequestConfirmDialog } from './KPIRequestResponse/RequestConfirmDialog';
import { FilterSection } from './components/Filters/FilterSection';

export function KPIResponsesGrid({ status }: { status: KPIRequestStatus }) {
  const responses: IKPIRequestDataModel[] | null =
    useRecoilValue(filterKPIRequestsByStatuses([status])) ?? null;
  const { columnDefs, defaultColDef } = useKPIResponsesColDefs(status);
  const {
    filteredRequests,
    selectedTemplates,
    selectedFrequencies,
    onSelectTemplateChange,
    onSelectFrequenciesChange,
  } = useKPIRequestsFilters(responses);
  const gridRef = useRef<AgGridReact>(null);

  const [selectedItems, setSelectedItems] = useState<IKPIRequestDataModel[]>([]);

  const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    setSelectedItems(e.api.getSelectedRows());
  }, []);

  const { handleBulkAccept } = useKPIRequestResponseActions();
  const [loading, setLoading] = useState(false);

  const onBulkAccept = useCallback(async () => {
    setLoading(true);
    await handleBulkAccept(selectedItems.map((r) => r.id));
    setLoading(false);
    gridRef.current?.api?.deselectAll();
    setShowConfirmDialog(false);
  }, [handleBulkAccept, selectedItems]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const onSearchCompanyChange = useCallback((value: string) => {
    gridRef.current?.api?.setGridOption('quickFilterText', value);
  }, []);

  return (
    <Stack height={'100%'}>
      <FilterSection marginTop='1rem' marginBottom={'1.5rem'}>
        <SearchInput onChange={onSearchCompanyChange} placeholder='Search for a company' />
        <KPIRequestsTemplatesFilter value={selectedTemplates} onChange={onSelectTemplateChange} />
        <KPIRequestsFrequenciesFilter value={selectedFrequencies} onChange={onSelectFrequenciesChange} />
      </FilterSection>
      {selectedItems.length > 0 && (
        <BulkActionButtons
          onAction={() => setShowConfirmDialog(true)}
          onCancel={() => gridRef.current?.api?.deselectAll()}
          actionLabel={`Accept Selected (${selectedItems.length})`}
        />
      )}
      {showConfirmDialog && (
        <RequestConfirmDialog
          open={showConfirmDialog}
          onClose={() => {
            setShowConfirmDialog(false);
            gridRef.current?.api?.deselectAll();
          }}
          requests={selectedItems}
          onConfirm={onBulkAccept}
          loading={loading}
          title='Confirm Bulk Accept'
          message="Please confirm you want to accept all selected responses. Accepted data will reflect in the company's financials."
        />
      )}

      <FadeInGridWrapper key={status}>
        <AgTable
          ref={gridRef}
          rowData={filteredRequests}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupIncludeTotalFooter={false}
          groupDefaultExpanded={-1}
          suppressMovableColumns={true}
          rowSelection={status === KPIRequestStatus.Submitted ? 'multiple' : undefined}
          onSelectionChanged={onSelectionChanged}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
