import { Key, useMemo } from 'react';
import { css } from '@emotion/react';
import { SetterOrUpdater } from 'recoil';
import { Box, Chip, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { motion } from 'framer-motion';
import { BackButton } from '../../../components/BackButton';
import { IFormField } from '../../../view-models/form.view-model';
import { DetailsConfigActions } from './DetailsConfigActions';
import { useDisplayConfigState } from './useDisplayConfigState';
import { ExitConfigBlocker } from './ExitConfigBlocker';
import { FieldOrderConfig } from './FieldOrderConfig';

const panelContainer = css`
  display: grid;
  grid-template-columns: 1fr minmax(30%, 26rem);
  column-gap: 1rem;
  overflow: hidden;
`;

const detailsPanelContainer = css`
  align-self: start;
`;

const statusChip = css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export interface IDisplayConfigStateParams<T extends string> {
  selectedFields?: T[];
  setSelectedFields: SetterOrUpdater<T[] | undefined>;
  handleSaveChanges: () => Promise<void>;
  handleResetConfig: () => Promise<void>;
  fieldsPerPage: number;
}

export interface IDummySectionProps {
  defaultPage: number;
  key: Key;
}
interface IDisplayConfigProps<T extends string> extends IDisplayConfigStateParams<T> {
  title: string;
  allFields: IFormField<unknown>[];
  renderDummySection: (props: IDummySectionProps) => JSX.Element;
}

export function DisplayConfig<T extends string>({
  title,
  handleSaveChanges,
  handleResetConfig,
  selectedFields,
  setSelectedFields,
  fieldsPerPage,
  allFields,
  renderDummySection,
}: IDisplayConfigProps<T>) {
  const { onSave, onReset, editStatus, activePage, onRemoveField, onReorder, onAddField } =
    useDisplayConfigState<T>({
      selectedFields,
      setSelectedFields,
      handleSaveChanges,
      handleResetConfig,
      fieldsPerPage,
    });

  const key = useMemo(() => selectedFields?.join(',') ?? '', [selectedFields]);

  const dummySection = useMemo(
    () => renderDummySection({ defaultPage: activePage, key: key }),
    [activePage, key, renderDummySection]
  );

  return (
    <Stack height='100%'>
      <ExitConfigBlocker onSave={onSave} onReset={onReset} editStatus={editStatus} />
      <Stack direction='row' alignItems={'start'} columnGap='0.5rem'>
        <BackButton to={'../'} title='Back to main panel' />
        <Typography variant='h4' mb='1rem'>
          {title}
        </Typography>
        {editStatus !== 'initial' && (
          <Chip
            sx={{ mt: '0.5rem' }}
            label={
              <div css={statusChip}>
                {editStatus === 'saved' ? 'Saved' : 'Modified'}
                {editStatus === 'saved' && <CheckIcon fontSize='small' />}
              </div>
            }
            color={editStatus !== 'saved' ? undefined : 'success'}
          />
        )}
      </Stack>
      <Stack direction={'row'} gap={'1rem'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant='body2' color='text.secondary'>
          Customize the field order for this section. To apply all changes, press the button “Save Changes”
        </Typography>
        <DetailsConfigActions
          save={{ handler: onSave, disabled: editStatus !== 'modified' }}
          reset={{ handler: onReset }}
        />
      </Stack>
      <Box margin={'1rem 0'} css={panelContainer}>
        <motion.div key={key} animate={{ opacity: [0.5, 1] }} transition={{ duration: 1 }}>
          <Box css={detailsPanelContainer}>{dummySection}</Box>
        </motion.div>
        <FieldOrderConfig
          selectedFields={selectedFields as string[]}
          onRemoveField={onRemoveField}
          onReorder={onReorder}
          onAddField={onAddField}
          allFields={allFields}
        />
      </Box>
    </Stack>
  );
}
