import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  ManageCompanyFieldsTypes,
  manageAssignCompanyFormSchema,
  manageSingleCompanyFormSchema,
} from '../../../../../schemas/template-assignment-schema';

export type ManageAction = 'manage' | 'assign';
export function useManageCompanyFormMethods(
  defaultValues?: Partial<ManageCompanyFieldsTypes>,
  action: ManageAction = 'manage'
) {
  const schema = action === 'manage' ? manageSingleCompanyFormSchema() : manageAssignCompanyFormSchema();
  const defaults = defaultValues ?? schema.getDefault();

  const methods = useForm<ManageCompanyFieldsTypes>({
    defaultValues: defaults,
    resolver: yupResolver(schema),
  });

  return methods;
}
