import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { FieldPath } from 'react-hook-form';
import { useAllCompanyDetailsFields } from '../../CompanyProfiles2/Summary/useCompanyDetailsFields';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { useAllInvestmentMetricsFields } from '../../CompanyProfiles2/Summary/useInvestmentMetricsFields';
import { CompanyFundMetricsDataModel } from '../../../schemas/CompanyFundMetrics.schema';
import { selectedDetailsFieldsState, selectedMetricsFieldsOrgState } from './DataDisplayState';

export function useLoadSelectedDetailsFields() {
  const [selectedFields, setSelectedFields] = useRecoilState(selectedDetailsFieldsState);
  const allFields = useAllCompanyDetailsFields();

  const [loaded, setLoaded] = useState(Boolean(selectedFields?.length));

  useEffect(() => {
    if (!selectedFields?.length) {
      setSelectedFields(allFields.map((field) => field.key as FieldPath<ICompanyDataModel>));
      setLoaded(true);
    }
  }, [allFields, selectedFields?.length, setSelectedFields]);

  return { loaded, selectedFields };
}

export function useLoadSelectedMetricsFields() {
  const [selectedFields, setSelectedFields] = useRecoilState(selectedMetricsFieldsOrgState);
  const allFields = useAllInvestmentMetricsFields();

  const [loaded, setLoaded] = useState(Boolean(selectedFields?.length));

  useEffect(() => {
    if (!selectedFields?.length) {
      setSelectedFields(allFields.map((field) => field.key as FieldPath<CompanyFundMetricsDataModel>));
      setLoaded(true);
    }
  }, [allFields, selectedFields?.length, setSelectedFields]);

  return { loaded, selectedFields };
}

export function getPageWithChange(staleOrder: string[], newOrder: string[], itemsPerPage: number) {
  for (let i = 0; i < staleOrder.length; i++) {
    if (staleOrder[i] !== newOrder[i]) {
      return getPageForIndex(i, itemsPerPage);
    }
  }
  return 1;
}

export function getPageForIndex(index: number, itemsPerPage: number) {
  return Math.floor(index / itemsPerPage) + 1;
}
