import { updatePreferencesV3 } from '../services/queries/MaggiePreferencesQueries';
import {
  IPreferencesV3DataModel,
  PreferenceKey,
  PreferenceScope,
} from '../data-models/preferences-v3.data-model';
import { FDMap } from './data-structure/FDMap';

export class PreferencesServiceV3 {
  static #instance: PreferencesServiceV3 | undefined;
  #preferences: FDMap<PreferenceScope, FDMap<PreferenceKey, unknown>>;

  private constructor() {
    this.#preferences = new FDMap<PreferenceScope, FDMap<PreferenceKey, unknown>>([
      [PreferenceScope.USER, new FDMap<PreferenceKey, unknown>()],
      [PreferenceScope.ORGANIZATION, new FDMap<PreferenceKey, unknown>()],
    ]);
  }

  static initService(preferences: IPreferencesV3DataModel<unknown>[]) {
    if (!this.#instance) {
      this.#instance = new PreferencesServiceV3();
    }

    const prefsAsMap = preferences.reduce((res, preference) => {
      const { scope, preferenceKey, value } = preference;

      return res.setOrUpdate(scope, new FDMap([[preferenceKey as PreferenceKey, value]]), (existingPrefs) =>
        existingPrefs.set(preferenceKey as PreferenceKey, value)
      );
    }, new FDMap<PreferenceScope, FDMap<PreferenceKey, unknown>>());

    this.#instance.setAllPreferences(prefsAsMap);

    return this.#instance;
  }

  static get() {
    if (!this.#instance) {
      throw new Error('Preferences service not initialized');
    }

    return this.#instance;
  }

  static destroyService() {
    this.#instance = undefined;
  }

  setAllPreferences(preferences: FDMap<PreferenceScope, FDMap<PreferenceKey, unknown>>) {
    this.#preferences = preferences;
  }

  getAllPreferences(scope = PreferenceScope.USER) {
    return this.#preferences.get(scope);
  }

  getPreferences<T>(preferenceKey: PreferenceKey, scope = PreferenceScope.USER): T | undefined {
    return this.#preferences.get(scope)?.get(preferenceKey) as T;
  }

  setPreferences<T>(key: PreferenceKey, value: T, scope = PreferenceScope.USER) {
    this.#preferences.getOrDefault(scope, new FDMap()).set(key, value);

    return updatePreferencesV3({
      scope,
      preferenceKey: key,
      value,
    });
  }
}
