import { useMatch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { NavigationWrapper } from '../NavigationWrapper';
import { SubSectionNavigation } from '../../SubSectionNavigation/SubSectionNavigation';
import SubSectionNavItems from '../../SubSectionNavigation/SubSectionNavItems';
import { PermissionService } from '../../../../../services/PermissionService';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import {
  CONFIG_ROUTE,
  CUSTOMIZATION_SECTIONS,
  MANAGEMENT_SECTIONS,
  PIPELINE_SECTIONS,
  PORTFOLIO_SECTIONS,
  SOURCING_SECTIONS,
} from './AdminPanelRoutesMappings';

export const AdminPanelNavigation = () => {
  const flags = useFlags<MaggieFeatureFlags>();
  const match = useMatch(`/${ROUTES.ADMIN_PANEL}/*`);
  const permSvc = PermissionService.get();
  const canViewNotificationsConfig =
    flags.showNotifications && permSvc.hasPermission(PermissionKey.canViewDeal);

  if (match === null) return null;

  let portCoSections = PORTFOLIO_SECTIONS;
  let customizationSections = CUSTOMIZATION_SECTIONS;
  if (!flags.showManageGroups) {
    portCoSections = PORTFOLIO_SECTIONS.slice(0, 2);
  }

  if (!flags.showDataDisplayConfig) {
    customizationSections = CUSTOMIZATION_SECTIONS.filter(
      (sec) => sec.id !== ROUTES.ADMIN_PANEL_DATA_DISPLAY
    );
  }
  if (!flags.showRubricConfig) {
    customizationSections = customizationSections.filter((sec) => sec.id !== ROUTES.ADMIN_PANEL_RUBRICS);
  }

  return (
    <NavigationWrapper title='Admin Panel'>
      {canViewNotificationsConfig && (
        <SubSectionNavigation title='Management'>
          <SubSectionNavItems sectionList={MANAGEMENT_SECTIONS} parentRoute={ROUTES.ADMIN_PANEL} />
        </SubSectionNavigation>
      )}
      <SubSectionNavigation title='CUSTOMIZATION'>
        <SubSectionNavItems sectionList={customizationSections} parentRoute={ROUTES.ADMIN_PANEL} />
      </SubSectionNavigation>
      {permSvc.hasPermission(PermissionKey.canEditDeal) && (
        <SubSectionNavigation title='Pipeline Configuration'>
          <SubSectionNavItems sectionList={PIPELINE_SECTIONS} parentRoute={CONFIG_ROUTE} />
        </SubSectionNavigation>
      )}
      {permSvc.hasPermission(PermissionKey.canEditPortfolioCompany) && (
        <SubSectionNavigation title='Portfolio Configuration'>
          <SubSectionNavItems sectionList={portCoSections} parentRoute={CONFIG_ROUTE} />
        </SubSectionNavigation>
      )}
      <SubSectionNavigation title='Sourcing Configuration'>
        <SubSectionNavItems sectionList={SOURCING_SECTIONS} parentRoute={CONFIG_ROUTE} />
      </SubSectionNavigation>
    </NavigationWrapper>
  );
};
