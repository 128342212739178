import * as yup from 'yup';
import { IDisplayField } from '../view-models/display-field.view-model';
import { RendererType } from '../data-models/field.data-model';
import { ISelectMeta } from '../data-models/field3.data-model';
import { CalculationType, Fund, fundFields, fundProfilesFormSchema } from './Fund.schema';
import { percentFieldHighPrecision0to100 } from './common-schema-defs';

export enum FundViewModelCalcType {
  total = 'Total',
  lpOnly = 'LP Only',
}

const calcTypeMeta = (key: string): IDisplayField<ISelectMeta<FundViewModelCalcType>> => ({
  key,
  renderer: RendererType.singleSelect,
  rendererMeta: {
    values: Object.values(FundViewModelCalcType).map((value) => ({
      value,
      displayName: value,
    })),
  },
});

export function fundViewModelFields() {
  return {
    contributionsCalcType: yup
      .string()
      .label('What contributions are returned as "Return of Capital" before the first tier?')
      .oneOf(Object.values(FundViewModelCalcType))
      .customMeta<IDisplayField<ISelectMeta<FundViewModelCalcType>>>({
        ...calcTypeMeta('_viewModel.contributionsCalcType'),
      })
      .required()
      .default(FundViewModelCalcType.total)
      .when('distributionsCalcType', {
        is: FundViewModelCalcType.lpOnly,
        then: (schema) =>
          schema.test({
            message: `Contributions must be "${FundViewModelCalcType.lpOnly}" when distributions are "${FundViewModelCalcType.lpOnly}"`,
            test: (value) => {
              return value !== FundViewModelCalcType.total;
            },
          }),
      }),
    distributionsCalcType: yup
      .string()
      .label('What distributions do you want to use to calculate distributable proceeds?')
      .oneOf(Object.values(FundViewModelCalcType))
      .customMeta<IDisplayField<ISelectMeta<FundViewModelCalcType>>>({
        ...calcTypeMeta('_viewModel.distributionsCalcType'),
      })
      .required()
      .default(FundViewModelCalcType.total),
    gpCommitmentPercentage: percentFieldHighPrecision0to100()
      .label('GP Commitment %')
      .nullable()
      .default(null),
    tier1: yup.boolean().nullable().label('Tier 1: Preferred Return').default(true),
    tier2: yup.boolean().nullable().label('Tier 2: Catch-Up').default(true),
    tier3: yup.boolean().nullable().label('Tier 3: Super Return Split').default(true),
  };
}

function fundViewModelBase() {
  return yup.object(fundViewModelFields());
}

export function fundViewModelStep1Schema() {
  return fundProfilesFormSchema().shape({
    _viewModel: fundViewModelBase(),
  });
}

export function fundViewModelSchema() {
  const { lpGpSplit, lpGpSplitThreshold, gpCatchUpPercentage, superReturnSplit } = fundFields();
  return fundProfilesFormSchema().shape({
    _viewModel: fundViewModelBase(),
    lpGpSplit: lpGpSplit.when('_viewModel.tier1', {
      is: true,
      then: (schema) => schema.required(),
    }),
    lpGpSplitThreshold: lpGpSplitThreshold.when('_viewModel.tier1', {
      is: true,
      then: (schema) => schema.required(),
    }),
    gpCatchUpPercentage: gpCatchUpPercentage.when('_viewModel.tier2', {
      is: true,
      then: (schema) => schema.required(),
    }),
    superReturnSplit: superReturnSplit.when('_viewModel.tier3', {
      is: true,
      then: (schema) => schema.required(),
    }),
  });
}

export type FundViewModel = yup.InferType<ReturnType<typeof fundViewModelSchema>>;

export function fromFundViewModel(fundViewModel: FundViewModel): Fund {
  let calculationType: CalculationType;
  const { _viewModel, ...rest } = fundViewModel;
  const result = { ...rest };

  if (
    _viewModel.contributionsCalcType === FundViewModelCalcType.total &&
    _viewModel.distributionsCalcType === FundViewModelCalcType.total
  ) {
    calculationType = CalculationType.combined;
  } else if (
    _viewModel.contributionsCalcType === FundViewModelCalcType.lpOnly &&
    _viewModel.distributionsCalcType === FundViewModelCalcType.lpOnly
  ) {
    calculationType = CalculationType.lpOnly;
  } else if (
    _viewModel.contributionsCalcType === FundViewModelCalcType.total &&
    _viewModel.distributionsCalcType === FundViewModelCalcType.lpOnly
  ) {
    calculationType = CalculationType.mixed;
  } else {
    throw new Error('Invalid calculation type');
  }

  const lpCommitmentSplit =
    result.lpCommitmentSplit ??
    (_viewModel.gpCommitmentPercentage != null ? 100 - _viewModel.gpCommitmentPercentage : null);

  if (!_viewModel.tier1) {
    result.lpGpSplit = null;
    result.lpGpSplitThreshold = null;
  }
  if (!_viewModel.tier2) {
    result.gpCatchUpPercentage = null;
  }
  if (!_viewModel.tier3) {
    result.superReturnSplit = null;
  }

  return {
    ...result,
    lpCommitmentSplit,
    calculationType,
  };
}

export function toFundViewModel(fund: Fund): FundViewModel {
  const gpCommitmentPercentage = fund.lpCommitmentSplit != null ? 100 - fund.lpCommitmentSplit : null;

  let contributionsCalcType, distributionsCalcType;

  switch (fund.calculationType) {
    case CalculationType.combined: {
      contributionsCalcType = FundViewModelCalcType.total;
      distributionsCalcType = FundViewModelCalcType.total;
      break;
    }
    case CalculationType.lpOnly: {
      contributionsCalcType = FundViewModelCalcType.lpOnly;
      distributionsCalcType = FundViewModelCalcType.lpOnly;
      break;
    }

    case CalculationType.mixed: {
      contributionsCalcType = FundViewModelCalcType.lpOnly;
      distributionsCalcType = FundViewModelCalcType.total;
      break;
    }

    default: {
      throw new Error('Invalid calculation type');
    }
  }

  return {
    ...fund,
    _viewModel: {
      contributionsCalcType,
      distributionsCalcType,
      gpCommitmentPercentage,
      tier1: fund.lpGpSplit != null || fund.lpGpSplitThreshold != null,
      tier2: fund.gpCatchUpPercentage != null,
      tier3: fund.superReturnSplit != null,
    },
  };
}
