import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import { NavLink } from 'react-router-dom';
import Archive from '../../../assets/icons/archive.svg?react';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { cardStyles } from '../../../theme/component-styles';

export function NoCapTable() {
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={cardStyles}
      minHeight={'60vh'}
      alignSelf={'stretch'}
    >
      <Archive />
      <Typography variant='h4' mt='1rem' mb='2rem'>
        No Cap Table
      </Typography>
      <AddCaptableButton />
      <Typography variant='body2' mt='1rem' color='text.secondary'>
        Please add your version of cap table
      </Typography>
    </Stack>
  );
}

export function AddCaptableButton(props: ButtonProps) {
  return (
    <Button
      size='medium'
      variant='contained'
      color='secondary'
      startIcon={<PublishIcon />}
      component={NavLink}
      to={ROUTES.CAP_TABLE_FORM}
      {...props}
    >
      Create Cap Table
    </Button>
  );
}
