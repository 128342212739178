import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Collapse, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { debounce } from 'lodash-es';
import { DealModal } from '../../../DealFlow2/components/DealModal/DealModal';
import { MuiSingleSelect, Option } from '../../../../components/MuiSingleSelect/MuiSingleSelect';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { FormWrapper } from '../../../Finance/components/TransactionModal/Forms/TransactionForms/Equity/EquityBuyForm';
import { FieldGroup } from '../../../../components/FieldGroup/FieldGroup';
import { FormField } from '../../../../components/FormField/FormField';
import { activeCompanyListIdState, listsByIdState } from '../../state/ListsState';
import { ICreateListItemForm, initCreateListItemForm } from '../../../../data-models/list-item.data-model';
import { SectorSelect } from '../../../Finance/form-components/SectorSelect';
import { activeUsersState } from '../../../../services/state/AppConfigState';
import { useCreateCompanyAndUpdateState } from '../../../../services/hooks/useCreateCompanyAndUpdateState';
import { useDuplicateWebsiteHandling } from '../../../DealFlow2/components/DealModal/CompanyForm/useDuplicateWebsiteHandling';
import { CompanySuggestion } from '../../../DealFlow2/components/DealModal/CompanyForm/CompanySuggestion';
import { createCompanyDataModel } from '../../../../data-models/company.data-model';
import { useListUploadActions } from '../../../../services/hooks/useListActions';
import { companyToSearchResultWithRealId } from '../../../../util/companyUtils';
import { CreateListItemSchema as validationSchema } from './CreateListItemSchema';

const DuplicateMessage = 'Company already exists';

const ButtonWrapper = styled('div')`
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  padding-bottom: 2rem;
`;

export const CreateListItemForm = ({ name }: { name: string }) => {
  const [mappedUsers, setMappedUsers] = useState<Option[] | null>(null);
  const users = useRecoilValue(activeUsersState);
  const { onCloseModal } = useModalState();

  const activeListId = useRecoilValue(activeCompanyListIdState);
  const listsMap = useRecoilValue(listsByIdState);
  const activeList = activeListId != null ? listsMap.get(activeListId) : null;
  const createCompanyAndUpdateState = useCreateCompanyAndUpdateState(false);
  const { addCompanyToList } = useListUploadActions();

  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    setTouched,
    setFieldValue,
    isValid,
    handleSubmit,
    setFieldError,
    validateField,
  } = useFormik<ICreateListItemForm>({
    initialValues: initCreateListItemForm({ name }),
    validationSchema,
    onSubmit: () => {
      handleCreate(values);
    },
  });

  const { suggestedCompany, checkForDuplicates, resetSuggestions } = useDuplicateWebsiteHandling();

  const handleCreate = useCallback(
    async (values: ICreateListItemForm) => {
      if (!activeList) return;
      const company = suggestedCompany ?? (await createCompanyAndUpdateState(values));
      if (!company) return;
      await addCompanyToList(activeList, companyToSearchResultWithRealId(company));
      onCloseModal();
    },
    [activeList, addCompanyToList, createCompanyAndUpdateState, onCloseModal, suggestedCompany]
  );

  const handleCancelSuggestion = useCallback(() => {
    setFieldError('website', undefined);
    setFieldValue('website', '');
    resetSuggestions();
  }, [resetSuggestions, setFieldError, setFieldValue]);

  const handleSelectSuggestions = useCallback(() => {
    if (!suggestedCompany) return;
    setFieldValue('name', suggestedCompany.name);
    setFieldValue('website', suggestedCompany.website);
    setFieldValue('sectorId', suggestedCompany.sectorId);
    setFieldError('website', undefined);
  }, [setFieldError, setFieldValue, suggestedCompany]);

  useEffect(() => {
    const debouncedCheck = debounce((url) => {
      checkForDuplicates(url);
    }, 300);

    if (values.website) {
      debouncedCheck(values.website);
    } else {
      setFieldError('website', undefined);
      resetSuggestions();
    }
  }, [checkForDuplicates, resetSuggestions, setFieldError, validateField, values.website]);

  const isDuplicate = !!suggestedCompany && suggestedCompany.name !== values.name;

  useEffect(() => {
    if (isDuplicate) {
      setFieldError('website', DuplicateMessage);
    } else if (values.website) {
      validateField('website');
    }
  }, [isDuplicate, setFieldError, validateField, values.website]);

  const handleError = (field: keyof ICreateListItemForm) => (touched[field] ? errors[field] : '');

  useEffect(() => {
    if (users) setMappedUsers(users.map(({ id, name }) => ({ id, value: name })));
  }, [users]);

  return (
    <DealModal title={`Create new company with name '${name}'`} width={'814px'} contentPadding={'0 40px'}>
      <FormWrapper onSubmit={handleSubmit}>
        <FieldGroup hasBorder={false}>
          <FormField label={'Company Name'} required error={handleError('name')}>
            <TextField
              name={'name'}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              variant='outlined'
              placeholder='Enter a company name'
            />
          </FormField>
          <FormField label={'Website'} error={errors.website}>
            <TextField
              name={'website'}
              value={values.website}
              onChange={handleChange}
              variant='outlined'
              placeholder='Enter a website'
            />
          </FormField>
          <Collapse in={isDuplicate}>
            <CompanySuggestion
              company={suggestedCompany ?? createCompanyDataModel()}
              onCancel={handleCancelSuggestion}
              onSelect={handleSelectSuggestions}
              style={{ marginBottom: '1rem' }}
            />
          </Collapse>

          <FormField label={'Sector'} required error={handleError('sectorId')}>
            <SectorSelect
              onChange={(sectorId) => setFieldValue('sectorId', sectorId)}
              value={values.sectorId}
              onFocus={() => setTouched({ ...touched, sectorId: true })}
            />
          </FormField>
        </FieldGroup>
        <FieldGroup>
          <FormField label={'Assignee'} error={handleError('assignee')}>
            <MuiSingleSelect
              options={mappedUsers ?? []}
              disablePortal
              fieldName={'assignee'}
              value={mappedUsers?.find((u) => u.id === values.assignee) ?? null}
              onChange={(_, option) => setFieldValue('assignee', option?.id ?? '')}
              onFocus={() => setTouched({ ...touched, assignee: true })}
              fieldPlaceholder='Select a user'
            />
          </FormField>
        </FieldGroup>
        <ButtonWrapper>
          <Button variant={'contained'} color='secondary' disabled={!isValid || isDuplicate} type={'submit'}>
            Add company
          </Button>
          <Button variant={'outlined'} color='secondary' onClick={onCloseModal}>
            Cancel
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </DealModal>
  );
};
