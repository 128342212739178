import * as yup from 'yup';
import { positiveNumMsg, requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { usdField } from '../../../../../../../schemas/common-schema-defs';

export function optionIssuanceFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    optionsIssuedNo: yup
      .number()
      .nullable()
      .required(requiredMsg('Options Issued'))
      .positive(positiveNumMsg('Options Issued')),
    optionsStrikePrice: usdField()
      .nullable()
      .required(requiredMsg('Strike Price'))
      .positive(positiveNumMsg('Strike Price')),
    currencyid: transactionCurrencyIdField(),
    optionsExpiryDate: yup.date().nullable().required(requiredMsg('Expiration Date')),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function optionIssuanceFormSchema() {
  return yup.object().shape(optionIssuanceFields());
}

export function optionIssuanceGridSchema() {
  return yup.object().shape(optionIssuanceFields());
}
