import { atom, selector } from 'recoil';
import { SerializedEditorState } from 'lexical';
import { INoteDataModel } from '../../../data-models/note.data-model';
import { notesState } from '../../../services/state/NoteState';
import { authenticatedUserState } from '../../../services/state/AuthState';
import { IField } from '../../../data-models/field2.data-model';
import { ActivityTabs } from '../components/ActivityDrawer/ActivityDrawer2';
import { Commentary } from '../../../schemas/Commentary.schema';

export const selectedCompanyIdProfile = atom<number>({
  key: 'profileSelectedCompanyIDState',
  default: -1,
});

export const selectedKpiChartState = atom<IField<unknown> | null>({
  key: 'selectedKpiChartState',
  default: null,
});

export const isDrawerOpenState = atom<boolean>({
  key: 'isDrawerOpenState',
  default: false,
});

export const selectedNoteState = atom<INoteDataModel<SerializedEditorState> | undefined>({
  key: 'selectedNoteState',
  default: undefined,
});

export const showNoteEditorState = selector<boolean>({
  key: 'showNoteEditorState',
  get: ({ get }) => {
    return get(selectedNoteState) !== undefined;
  },
});

export const selectedCommentaryState = atom<Commentary | undefined>({
  key: 'selectedCommentaryState',
  default: undefined,
});

export const showCommentaryEditorState = selector<boolean>({
  key: 'showCommentaryEditorState',
  get: ({ get }) => {
    return get(selectedCommentaryState) !== undefined;
  },
});

export const enum ActivityTabsType {
  ALL = 'All activity',
  MINE = 'My Activity',
}

export const selectedActivityNotes = atom<ActivityTabsType>({
  key: 'selectedActivityFilterState',
  default: ActivityTabsType.ALL,
});

export const filteredNotesState = selector<INoteDataModel<unknown>[]>({
  key: 'filteredNotesState',
  get: ({ get }) => {
    const companyId = get(selectedCompanyIdProfile);

    if (companyId < 0) {
      return [];
    }

    const allNotes = get(notesState(companyId)).slice();
    const selectedActivityTab = get(selectedActivityNotes);
    const currentUser = get(authenticatedUserState);
    const visibleNotes =
      selectedActivityTab === ActivityTabsType.ALL
        ? allNotes
        : allNotes.filter((note) => {
            return note.createdBy === currentUser?.email;
          });

    return visibleNotes.sort(sortNotesByDate);
  },
});

function sortNotesByDate(note1: INoteDataModel<unknown>, note2: INoteDataModel<unknown>) {
  const date1 = new Date(note1.updatedAt);
  const date2 = new Date(note2.updatedAt);

  return date2.getTime() - date1.getTime();
}

export const selectedActivityTypeState = atom<ActivityTabs | undefined>({
  key: 'selectedActivityTypeState',
  default: undefined,
});

export const selectedSummaryChartState = atom<'ttrv' | 'cumul-perf' | null>({
  key: 'selectedSummaryChartState',
  default: null,
});
