import * as yup from 'yup';
import { positiveNumMsg, requiredMsg, typeMsg } from '../../utils/validationMessages';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { integerField, pricePerShareField } from '../../../../../../../schemas/common-schema-defs';

export function tokenBuyFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    investmentType: yup.string().optional(),
    pricePerShare: pricePerShareField()
      .required()
      .positive(positiveNumMsg('Price Per Token'))
      .nullable()
      .typeError(typeMsg('Price Per Token', 'number')),
    noOfShares: integerField()
      .typeError(typeMsg('Number of Tokens', 'number'))
      .required()
      .min(0, positiveNumMsg('Number of Tokens')),
  };
}

export function tokenBuyFormSchema() {
  return yup.object().shape(tokenBuyFields());
}

export function tokenBuyGridSchema() {
  return yup.object().shape(tokenBuyFields()).omit(['investmentType']);
}
