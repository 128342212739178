import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import {
  endOfFiscalPeriod,
  getEachDateByPeriodAndFrequency,
  getFormattedFiscalDate,
} from '../../../../CompanyProfiles/utils/financialUtils';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { KPIRequestFrequency } from '../../../../../data-models/kpi-requests.data-model';
import { ISimpleChoice } from '../../../../../data-models/field2.data-model';

export function getPeriodOptions({
  period,
  fye,
}: {
  period: KpiPeriod;
  fye: number;
}): ISimpleChoice<string>[] {
  let nPeriods = 13;
  if (period === KpiPeriod.quarter) nPeriods = 5;
  if (period === KpiPeriod.year) nPeriods = 3;

  const dates: Date[] = getEachDateByPeriodAndFrequency({
    frequency: period,
    totalSelectedPeriods: nPeriods,
    fye,
    endDate: endOfFiscalPeriod(new Date(), period, fye - 1),
  }).dates;

  return dates.slice(0, dates.length - 1).reduceRight((acc, date) => {
    acc.push({
      value: formatISODateOnly(date),
      displayName: getFormattedFiscalDate(formatISODateOnly(date), period, fye),
    });
    return acc;
  }, [] as ISimpleChoice<string>[]);
}

export const frequencyToKpiPeriod: Record<KPIRequestFrequency, KpiPeriod> = {
  [KPIRequestFrequency.Quarterly]: KpiPeriod.quarter,
  [KPIRequestFrequency.Annual]: KpiPeriod.year,
  [KPIRequestFrequency.Monthly]: KpiPeriod.month,
  [KPIRequestFrequency.None]: KpiPeriod.month, // ??? TODO: check this
};
