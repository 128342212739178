import * as yup from 'yup';
import { positiveNumMsg, requiredMsg } from '../../utils/validationMessages';
import { currencyIdField, integerField } from '../../../../../../../schemas/common-schema-defs';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';

export function ownershipChangeFields() {
  return {
    transactionDate: transactionDateSchema(),
    currencyid: currencyIdField().nullable(),
    reason: yup.string().nullable(),
    fullyDilutedShares: integerField()
      .nullable()
      .required(requiredMsg('Fully Diluted Shares'))
      .positive(positiveNumMsg('Fully Diluted Shares')),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function ownershipChangeFormSchema() {
  return yup.object().shape(ownershipChangeFields());
}
