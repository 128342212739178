import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { transformDateToString } from '../../Forms/transactionFieldSchemas';
import {
  IFinanceRoundFormViewModel,
  isNewRoundId,
} from '../../../../view-models/finance-round-form.view-model';

const optionalMin0 = yup.number().nullable().min(0, 'Value must be greater than 0');
const optionalNumber = yup.number().nullable();

export const assignToInvestmentSchema = yup.object().shape({
  _viewModel: yup.object().shape({
    leadInvestors: yup.array().nullable(),
    coInvestors: yup.array().nullable(),
  }),
  id: optionalNumber,
  roundId: yup.number().nullable(),
  roundDate: yup
    .string()
    .nullable()
    .transform(transformDateToString)
    .when('id', {
      is: isNewRoundId,
      then: (schema) => schema.required('Date is required'),
    }),
  name: yup
    .string()
    .nullable()
    .when('id', {
      is: isNewRoundId,
      then: (schema) => schema.required('Name is required'),
    }),
  fullyDilutedShares: optionalMin0,
  postMoneyValuation: optionalMin0,
  raiseAmount: optionalNumber,
}) as ObjectSchema<IFinanceRoundFormViewModel>;

export const editInvestmentSchema = assignToInvestmentSchema.shape({
  name: yup.string().nullable().required('Name is required'),
  roundDate: yup.string().nullable().transform(transformDateToString).required('Date is required'),
}) as ObjectSchema<IFinanceRoundFormViewModel>;
