import * as yup from 'yup';
import { merge } from 'lodash-es';
import { ParticipationType } from '../data-models/scenario.data-model';
import { RendererType } from '../data-models/field.data-model';
import { StringListCellRenderer } from '../components/AgTable/cell-renderers/StringListCellRenderer';
import {
  dateField,
  integerField,
  multiplierField,
  percentField,
  ppsField10dp,
  usdField,
} from './common-schema-defs';
import { CompanySchema } from './Company.schema';

export function securitySchema() {
  return yup.object().shape(securityFields());
}

export function securityFields() {
  return {
    captableId: yup.number().required(),
    coInvestors: yup.array().of(yup.string()).nullable().label('Co-Investors').default([]),
    companyId: yup.number().required().label('Company').customMeta({
      renderer: RendererType.companyId,
    }),
    conversionPrice: ppsField10dp().nullable().label('Conversion PPS'),
    customData: yup.object().default({}).nullable(),
    discountRate: percentField().default(0).nullable().label('Discount %'),
    displayName: yup.string().required().label('Security').default(''),
    fundIds: yup.array().of(yup.number()).nullable().default([]).label('Fund'),
    id: yup.number().required(),
    interestRate: percentField().default(0).nullable().label('Interest Rate %'),
    investedAmount: usdField().nullable(),
    investmentDate: dateField().nullable(),
    liquidationMultiplier: multiplierField().default(0).nullable(),
    name: yup.string().required(),
    ownershipPercentage: percentField().default(0).nullable().label('Ownership % of Event'),
    paidInCapital: usdField().nullable(),
    participationCap: multiplierField().nullable(),
    participationType: yup.string().oneOf(Object.values(ParticipationType)).nullable(),
    postMoneyValuation: usdField().nullable(),
    preMoneyValuation: usdField().nullable(),
    pricePerShare: usdField().nullable().label('PPS'),
    raisedAmount: usdField().nullable(),
    seniority: integerField().nullable(),
    sharesHeld: integerField().nullable(),
    sharesIssued: integerField().nullable().label('Total FDS'),
    valuationCap: usdField().nullable(),
  };
}

export type SecurityDataModel = yup.InferType<ReturnType<typeof securitySchema>>;

export function createSecurityDataModel(overrides: Partial<SecurityDataModel> = {}): SecurityDataModel {
  return merge(
    securitySchema().getDefault(),
    {
      captableId: 0,
      companyId: 0,
      id: 0,
      name: '',
    },
    overrides
  );
}

export function securityGridSchema() {
  const {
    coInvestors,
    companyId,
    conversionPrice,
    investedAmount,
    liquidationMultiplier,
    paidInCapital,
    participationCap,
    pricePerShare,
    postMoneyValuation,
    preMoneyValuation,
    raisedAmount,
    valuationCap,
    ...rest
  } = securityFields();

  return yup.object().shape({
    ...rest,
    coInvestors: coInvestors.gridMeta({
      cellRenderer: StringListCellRenderer,
    }),
    companyId: companyId.gridMeta({
      renderer: RendererType.companyId,
    }),
    company: CompanySchema.gridSchema(),
    conversionPrice: conversionPrice.gridMeta({ formatter: 'usd' }),
    fundType: yup.array().of(yup.string()).label('Fund Group'),
    investedAmount: investedAmount.gridMeta({ formatter: 'usd' }),
    liquidationMultiplier: liquidationMultiplier.gridMeta({ formatter: 'multiplier' }),
    paidInCapital: paidInCapital.gridMeta({ formatter: 'usd' }),
    participationCap: participationCap.gridMeta({ formatter: 'multiplier' }),
    postMoneyValuation: postMoneyValuation.gridMeta({ formatter: 'usd' }),
    preMoneyValuation: preMoneyValuation.gridMeta({ formatter: 'usd' }),
    pricePerShare: pricePerShare.gridMeta({ formatter: 'usd' }),
    raisedAmount: raisedAmount.gridMeta({ formatter: 'usd' }),
    valuationCap: valuationCap.gridMeta({ formatter: 'usd' }),
  });
}
