import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  sharesField,
  simpleTextField,
  simpleMultilineTextField,
  useRoundIdField,
  amountField,
  conversionRatioField8dp,
} from '../../../data-fields/CommonFields';
import { securityField, getValuationImpactField } from '../../../data-fields/TransactionFields';
import { ExitType, ITransactionDataModel, ProceedsTypes } from '../../../data-models/transaction.data-model';
import { createFormField } from '../../../view-models/form.view-model';
import { ICompanySearchMeta, ISelectMeta } from '../../../data-models/field3.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { SearchType } from '../../../services/queries/MaggieCompanyQueries';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';
import { useGetAmountField } from './TransactionFormUtils';

export function useEquityFields() {
  const getAmountField = useGetAmountField<ITransactionDataModel>('currencyid');
  const getCommonInitialFields = useFundDateCurrencyFields();
  const getAcquirerField = useAcquirerField();
  const getCashFields = useCashFields();
  const getStockProceedsFields = useStockProceedsFields();
  const getValuationImpactFields = useValuationImpactFields();
  const exchangedRoundField = useRoundIdField({
    key: 'exchangedRoundId',
    label: 'Exchanged Stage',
    required: true,
  });
  const receivedRoundField = useRoundIdField({
    key: 'receivedRoundId',
    label: 'Received Stage',
    required: true,
  });

  const getEquityBuyFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      securityField(),
      getAmountField({ key: 'investmentAmount', label: 'InvestmentAmount', required: true }),
      sharesField({ key: 'noOfShares', label: 'Shares Purchased', required: true }),
      getValuationImpactField(),
      conversionRatioField8dp(),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [getAmountField, getCommonInitialFields]);

  const getEquitySellFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      proceedsTypeField(),
      sharesField({ key: 'noOfShares', label: 'Shares Sold', required: true }),
      exitTypeField(),
      ...getAcquirerField(),
      getAmountField({ key: 'exitValuation', label: 'Exit Valuation' }),
      getValuationImpactField(),
      ...getCashFields(),
      ...getStockProceedsFields(),
      ...getValuationImpactFields(),

      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [
    getCommonInitialFields,
    getAcquirerField,
    getAmountField,
    getCashFields,
    getStockProceedsFields,
    getValuationImpactFields,
  ]);

  const getEquitySecondaryPurchaseFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      securityField(),
      getAmountField({ key: 'investmentAmount', label: 'InvestmentAmount', required: true }),
      sharesField({ key: 'noOfShares', label: 'New Shares Purchased', required: true }),
      getValuationImpactField({ required: true }),
      ...getValuationImpactFields(),
      conversionRatioField8dp(),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [getAmountField, getCommonInitialFields, getValuationImpactFields]);

  const getEquityShareExchangeFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      exchangedRoundField,
      sharesField({ key: 'exchangedSharesNo', label: 'Exchanged Shares', required: true }),
      amountField({ key: 'exchangedPricePerShare', label: 'Exchanged PPS', required: true }),
      receivedRoundField,
      sharesField({ key: 'receivedSharesNo', label: 'Received Shares', required: true }),
      amountField({ key: 'receivedPricePerShare', label: 'Received PPS', required: true }),
      simpleTextField({ key: 'security', label: 'Received Security' }),
    ];
  }, [exchangedRoundField, getCommonInitialFields, receivedRoundField]);

  return {
    getEquityBuyFields,
    getEquitySellFields,
    getEquitySecondaryPurchaseFields,
    getEquityShareExchangeFields,
  };
}

function useAcquirerField() {
  const { watch } = useFormContext();

  return useCallback(() => {
    if (!watch('exitType')) return [];
    return [
      createFormField<ICompanySearchMeta>({
        key: 'acquirer',
        label: 'Acquirer',
        renderer: RendererType.companySearch,
        rendererMeta: {
          multiSelect: false,
          showAdd: false,
          searchType: SearchType.Company,
          createOnSelection: false,
        },
      }),
    ];
  }, [watch]);
}

function useCashFields() {
  const { watch } = useFormContext();
  const getAmountField = useGetAmountField<ITransactionDataModel>('currencyid');

  return useCallback(() => {
    if (!watch('proceedsType')?.includes(ProceedsTypes.Cash)) return [];

    return [
      getAmountField({ key: 'receivedAmount', label: 'Cash Received', required: true }),
      getAmountField({ key: 'escrowAmount', label: 'Escrow Amount', required: true }),
    ];
  }, [getAmountField, watch]);
}

function useStockProceedsFields() {
  const { watch } = useFormContext();
  const getAmountField = useGetAmountField<ITransactionDataModel>('currencyid');

  return useCallback(() => {
    if (!watch('proceedsType')?.includes(ProceedsTypes.StockProceeds)) return [];

    return [
      sharesField({ key: 'sharesReceived', label: 'Shares Received from Acquirer', required: true }),
      getAmountField({ key: 'ppsSharesReceived', label: 'PPS of Shares Received', required: true }),
    ];
  }, [getAmountField, watch]);
}

function useValuationImpactFields() {
  const { watch } = useFormContext();

  return useCallback(() => {
    if (!watch('valuationImpact')) return [];

    return [
      simpleTextField({
        key: 'fmvBasis',
        label: 'FMV Basis',
      }),
    ];
  }, [watch]);
}

export function proceedsTypeField() {
  return createFormField<ISelectMeta<string>>({
    key: 'proceedsType',
    label: 'Proceeds Type',
    renderer: RendererType.multiSelect,
    rendererMeta: {
      values: Object.values(ProceedsTypes).map((type) => ({ displayName: type, value: type })),
      multi: true,
    },
    required: true,
  });
}

export function exitTypeField() {
  return createFormField<ISelectMeta<string>>({
    key: 'exitType',
    label: 'Exit Type',
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: Object.values(ExitType).map((type) => ({ displayName: type, value: type })),
    },
  });
}
