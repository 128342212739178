import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { FormProvider } from 'react-hook-form';
import { Alert, Collapse, Box, Card, Stack, Typography, CircularProgress } from '@mui/material';
import { debounce } from 'lodash-es';
import {
  manageAssignCompanyFormSchema,
  ManageCompanyFieldsTypes,
} from '../../../../../schemas/template-assignment-schema';
import { useUpdateCompanyAndState } from '../../../../../services/hooks/useUpdateCompanyAndState';
import { selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { StepperFormButtons } from '../../../../Finance2/Forms/StepperFormButtons';
import { companyState } from '../../../../../services/state/CompanyState';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { cardStyles } from '../../../../../theme/component-styles';
import { CollapseButton } from '../../../../CompanyProfiles/Summary/components/CollapseButton';
import { FormFactoryWithStandardLayout } from '../../../../../components/Form/FormFactory';
import { schemaToFormFields } from '../../../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../../../view-models/form.view-model';
import { useManageCompanyFormMethods } from './useManageCompanyFormMethods';
import { FormCardContainer } from './common-styled-components';
import { companiesWithMissingDataState, currentStepStateTA } from './AssignTemplateFormState';

import { validateCompanyData } from './template-assignment-utils';

export function AssignTemplateStep2() {
  const companyCount = useRecoilValue(selectedKpiCompaniesStateDC).length;

  if (companyCount === 0) return <Alert severity='error'>No companies selected</Alert>;
  return <AssignTemplateStep2Bulk />;
}

interface ISingleCompanySettingsFormProps {
  company: ICompanyDataModel;
  setLoading?: Dispatch<SetStateAction<boolean>>;
}
export function SingleCompanySettingsForm({ company, setLoading }: ISingleCompanySettingsFormProps) {
  const updateCompanyAndState = useUpdateCompanyAndState();
  const methods = useManageCompanyFormMethods(company as ManageCompanyFieldsTypes, 'assign');

  const updateCompany = useMemo(
    () =>
      debounce(async () => {
        if (!company) return false;
        const { fye, respondents } = methods.getValues();
        const isValid = await methods.trigger();
        if (!isValid) return;
        setLoading?.(true);
        await updateCompanyAndState(
          company.id,
          { fye, respondents } as Partial<ICompanyDataModel>,
          false,
          false,
          true
        );
        setLoading?.(false);
      }, 500),
    [company, methods, setLoading, updateCompanyAndState]
  );

  const { watch } = methods;
  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        updateCompany();
      }
    });
    return () => subscription.unsubscribe();
  }, [updateCompany, watch]);

  return (
    <FormProvider {...methods}>
      <ManageCompanyFields />
    </FormProvider>
  );
}

function ManageCompanyFields() {
  const fields = schemaToFormFields(manageAssignCompanyFormSchema());
  const form = createFormFromFieldsArray(fields);
  return <FormFactoryWithStandardLayout form={form} />;
}

export function AssignTemplateStep2Bulk() {
  const selectedCompanies = useRecoilValue(selectedKpiCompaniesStateDC);
  const invalidCompanies = useRecoilValue(companiesWithMissingDataState);

  const cardForms = useMemo(() => {
    const initiallyInvalid = selectedCompanies.filter((c) => !validateCompanyData(c));
    return initiallyInvalid.map((company, i) => {
      return <ExpandableFormContainer companyId={company.id} key={company.id} defaultExpanded={i === 0} />;
    });
  }, [selectedCompanies]);

  return (
    <>
      <FormCardContainer>{cardForms}</FormCardContainer>
      <StepperFormButtons
        stepState={currentStepStateTA}
        stepIsValid={() => Promise.resolve(invalidCompanies.size === 0)}
      />
    </>
  );
}
interface IExpandableFormContainerProps {
  companyId: number;
  defaultExpanded?: boolean;
}
export function ExpandableFormContainer({
  companyId,
  defaultExpanded: initialExpanded,
}: IExpandableFormContainerProps) {
  const [expanded, setExpanded] = useState(initialExpanded ?? false);
  const company = useRecoilValue(companyState(companyId));
  const invalid = !validateCompanyData(company!);
  const [loading, setLoading] = useState(false);
  if (!company) return null;
  return (
    <Card
      sx={{
        ...cardStyles,
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        columnGap: '1rem',
        '&:hover': {
          boxShadow: '0px 4px 10px 0px rgba(44, 46, 49, 0.15)',
        },
      }}
    >
      <CollapseButton expanded={expanded} setExpanded={setExpanded} />
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <CompanyLogoAndName name={company.name} logoUrl={company.logoUrl ?? ''} />
        {loading ? (
          <CircularProgress color='error' size={'1rem'} />
        ) : invalid ? (
          <Typography variant='caption' color='error'>
            {'Missing data'}
          </Typography>
        ) : null}
      </Stack>

      <Box style={{ gridColumn: '1/-1' }}>
        <Collapse in={expanded}>
          {expanded ? (
            <Box sx={{ my: '1rem' }}>
              <SingleCompanySettingsForm company={company} setLoading={setLoading} />
            </Box>
          ) : (
            <div />
          )}
        </Collapse>
      </Box>
    </Card>
  );
}
