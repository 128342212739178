import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ThumbDownIconOutlined from '@mui/icons-material/ThumbDownOutlined';
import {
  Icon,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, memo } from 'react';
import { useRecoilValue } from 'recoil';
import { Add } from '@mui/icons-material';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';
import { useCurrentUser } from '../../../services/hooks/useCurrentUser';
import { usersByIdMapState } from '../../../services/state/AppConfigState';
import { colors } from '../../../theme/colors';
import { IListItemDataModel } from '../../../data-models/list-item.data-model';
import { companyState } from '../../../services/state/CompanyState';
import { ConditionalTooltipWrapper } from '../../../components/ConditionalTooltipWrapper';
import { getAllItemActionsByPriority, isItemHidden } from '../utils/list-utils';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { activeListState } from '../state/ListsState';
import { ListType } from '../../../data-models/list.data-model';
import { TrackingSignifier } from './TrackingSignifier';

const EndIconsContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TextAndIcon = styled('div')`
  align-items: center;
  display: grid;
  column-gap: 0.25rem;
  grid-template-columns: min-content 1fr;
`;

const StyledListItem = styled(ListItem)`
  border: 1px solid white;
  border-radius: 4px;
  margin: 0 0 0.5rem;
  height: 2.75rem;
  box-shadow: 0px -4px 1px rgba(255, 255, 255, 0.06), 0px 4px 1px rgba(16, 37, 62, 0.04),
    0px 24px 1px rgba(75, 82, 93, 0.02);
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 1) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
`;
const StyledListItemButton = styled(ListItemButton)`
  border-radius: 4px;
  max-height: 100%;
  max-width: 100%;
  ${({ selected, theme }) => selected && `background: ${theme.colors.secondary[20]}`};
`;

const commonAvatarStyles = {
  border: '1px solid white',
};

const hiddenAvatarStyles = {
  filter: 'grayscale(0.9)',
  border: 'thin solid white',
  color: colors.neutral[60],
};

interface IListCardProps extends React.HTMLAttributes<HTMLDivElement> {
  item: IListItemDataModel;
  selected: boolean;
}

export const ListCard: FC<IListCardProps> = ({ item, onClick, selected }) => {
  const theme = useTheme();
  const usersMap = useRecoilValue(usersByIdMapState);
  const currentUser = useCurrentUser();
  const hiddenColor = theme.colors.neutral[60];
  const company = useRecoilValue(companyState(item.companyId));

  const activeList = useRecoilValue(activeListState);
  const isDiscoveryList = activeList?.type === ListType.DISCOVERY;

  if (!company || !activeList) {
    return null;
  }

  const isHidden = Boolean(item) && isDiscoveryList && isItemHidden(item, company, currentUser.id);
  const primaryText = company.name;
  const labelId = `checkbox-list-secondary-label-${company.name}`;
  const endIcon = getEndIcon(item, company, currentUser.id, isHidden);

  return (
    <StyledListItem
      disablePadding
      style={isHidden ? { border: `1px solid ${colors.neutral[10]}` } : undefined}
    >
      <StyledListItemButton
        selected={selected}
        onClick={onClick}
        sx={
          isHidden
            ? {
                '&:hover': {
                  filter: 'brightness(1.05)',
                },
              }
            : undefined
        }
        style={
          isHidden && !selected
            ? {
                background:
                  'radial-gradient(100% 204.54% at 0% 0%, rgba(210, 213, 219, 0.44) 0%, rgba(210, 213, 219, 0.176) 100%)',
              }
            : undefined
        }
      >
        <ListItemAvatar sx={{ minWidth: '2.25rem' }}>
          <MUIAvatar
            nameParts={company.name.split(' ')}
            src={company.logoUrl ?? ''}
            style={{
              ...commonAvatarStyles,
              ...(isHidden ? hiddenAvatarStyles : {}),
            }}
          />
        </ListItemAvatar>
        <ListItemText
          id={labelId}
          primary={
            <TextAndIcon>
              {company.isConnected ? (
                <HandshakeOutlinedIcon
                  titleAccess={'has previous deal'}
                  color={isHidden ? 'disabled' : 'secondary'}
                  sx={{
                    fontSize: '14px',
                  }}
                />
              ) : null}
              <ConditionalTooltipWrapper
                text={primaryText}
                typographyProps={isHidden ? { sx: { color: colors.neutral[60] } } : undefined}
              />
            </TextAndIcon>
          }
          color={isHidden ? hiddenColor : ''}
          secondary={
            <Typography component='span' variant='caption' color={isHidden ? hiddenColor : ''}>
              {company.sector?.displayName}
            </Typography>
          }
        />
        <EndIconsContainer key={company.followers?.length}>
          {company.assignee && isDiscoveryList ? (
            <MUIAvatar
              src=''
              nameParts={usersMap.get(company.assignee)?.name?.split(/\s+/) ?? []}
              style={{
                ...commonAvatarStyles,
                ...(isHidden ? hiddenAvatarStyles : {}),
              }}
            />
          ) : (
            <Icon fontSize={'small'} />
          )}
          {endIcon}
        </EndIconsContainer>
      </StyledListItemButton>
    </StyledListItem>
  );
};

function getEndIcon(
  item: IListItemDataModel,
  company: ICompanyDataModel,
  currentUserId: number,
  isHidden: boolean
) {
  const allItemActions = getAllItemActionsByPriority(item, company, currentUserId);
  if (allItemActions.length === 0) {
    return <Icon fontSize={'small'} />;
  }
  const strongestAction = allItemActions[0];

  if (strongestAction === 'pipeline') {
    return <Add titleAccess={'passed'} color={isHidden ? 'disabled' : 'secondary'} fontSize={'small'} />;
  }

  if (strongestAction === 'hidden') {
    return (
      <ThumbDownIconOutlined
        titleAccess={'passed'}
        color={isHidden ? 'disabled' : 'secondary'}
        fontSize={'small'}
      />
    );
  }

  if (strongestAction === 'followedByUser' || strongestAction === 'followedByOthers') {
    return <TrackingSignifier company={company} isHidden={isHidden} />;
  }
}

function listCardsEqual(prevProps: IListCardProps, nextProps: IListCardProps) {
  return (
    prevProps.item.id === nextProps.item.id &&
    prevProps.selected === nextProps.selected &&
    prevProps.hidden === nextProps.hidden &&
    prevProps.onClick === nextProps.onClick
  );
}

export const MemoizedListCard = memo(ListCard, listCardsEqual);
