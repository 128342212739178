import { MostRecentRoundsChart } from '../../../../components/Charts/Leaderboard/MostRecentRounds/MostRecentRoundsChart';
import { useMostRecentRounds } from '../../hooks/useMostRecentRounds';
import { CHART_COMPONENTS } from '../../../../types';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { IPortfolioChartProps } from './ChartComponent';

export function MostRecentRounds(props: IPortfolioChartProps<IMetricsDataModel>) {
  const data = useMostRecentRounds(props.filteredMetrics);

  return <MostRecentRoundsChart id={CHART_COMPONENTS.MOST_RECENT_ROUNDS} width={640} data={data} />;
}
