import { useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';

import { AgTable } from '../../../../components/AgTable/AgTable';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { filterKPIRequestsByStatuses } from '../../../../services/state/KPI/KPIRequestsState';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { KPIRequestsFrequenciesFilter } from './components/Filters/KPIRequestsFrequenciesFilter';
import { KPIRequestsTemplatesFilter } from './components/Filters/KPIRequestsTemplatesFilter';
import { useKPIRequestsFilters } from './hooks/useKPIRequestsFilters';
import useKPIRequestsSentColDefs from './hooks/useKPIRequestsSentColDefs';
import { FilterSection } from './components/Filters/FilterSection';

export function SentKpiRequestsGrid({
  status,
}: {
  status: KPIRequestStatus.Sent | KPIRequestStatus.Archived;
}) {
  const requests = useRecoilValue(filterKPIRequestsByStatuses([status])) ?? null;
  const { columnDefs, defaultColDef } = useKPIRequestsSentColDefs(status);
  const {
    filteredRequests,
    selectedTemplates,
    selectedFrequencies,
    onSelectTemplateChange,
    onSelectFrequenciesChange,
  } = useKPIRequestsFilters(requests);
  const gridRef = useRef<AgGridReact>(null);

  const onSearchCompanyChange = useCallback((query: string) => {
    gridRef.current?.api?.setGridOption('quickFilterText', query);
  }, []);

  return (
    <Stack height={'100%'}>
      <Stack direction='row' alignItems='center' marginBottom={'1.5rem'} marginTop={'.5rem'}>
        <FilterSection marginTop='1rem'>
          <SearchInput onChange={onSearchCompanyChange} placeholder='Search for a company' />
          <KPIRequestsTemplatesFilter value={selectedTemplates} onChange={onSelectTemplateChange} />
          <KPIRequestsFrequenciesFilter value={selectedFrequencies} onChange={onSelectFrequenciesChange} />
        </FilterSection>
      </Stack>
      <FadeInGridWrapper key={status}>
        <AgTable
          ref={gridRef}
          rowData={filteredRequests}
          columnDefs={columnDefs}
          rowSelection='multiple'
          defaultColDef={defaultColDef}
          groupIncludeTotalFooter={false}
          rowGroupPanelShow='always'
          groupDefaultExpanded={-1}
          suppressMovableColumns={true}
          suppressRowDrag={true}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
