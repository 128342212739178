import { useEffect, useRef } from 'react';

export function useFocusElement(containerId: string, htmlTag: keyof HTMLElementTagNameMap, timeout = 0) {
  const timeoutId = useRef<number | null>(null);
  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = window.setTimeout(() => {
      document.getElementById(containerId)?.querySelector(htmlTag)?.focus();
    }, timeout);

    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [containerId, htmlTag, timeout]);
}
