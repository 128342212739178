import { useCallback, KeyboardEvent, useState } from 'react';
import { cloneDeep } from 'lodash-es';
import { Button, Paper, Popover, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomCellEditorProps } from 'ag-grid-react';
import { field2ToFormField } from '../../../../../data-models/field2.data-model';
import { KpiSection } from '../../../../../data-models/company-financials.data-model';
import {
  getFinancialsFromRowData,
  HeaderCompParamsType,
  IValuesBySection,
  TKpiValueSetterParams,
  useKpiValueSetter,
} from '../hooks/useFinancialsColumnDefs';
import { IFinancialsRowData2 } from '../hooks/useFinancialsGridData';
import { useFocusElement } from '../../../../../hooks/useFocusElement';
import { FormFieldWithLabelFactory } from '../../../../../components/Form/FormFieldAndLabelFactory';
import { getFormattedFiscalDate } from '../../../utils/financialUtils';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';

export const calculatedDescription = 'This field is calculated';
export interface IKpiEditorParams
  extends CustomCellEditorProps<IFinancialsRowData2, IValuesBySection<unknown>> {
  fye: number;
}

export function KpiEditor(params: IKpiEditorParams) {
  const { data, value, onValueChange, colDef, stopEditing, fye } = params;
  const { colDate, kpiPeriod } = colDef.headerComponentParams as HeaderCompParamsType;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { valuesByDate2, ...kpi } = data ?? {};
  const financials = getFinancialsFromRowData(data, formatISODateOnly(colDate), kpiPeriod);
  const isActualCalculated = !!financials?.actual?.calculated;
  const isBudgetCalculated = !!financials?.budget?.calculated;

  const methods = useForm<IValuesBySection<unknown>>({
    values: cloneDeep(value) as IValuesBySection<unknown>,
  });

  const field = field2ToFormField(kpi);
  const actualField = cloneDeep({
    ...field,
    name: KpiSection.actual,
    label: 'Actual',
    key: KpiSection.actual,
    variant: 'form' as const,
    description: isActualCalculated ? calculatedDescription : undefined,
    disabled: colDate.getTime() > Date.now() || isActualCalculated,
  });
  const budgetField = cloneDeep({
    ...field,
    name: KpiSection.budget,
    label: 'Budget',
    key: KpiSection.budget,
    variant: 'form' as const,
    description: isBudgetCalculated ? calculatedDescription : undefined,
    disabled: isBudgetCalculated,
  });

  const [open, setOpen] = useState(true);
  const valueSetter = useKpiValueSetter();

  const onClose = useCallback(() => {
    setOpen(false);
    stopEditing();
  }, [stopEditing]);

  const onSave = useCallback(() => {
    const newValue = methods.getValues();
    valueSetter({ data, colDef, newValue } as TKpiValueSetterParams);
    onValueChange({
      ...value,
      actual: newValue.actual,
      budget: newValue.budget,
    } as IValuesBySection<unknown>);
    setOpen(false);
    stopEditing();
  }, [colDef, data, methods, onValueChange, stopEditing, value, valueSetter]);

  useFocusElement('kpi-editor', 'input', 200);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key == 'Escape') {
        onClose();
      } else if (e.key === 'Enter') {
        onSave();
      }
    },
    [onClose, onSave]
  );

  const formattedDate = `${data.displayName} (${getFormattedFiscalDate(
    formatISODateOnly(colDate),
    kpiPeriod,
    fye ?? 12
  )})`;

  if (!data || !value || !open) return <></>;

  return (
    <Popover open={true} anchorEl={params.eGridCell} onKeyDown={onKeyDown} disablePortal>
      <Paper style={{ minWidth: '20rem', padding: '1rem' }}>
        <Typography variant='body2' mb='0.5rem'>
          {formattedDate}
        </Typography>
        <FormProvider {...methods}>
          <Stack id='kpi-editor'>
            <FormFieldWithLabelFactory formField={actualField} />
            <FormFieldWithLabelFactory formField={budgetField} />
          </Stack>
          <Stack direction='row' spacing={1} justifyContent='start' mt='0.5rem'>
            <Button variant='contained' color='secondary' onClick={onSave}>
              Save
            </Button>
            <Button variant='outlined' color='secondary' onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </FormProvider>
      </Paper>
    </Popover>
  );
}
