import { GridOptions } from 'ag-grid-community';
import { styled } from '@mui/material/styles';

const BORDER_STYLE = '4px solid';

const CIRCLE_STYLE = `
content: '';
display: inline-block;
width: 0.5rem;
height: 0.5rem;
margin: 0.5rem;
margin-left: 0;
border-radius: 50%;`;

interface TableStyleWrapperProps {
  distanceFromTopInRems?: number;
}

export const TableStyleWrapper = styled('div')<TableStyleWrapperProps>`
  width: 99%;
  margin: 0 auto;
  position: sticky;
  top: ${({ distanceFromTopInRems }) => distanceFromTopInRems ?? 5}rem;

  &.Transposed {
    height: ${({ distanceFromTopInRems }) => `calc(100vh - ${distanceFromTopInRems ?? 5}rem)`};
  }
  & .ag-theme-alpine .ag-root-wrapper {
    background-color: transparent;
  }

  & .ag-cell:not(.ag-cell.ag-cell-last-left-pinned) {
    border-left: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    transform: translateX(-1px); // aligns cell borders with the header resize handle
    & img {
      max-width: 1.5rem;
    }
  }

  & .gc-grid .ag-header-cell {
    border-top: 1px solid transparent !important;
  }
  & .ag-header-row.ag-header-row-column-group {
    & .ag-header-cell-resize::after {
      background-color: transparent;
    }
  }
  .ag-header-group-cell-with-group {
    padding: 0;
  }
  .color-header-group {
    &:nth-of-type(3n) {
      border-bottom: ${BORDER_STYLE} ${({ theme }) => theme.colors.primary[40]};
      &::before {
        ${CIRCLE_STYLE};
        background-color: ${({ theme }) => theme.colors.primary[40]};
      }
    }
    &:nth-of-type(3n + 1) {
      border-bottom: ${BORDER_STYLE} ${({ theme }) => theme.colors.tertiary[40]};
      &::before {
        ${CIRCLE_STYLE};
        background-color: ${({ theme }) => theme.colors.tertiary[40]};
      }
    }
    &:nth-of-type(3n + 2) {
      border-bottom: ${BORDER_STYLE} ${({ theme }) => theme.colors.secondary[40]};
      &::before {
        ${CIRCLE_STYLE};
        background-color: ${({ theme }) => theme.colors.secondary[40]};
      }
    }
  }
`;

export const gridStyleOptions: GridOptions = {
  rowGroupPanelShow: 'never',
  groupHeaderHeight: 44,
};
