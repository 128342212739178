import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/system';
import { IBaseFieldProps } from '../Display/types';
import { IFormFieldSelectMeta } from '../../../view-models/form.view-model';
import { SelectType } from '../Display/FieldSelect';
import { ISelectMeta } from '../../../data-models/field3.data-model';
import { colors } from '../../../theme/colors';
import { formSelectInlineClassNames } from '../../../theme/component-styles';

export enum StatusColorName {
  Green = 'green',
  Amber = 'amber',
  Red = 'red',
}

export const StatusToColorMapping: Record<StatusColorName, string> = {
  green: colors.success[50],
  amber: colors.warning[40],
  red: colors.critical[60],
};

export function getColorStatusRendererMeta(): ISelectMeta<string> {
  return {
    values: Object.keys(StatusToColorMapping).map((colorName) => {
      return { value: colorName, displayName: colorName };
    }),
  };
}

export const ColorStatusBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'statusColor' })<{
  statusColor: string;
}>`
  background-color: ${(props) => props.statusColor};
  border-radius: 0.25rem;
  display: inline-block;
  align-items: center;
  height: 20px;
  width: 3rem;
`;

export function ColorStatus(props: IBaseFieldProps<IFormFieldSelectMeta<SelectType | string>>) {
  const { formField, formProps } = props;
  const { onChange, value } = formProps;
  const { variant, disabled } = formField ?? {};
  const className = variant === 'form-inline' ? `${formSelectInlineClassNames} form-select` : '';

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value);
  };

  const items = formField.rendererMeta?.values.map((option) => {
    const { value } = option;

    return (
      <MenuItem key={value} value={value}>
        <ColorStatusBox statusColor={StatusToColorMapping[value as StatusColorName]} />
      </MenuItem>
    );
  });
  if (formField.rendererMeta?.displayEmpty) {
    items?.push(
      <MenuItem key={'empty'} value={''}>
        <ColorStatusBox statusColor={colors.neutral[20]} />
      </MenuItem>
    );
  }

  return (
    <Select
      className={className}
      value={value ?? ''}
      onChange={handleChange}
      size={'small'}
      disabled={disabled}
      displayEmpty={formField.rendererMeta?.displayEmpty}
    >
      {items}
    </Select>
  );
}

export function getColorStatusMapping() {
  return StatusToColorMapping;
}
