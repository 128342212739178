import { FC } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { roundsByCompanyIdState } from '../state/FinanceState';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';
import { commonProcessCb } from '../../../components/AgTable/exportToExcelDefs';
import { ErrorOccurredMessage } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { Loader } from '../../../components/Loader/Loader';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { AgTable } from '../../../components/AgTable/AgTable';
import { defaultColDef } from '../common-grid-defs/defaultColDefs';
import { genericComparator } from '../../../util/comparators';
import { GridStyleWrapper } from '../common-grid-defs/GridStyleWrapper';
import { useRoundTableColDefs } from './useRoundTableColDefs';

export const RoundTable: FC = () => {
  const selectedCompanyId = useRecoilValue(selectedCompanyIdStateFinance);
  const roundsByCompanyLoadable = useRecoilValueLoadable(roundsByCompanyIdState(selectedCompanyId ?? -1));
  const roundsByCompany = roundsByCompanyLoadable.valueMaybe();
  const columnDefs = useRoundTableColDefs();

  if (roundsByCompanyLoadable.state === 'loading') return <Loader height='80%' />;
  if (roundsByCompanyLoadable.state === 'hasError') return <ErrorOccurredMessage />;

  return (
    <FadeInGridWrapper key={selectedCompanyId}>
      <GridStyleWrapper>
        <AgTable
          key={selectedCompanyId}
          columnDefs={columnDefs}
          rowData={roundsByCompany ?? []}
          defaultColDef={{ ...defaultColDef, minWidth: 150 }}
          defaultExcelExportParams={{ processCellCallback: commonProcessCb }}
          defaultCsvExportParams={{ processCellCallback: commonProcessCb }}
          autoGroupColumnDef={{ comparator: genericComparator }}
        />
      </GridStyleWrapper>
    </FadeInGridWrapper>
  );
};
