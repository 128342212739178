import { UseMutationResult, useMutation, useQuery } from '@tanstack/react-query';
import { IDealDataModel } from '../../data-models/deal.data-model';
import { queryClient } from '../MaggieQueryClient';
import { DEALS, DEAL_BOARD_CONFIG, fetchDealBoardConfig, updateDeal } from '../queries/MaggieDealQueries';

export interface MutateDealProps {
  payload: IDealDataModel;
  stageIds: number[];
}

export function useDealBoardConfig() {
  return useQuery(DEAL_BOARD_CONFIG, () => fetchDealBoardConfig(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

export function useUpdateDeal(): UseMutationResult<IDealDataModel, Error, MutateDealProps> {
  return useMutation((data: MutateDealProps) => updateDeal(data.payload), {
    onMutate: async (data) => {
      const { payload: newDeal, stageIds } = data;
      await queryClient.cancelQueries([DEALS, { stageIds }]);
      const prevDeals = queryClient.getQueryData<IDealDataModel[]>([DEALS, { stageIds }]);

      if (prevDeals) {
        const prevDealsSansNewDeal = prevDeals.filter((deal) => deal.id !== newDeal.id);
        queryClient.setQueriesData<IDealDataModel[]>(
          [DEALS, { stageIds }],
          [...prevDealsSansNewDeal, newDeal]
        );
      }
      return { prevDeals };
    },
    onError: (_err, vars, ctx) => {
      if (ctx?.prevDeals) {
        const { stageIds } = vars;
        queryClient.setQueriesData<IDealDataModel[]>([DEALS, { stageIds }], ctx.prevDeals);
      }
    },
    onSuccess: (_data, vars) => {
      const { stageIds } = vars;
      queryClient.invalidateQueries([DEALS, { stageIds }]);
    },
    onSettled: (_data, _err, vars) => {
      const { stageIds } = vars;
      queryClient.invalidateQueries([DEALS, { stageIds }]);
    },
  });
}
