import { FC, useMemo } from 'react';
import { NavLink, createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../constants/RouteNameMapping';
import { MaggieFeatureFlags } from '../../util/feature-flags';
import { SelectedCompanyParam } from './finance-constants';

const DetailsRoute = `/${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}/${ROUTES.FINANCE_DETAILS}`;

export const FinanceTabs: FC = () => {
  const { pathname } = useLocation();
  const search = useSearchParams()[0];
  const companyId = search.get(SelectedCompanyParam);
  const companySearchParam = createSearchParams({ [SelectedCompanyParam]: companyId ?? '' }).toString();
  const selectedSection = useMemo(() => getDetailsSubsection(pathname), [pathname]);
  const { showFinanceSecurity, showTransactionV3AsPrimary } = useFlags<MaggieFeatureFlags>();
  const showTabs = useShowFinanceTabs();

  const tabs = useMemo(() => {
    const result = [
      showTransactionV3AsPrimary
        ? {
            path: ROUTES.FINANCE_TRANSACTIONS_3,
            title: ROUTE_TITLE_MAPPING[ROUTES.FINANCE_TRANSACTIONS_3],
          }
        : {
            path: ROUTES.FINANCE_TRANSACTIONS,
            title: ROUTE_TITLE_MAPPING[ROUTES.FINANCE_TRANSACTIONS],
          },
      {
        path: ROUTES.FINANCE_ROUNDS,
        title: ROUTE_TITLE_MAPPING[ROUTES.FINANCE_ROUNDS],
      },
    ];
    if (showFinanceSecurity) {
      result.unshift({ path: ROUTES.FINANCE_SECURITY, title: ROUTE_TITLE_MAPPING[ROUTES.FINANCE_SECURITY] });
    }

    return result;
  }, [showFinanceSecurity, showTransactionV3AsPrimary]);

  if (!showTabs) return null;

  return (
    <Tabs variant='scrollable' scrollButtons='auto' value={selectedSection} style={{ marginBottom: '1rem' }}>
      {tabs.map((t) => (
        <Tab
          component={NavLink}
          key={t.path}
          value={t.path}
          label={t.title}
          style={{ paddingLeft: 0, marginRight: '1rem' }}
          to={{ pathname: `${DetailsRoute}/${t.path}`, search: companySearchParam }}
        />
      ))}
    </Tabs>
  );
};

export function useShowFinanceTabs() {
  const { pathname } = useLocation();
  return pathname.includes(`/${ROUTES.FINANCE_DETAILS}/`);
}

export function getFinanceSubsection(path: string): string | undefined {
  return path.split(`/${ROUTES.FINANCE2}/`)?.at(1);
}

export function getDetailsSubsection(path: string): string | undefined {
  return path.split(`/${ROUTES.FINANCE_DETAILS}/`)?.at(1)?.split('/')?.at(0);
}
