import * as yup from 'yup';
import { positiveNumMsg, requiredMsg, typeMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { pricePerShareField } from '../../../../../../../schemas/common-schema-defs';

export function warrantExerciseFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    pricePerShare: pricePerShareField()
      .typeError(typeMsg('Converted PPS', 'number'))
      .required('Converted PPS')
      .moreThan(-Number.MIN_VALUE, positiveNumMsg('Converted PPS')),
    currencyid: transactionCurrencyIdField(),
    exercisedWarrantsNo: yup
      .number()
      .typeError(typeMsg('Number of Shares Converted', 'number'))
      .required(requiredMsg('Number of Shares Converted'))
      .positive(positiveNumMsg('Number of Shares Converted')),
    warrantExercisePrice: yup.number(),
    warrantVested: yup.boolean().required(requiredMsg('Vested')),
    security: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function warrantExerciseFormSchema() {
  return yup.object().shape(warrantExerciseFields());
}
