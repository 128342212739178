export enum PermissionKey {
  canAssignUserToTrackedDeal = 'fd.list.track.assign-user',
  canEditDeal = 'fd.deal.edit',
  canEditList = 'fd.list.edit',
  canEditPortfolioCompany = 'fd.portfolio.company.edit',
  canEditTransaction = 'fd.transactions.edit',
  canReadDataCollection = 'fd.data-collection.read',
  canSubmitCollectionSubmit = 'fd.data-collection.submit',
  canViewAppAdminPanel = 'fd.admin-panel.view',
  canViewDeal = 'fd.deal.read',
  canViewList = 'fd.list.read',
  canViewMetrics = 'fd.metrics.read',
  canViewTransaction = 'fd.transactions.read',
  canViewUserAdminPanel = 'fd.user-settings.view',
  canWriteDataCollection = 'fd.data-collection.write',
  hasAnalysisModule = 'fd.analysis.*',
  hasDataCollectionModule = 'fd.data-collection.*',
  hasMappingsModule = 'fd.mappings.*',
  hasIntegrationsModule = 'fd.integrations.*',
}

export enum RoleKey {
  appAdmin = 'fdAppAdmin',
  itAdmin = 'fdITAdmin',
  externalUser = 'fdExternalUser',
  serviceProvider = 'fdServiceProvider',
  userAdmin = 'fdUserAdmin',
}
