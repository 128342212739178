import { useCallback } from 'react';
import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { merge } from 'lodash-es';
import { useRecoilValue } from 'recoil';
import { YAXisOption } from 'echarts/types/dist/shared';
import {
  getDateRange,
  KpiSeriesBySectionByPeriod,
  useGetFinancialsBySectionAndPeriod,
} from '../../../hooks/useLastThreePeriodData';
import { IField } from '../../../../../data-models/field2.data-model';
import { KpiPeriod, KpiSection } from '../../../../../data-models/company-financials.data-model';
import { FormatterService } from '../../../../../util/formatter-service';
import { EChartsBarOption } from '../../../../../components/Charts/Barchart/BarChart';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import {
  selectedMetricsDateCPState,
  selectedFrequencyPerfState,
} from '../../../state/CompanyFinancialsDateState';
import {
  IChartProps,
  MixedChart,
  MixedSeriesOption,
} from '../../../../../components/Charts/MixedChart/MixedChart';
import { AxisTickTextStyle } from '../../../../../components/Charts/chart-constants';
import { companyState } from '../../../../../services/state/CompanyState';
import { getFormattedFiscalDate } from '../../../utils/financialUtils';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import { createFormatterDataModel } from '../../../../../data-models/formatter.data-model';
import { getDatesInRange, kpisAsSeries } from './PerfBarChart';

export interface IChartFieldParams {
  field: IField<unknown>;
  kpiSection: KpiSection;
  options: BarSeriesOption | LineSeriesOption;
}

export interface IViewConfig {
  date?: Date;
  frequency?: KpiPeriod;
  periodRange?: number;
}
interface IMixedPerfChartProps {
  title: string;
  fields: IChartFieldParams[];
  viewConfig?: IViewConfig;
  staticOptions?: IChartProps['staticOptions'];
}
export function MixedPerfChart({ fields, viewConfig, staticOptions }: IMixedPerfChartProps) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const selectedDate = useRecoilValue(selectedMetricsDateCPState(companyId))!;
  const selectedPeriod = useRecoilValue(selectedFrequencyPerfState(companyId));
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;
  const getChartData = useGetMixedPerfChartData();
  const { date = selectedDate, frequency: period = selectedPeriod, periodRange = 4 } = viewConfig ?? {};
  const { chartData, timeCategories, yAxis } = getChartData(fields, date, period, periodRange, fye);

  return (
    <MixedChart
      xCategoryData={timeCategories}
      yValueData={chartData}
      yAxis={yAxis}
      staticOptions={staticOptions}
    />
  );
}

export function useGetMixedPerfChartData() {
  const getFinancials = useGetFinancialsBySectionAndPeriod();

  return useCallback(
    function (
      fields: IChartFieldParams[],
      selectedDate: Date,
      selectedPeriod: KpiPeriod,
      range: number,
      fye: number
    ) {
      const { from, to } = getDateRange(selectedPeriod, selectedDate, range);
      const displayedDates = getDatesInRange(selectedPeriod, selectedDate, range);
      const timeCategories = displayedDates.map((date) =>
        getFormattedFiscalDate(formatISODateOnly(date), selectedPeriod, fye ?? 12)
      );
      const financialsForRange = getFinancials(from, to);
      const formatters = new Set(
        fields.map(({ field }) => FormatterService.get().getFormatterForField(field))
      );
      let arrayFormatters = Array.from(formatters);

      if (arrayFormatters.length < 2) {
        console.warn('A formatter is missing, using string');
        while (arrayFormatters.length < 2) {
          arrayFormatters.push(
            FormatterService.get().getFormatterForModel(createFormatterDataModel({ type: 'string' }))
          );
        }
      }
      if (arrayFormatters.length > 2) {
        console.warn('Cannot have more than 2 Y axes.');
        arrayFormatters = arrayFormatters.slice(0, 2);
      }
      const yAxis = arrayFormatters.map((formatter) => {
        return {
          ...AxisTickTextStyle,
          type: 'value',
          axisLabel: {
            formatter: (val: number) => {
              return formatter(Number(val));
            },
          },
        };
      }) as YAXisOption[];
      const chartData = fields.map(({ field, kpiSection, options }) => {
        const formatter = FormatterService.get().getFormatterForField(field);

        const formatterOptions: EChartsBarOption = {
          tooltip: {
            valueFormatter: (value) => {
              if (value !== 0 && !value) {
                return '-';
              }
              return formatter?.(Number(value)) ?? '';
            },
          },
        };
        return getSeries({
          field,
          selectedPeriod,
          dataSeries: financialsForRange,
          fye,
          kpiSection,
          ...merge({ ...options }, { ...formatterOptions }),
        });
      });

      return { timeCategories, chartData, yAxis };
    },
    [getFinancials]
  );
}

export type SeriesParams = {
  field: IField<unknown>;
  selectedPeriod: KpiPeriod;
  dataSeries: KpiSeriesBySectionByPeriod;
  fye: number;
  kpiSection: KpiSection;
};

export function getSeries(params: SeriesParams & MixedSeriesOption): MixedSeriesOption {
  const { field, selectedPeriod, dataSeries, fye, kpiSection, ...seriesOptions } = params;
  const series: LineSeriesOption | BarSeriesOption = {
    data: kpisAsSeries(
      dataSeries.get(kpiSection)?.get(selectedPeriod)?.get(field.displayName) ?? [],
      selectedPeriod,
      fye
    ),
    ...seriesOptions,
  };

  return series as MixedSeriesOption;
}
