import { debounce } from 'lodash-es';
import { MaggieHttp } from '../MaggieHttp';
import { IPreferencesDataModel } from '../../data-models/preferences.data-model';
import { getErrorMessage } from '../queryHelpers';
import { IPreferencesV3DataModel, PreferenceScope } from '../../data-models/preferences-v3.data-model';

export async function getAllPreferences() {
  const userPrefs = await getPreferencesV3(PreferenceScope.USER);
  const orgPrefs = await getOrganizationPreferences();

  return [...userPrefs, ...orgPrefs];
}

export async function getOrganizationPreferences() {
  const { data } = await MaggieHttp.maggie.get<IPreferencesV3DataModel<unknown>[]>(`/preferences/v3`, {
    params: {
      scope: PreferenceScope.ORGANIZATION,
    },
  });

  return data;
}

export async function getPreferencesV3(scope: PreferenceScope) {
  const { data } = await MaggieHttp.maggie.get<IPreferencesV3DataModel<unknown>[]>(
    `/preferences/preferenceKey/all`,
    {
      params: {
        scope,
      },
    }
  );

  return data.map((pref) => {
    return { ...pref, scope };
  });
}

export async function updatePreferencesV3<T>(
  payload: Pick<IPreferencesV3DataModel<T>, 'scope' | 'preferenceKey' | 'value'>
) {
  if (!payload.preferenceKey) {
    throw new Error('Module is required');
  }

  const { data } = await MaggieHttp.maggie.put<T, IPreferencesV3DataModel<unknown>>(
    `/preferences/v3`,
    payload.value,
    {
      params: {
        scope: payload.scope,
        preferenceKey: payload.preferenceKey,
      },
    }
  );

  return data;
}

export async function getPreferences() {
  const { data } = await MaggieHttp.maggie.get<IPreferencesDataModel>(`/preferences`);
  return data;
}

export const updatePreferencesDebounced = debounce(updatePreferences, 500);

export async function updatePreferences(payload: IPreferencesDataModel) {
  try {
    return MaggieHttp.maggie.put<IPreferencesDataModel, IPreferencesDataModel>(`/preferences`, payload);
  } catch (err) {
    console.error(getErrorMessage(err, 'Failed to update preferences'));
  }
}
