import { useRecoilValue } from 'recoil';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { selectedFundStateFP } from '../state/FPState';
import { Breakpoint, ContainerName } from '../../../constants/styles';
import { FPDigestPanel } from './FPDigestPanel';
import { FundMetricsPanel } from './FundMetricsPanel';
import { ContributionsDistributions } from './ContributionsDistributions';

const DigestAndMetricsSection = css`
  display: grid;
  gap: 1rem;
  @container ${ContainerName.fundProfilesContainer} (width >= ${Breakpoint.profilePanels}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export function FPSummary() {
  const fund = useRecoilValue(selectedFundStateFP);

  if (!fund) return null;
  return (
    <Stack display={'grid'} gap={'1rem'}>
      <section css={DigestAndMetricsSection}>
        <FPDigestPanel />
        <FundMetricsPanel />
      </section>
      <ContributionsDistributions />
    </Stack>
  );
}
