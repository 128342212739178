import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ColDef, GridApi, GridReadyEvent, NestedFieldPaths, ValueGetterParams } from 'ag-grid-community';
import { allInvestmentRoundsMapState, forceAllTransactionsByDateUpdate } from '../state/FinanceState';
import { AbstractTransaction, commonTransactionGridSchema } from '../../../schemas/AbstractTransactionSchema';
import { eqBuyGridSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Equity/EquityBuySchema';
import { useSchemaToGrid } from '../../../util/schema-utils';
import { defaultColDef } from '../common-grid-defs/defaultColDefs';
import { genericComparator } from '../../../util/comparators';
import { AgTable } from '../../../components/AgTable/AgTable';
import { SecurityTableStyleWrapper } from '../Security/SecurityTableStyleWrapper';

import { getMenuColDef } from '../Grids/useTransactionColDefs';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { withOverrides } from '../../../util/ag-grid-utils';
import { useExtendedExportSettings } from '../../../components/AgTable/exportToExcelDefs';
import { roundsMapState } from '../../../services/state/AppConfigState';
import { RoundFromInvestmentCellRenderer } from '../../../components/AgTable/cell-renderers/RoundCellRenderer';
import { genericKeyCreator } from '../common-grid-defs/commonColDefs';
import { useAllTransactionsGridFilters } from './useAllTransactionsGridFilters';
import { IExtendedTransactionDataModel } from './useAllTransactionsData';

const customDefs: Record<string, ColDef<IExtendedTransactionDataModel>> = {
  transactionDate: {
    sortable: true,
    initialSort: 'desc',
  },
  fundId: {
    menuTabs: [],
  },
  transactionType: {
    menuTabs: [],
  },
};

export interface IAllTransactionsGridProps {
  transactions: ITransactionDataModel[];
  fundFilter?: number[];
  transactionTypeFilter?: string[];
}
export function AllTransactionsGrid({ transactions }: IAllTransactionsGridProps) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { doesExternalFilterPass, isExternalFilterPresent } = useAllTransactionsGridFilters(gridApi);
  const allTransactionsKey = useRecoilValue(forceAllTransactionsByDateUpdate);
  const schemaToGrid = useSchemaToGrid();
  const investmentsById = useRecoilValue(allInvestmentRoundsMapState);
  const roundsById = useRecoilValue(roundsMapState);

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  const { exportParams } = useExtendedExportSettings(
    new Set(['companyId', 'fundId', 'transactionType', 'transactionTypeId', 'roundId']),
    'All Transactions'
  );

  const initialGridSettings = useMemo(() => {
    const defs = schemaToGrid(eqBuyGridSchema().concat(commonTransactionGridSchema()), [
      'companyId',
      'transactionDate',
      'fundId',
      'transactionType' as NestedFieldPaths<AbstractTransaction>,
      'transactionTypeId',
      'investmentName',
      'status',
    ]);
    defs.push(getMenuColDef());

    const columnDefs = withOverrides(defs, customDefs);
    columnDefs.splice(5, 0, {
      headerName: 'Stage',
      valueGetter: (params: ValueGetterParams) => {
        const investment = investmentsById.get(params.data?.investmentRoundId ?? -1);
        if (!investment) return '';
        const round = roundsById.get(investment.roundId ?? -1);
        return round?.name ?? '';
      },
      cellRenderer: RoundFromInvestmentCellRenderer,
      keyCreator: genericKeyCreator,
      enableRowGroup: true,
    });

    return {
      columnDefs,
      defaultColDefs: { ...defaultColDef, minWidth: 150 },
      autoGroupColumnDef: { comparator: genericComparator },
    };
  }, [investmentsById, roundsById, schemaToGrid]);

  return (
    <>
      <SecurityTableStyleWrapper key={allTransactionsKey} style={{ paddingTop: '1rem', height: '72vh' }}>
        {transactions && (
          <FadeInGridWrapper>
            <AgTable
              columnDefs={initialGridSettings.columnDefs}
              rowData={transactions}
              defaultColDef={initialGridSettings.defaultColDefs}
              autoGroupColumnDef={initialGridSettings.autoGroupColumnDef}
              onGridReady={onGridReady}
              doesExternalFilterPass={doesExternalFilterPass}
              isExternalFilterPresent={isExternalFilterPresent}
              defaultExcelExportParams={exportParams}
              defaultCsvExportParams={exportParams}
            />
          </FadeInGridWrapper>
        )}
      </SecurityTableStyleWrapper>
    </>
  );
}
