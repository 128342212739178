import { styled } from '@mui/material/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PopupMenu } from '../../../../../components/PopupMenu/PopupMenu';
import { IKpiSelectItem, KPIoptionsState, selectedKpisItemState } from '../../../state/ProfileViewSettings';
import { SelectItem } from '../../../../../types';
import { selectedCompanyIdProfile } from '../../../state/UIState';

const Wrapper2 = styled('div')`
  display: flex;
  position: relative;
  margin-left: auto;
`;

export const AddStatistic2 = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const [selectedStatistics, setSelectedStatistics] = useRecoilState(selectedKpisItemState(companyId));
  const KPIoptions = useRecoilValue(KPIoptionsState);

  const handleChange2 = (selected: SelectItem[]) => {
    setSelectedStatistics(selected as IKpiSelectItem[]);
  };

  return (
    <Wrapper2>
      <PopupMenu
        items={KPIoptions}
        onChange={handleChange2}
        selectedItems={selectedStatistics}
        label={'Select KPIs'}
        groupable
        searchable
        searchPlaceholder='Search for KPI name'
      />
    </Wrapper2>
  );
};
