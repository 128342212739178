import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { ZINDEX } from '../../../constants/styles';
import { AutoHideDuration, ToastWrapper } from '../../../components/ToastMessage/ToastMessage';
import {
  IBulkUploadStatusMessageDataModel,
  UploadStatus,
} from '../../../data-models/bulk-upload-status.data-model';
import { theme } from '../../../theme';

const gradients: Record<UploadStatus, string> = {
  [UploadStatus.uploading]: `linear-gradient(270deg, ${theme.colors.primary[10]}  0%, ${theme.colors.success[50]} 40.62%, ${theme.colors.secondary[40]} 82.64%, ${theme.colors.primary[40]} 100%)`,

  [UploadStatus.success]: `linear-gradient(270deg, ${theme.colors.success[30]} 0%, ${theme.colors.tertiary[50]} 40.62%, ${theme.colors.tertiary[60]} 82.64%,${theme.colors.success[70]} 100%)`,

  [UploadStatus.error]: `linear-gradient(270deg, ${theme.colors.warning[30]} 0%, ${theme.colors.critical[30]} 40.62%, ${theme.colors.critical[50]} 82.64%, ${theme.colors.critical[60]} 100%)`,
};

const StyledToastWrapper = styled(ToastWrapper)`
  padding: 1.25rem 1rem 1.25rem 1.5rem;
  background: ${({ theme }) => theme.colors.primary[0]};
  position: relative;
`;

const StyledBorder = styled('div')<{ status?: UploadStatus }>`
  background: ${({ status }) =>
    status === UploadStatus.uploading
      ? gradients[UploadStatus.uploading]
      : status === UploadStatus.error
      ? gradients[UploadStatus.error]
      : gradients[UploadStatus.success]};
  height: 0.25rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const StyledStatus = styled(Typography)`
  text-transform: uppercase;
`;

const StyledFileNameContainer = styled('div')`
  border: thin solid ${({ theme }) => theme.colors.neutral[30]};
  border-radius: 4px;
  margin: 0.25rem 0.5rem 0.25rem 0;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

function TransitionLeft(props: SlideProps) {
  return <Slide {...props} direction={'left'} />;
}

export interface IBulkUploadStatusProps extends IBulkUploadStatusMessageDataModel {
  open: boolean;
  onClose: () => void;
  onRetry?: () => void;
}

export const BulkUploadStatus: FC<IBulkUploadStatusProps> = ({
  open,
  onClose,
  fileName,
  status,
  note,
  onRetry,
}) => {
  const { colors } = useTheme();

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={status === UploadStatus.uploading ? null : AutoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={TransitionLeft}
      style={{ zIndex: ZINDEX.UPLOAD_MODAL + 1 }}
    >
      <StyledToastWrapper>
        <Stack direction='row' justifyContent={'space-between'}>
          <StyledStatus>{`uploading ${status === UploadStatus.uploading ? '...' : ''}`}</StyledStatus>
          <IconButton onClick={onClose} aria-label='close'>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Stack>

        <StyledFileNameContainer>
          <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} gap='0.5rem'>
            <UploadFileIcon color='disabled' />
            <Typography variant='body2' color='text.primary'>
              {fileName}
            </Typography>
          </Stack>
          {status === UploadStatus.uploading && <CircularProgress color={'secondary'} size={'1.5rem'} />}
          {status === UploadStatus.error && <WarningAmberIcon htmlColor={colors.critical[50]} />}
          {status === UploadStatus.success && <CheckCircleIcon htmlColor={colors.success[50]} />}
        </StyledFileNameContainer>
        <Collapse in={status !== UploadStatus.uploading}>
          <Typography variant='body2' color='text.secondary' sx={{ py: '0.5rem' }}>
            {status === UploadStatus.success
              ? 'File was successfully uploaded!'
              : status === UploadStatus.error
              ? note ?? 'There was an error processing. Please try again.'
              : ''}
          </Typography>
        </Collapse>
        {status === UploadStatus.error && (
          <Button variant='contained' color='secondary' onClick={onRetry}>
            Retry
          </Button>
        )}
        <StyledBorder status={status} />
      </StyledToastWrapper>
    </Snackbar>
  );
};
