import { FC, useCallback, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Box, Button, Stack } from '@mui/material';
import { ColDef, GridApi, GridReadyEvent, SizeColumnsToContentStrategy } from 'ag-grid-community';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';

import { AgTable } from '../../../components/AgTable/AgTable';
import { pluralizeWord } from '../../../util/stringUtils';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { currentStepState } from './CapTableFormState';
import { FormCard } from './commonStyledComponents';
import {
  EditableGridDefaultColDefs,
  EditableGridOptions,
  useEditableGridDefinitions,
} from './EditableGridDefinitions';
import { useCancelButtonProps } from './CapTableForm';
import { CaptableGridContainer } from './SummaryGrid/CaptableGridContainer';
import { useInvestmentRowData } from './useInvestmentRowData';

export interface IAddInvestmentGridProps<T> {
  setStateToRows: (rows: T[]) => void;
  initialRowData: T[];
  colDefs: ColDef<T>[];
  HelpInfo?: FC;
}

const autoSizeStrategy: SizeColumnsToContentStrategy = { type: 'fitCellContents', skipHeader: true };

export function AddInvestmentsGrid<T>({
  setStateToRows,
  initialRowData,
  colDefs,
  HelpInfo,
}: IAddInvestmentGridProps<T>) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const setCurrentStep = useSetRecoilState(currentStepState);
  const { rowData, nSelectedRows, onRowSelected, onAddRow, onDeleteRows } = useInvestmentRowData(
    initialRowData,
    gridApi
  );

  const modifiedColumnDefs = useMemo(() => {
    if (colDefs.length > 0) {
      colDefs.at(0)!.minWidth = 300;
      colDefs.at(0)!.pinned = 'left';
    }
    return colDefs;
  }, [colDefs]);

  const saveRows = useCallback(() => {
    if (!gridApi) return Promise.resolve(false);

    const rows: T[] = [];
    gridApi.forEachNode((node) => rows.push(node.data));

    setStateToRows(rows);

    return Promise.resolve(true);
  }, [gridApi, setStateToRows]);

  const cancelButtonProps = useCancelButtonProps();

  const onNext = useCallback(() => {
    return saveRows();
  }, [saveRows]);

  const onPrevious = useCallback(() => {
    saveRows();
    setCurrentStep((prev) => prev - 1);
  }, [saveRows, setCurrentStep]);

  const { processDataFromClipboard } = useEditableGridDefinitions();

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      setGridApi(params.api);
    },
    [setGridApi]
  );

  return (
    <FormCard style={{ paddingRight: '1rem', minHeight: 'fit-content' }}>
      <Box minHeight={'40vh'} mb='1rem'>
        <Stack direction={'row'} justifyContent={'space-between'} mb='1rem'>
          {HelpInfo && <HelpInfo />}

          <Stack direction={'row'} gap='0.5rem'>
            {nSelectedRows > 0 && (
              <Button variant='outlined' color='secondary' onClick={onDeleteRows}>
                {`Delete ${pluralizeWord('Row', nSelectedRows)}`}
              </Button>
            )}
            <Button variant='contained' color='secondary' onClick={onAddRow}>
              Add Row
            </Button>
          </Stack>
        </Stack>
        <CaptableGridContainer style={{ width: '100%', height: '100%' }}>
          <FadeInGridWrapper>
            <AgTable
              onGridReady={onGridReady}
              columnDefs={modifiedColumnDefs}
              rowData={rowData}
              rowGroupPanelShow='never'
              groupIncludeTotalFooter={false}
              gridOptions={EditableGridOptions}
              defaultColDef={EditableGridDefaultColDefs}
              processDataFromClipboard={processDataFromClipboard}
              rowSelection='multiple'
              suppressRowClickSelection
              onRowSelected={onRowSelected}
              autoSizeStrategy={autoSizeStrategy}
            />
          </FadeInGridWrapper>
        </CaptableGridContainer>
      </Box>
      <StepperFormButtons
        stepState={currentStepState}
        stepIsValid={onNext}
        handleGoBack={onPrevious}
        secondaryActionProps={cancelButtonProps}
        style={{ position: 'unset' }}
      />
    </FormCard>
  );
}
