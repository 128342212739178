import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { LineChart } from '../../../../components/Charts/LineChart/LineChart';
import { CHART_COMPONENTS } from '../../../../types';
import { IInvestmentDataModel } from '../../../../data-models/investment.data-model';
import { sortByQuarters, sortByYears } from '../../hooks/useAmountOfDealsOverTime';
import { selectedViewDatePF } from '../../state/PageState';
import { QuartersBuilder } from '../../services/QuartersBuilder';
import { mapLineChartData } from '../../../../components/Charts/LineChart/mapLineChartData';
import { IPortfolioChartProps } from './ChartComponent';

export const AmountOfDealsOverTimeChartRoundTracker: FC<IPortfolioChartProps<IInvestmentDataModel>> = (
  props
) => {
  const date = useRecoilValue(selectedViewDatePF);
  const data = computeChartData(date, props.filteredMetrics);

  return (
    <LineChart
      data={data}
      id={CHART_COMPONENTS.AMOUNT_OF_DEALS_OVER_TIME_RT}
      showLegend={false}
      title={'Participating Deals'}
      width={640}
      yAxisLabelFormatter={(value) => value as string}
    />
  );
};

function computeChartData(date: Date, metrics: IInvestmentDataModel[]) {
  const previous3Years = new Date(date);
  previous3Years.setFullYear(previous3Years.getFullYear() - 3);

  const metricsByQuarters = metrics
    .filter((metric) => {
      return (
        !!metric.gcAmount &&
        metric.gcAmount > 0 &&
        new Date(metric.investmentDate).getTime() >= previous3Years.getTime()
      );
    })
    .map((metric) => {
      const investmentDate = new Date(metric.investmentDate);
      const quarter = Math.floor(investmentDate.getMonth() / 3);

      return `Q${quarter + 1} ${investmentDate.getFullYear()}`;
    });

  const last12Quarters = QuartersBuilder.last12Quarters(date || new Date()).reduce(
    (acc, { label }) => ({ ...acc, [label]: 0 }),
    {} as Record<string, number>
  );

  metricsByQuarters.forEach((quarter) => {
    if (quarter in last12Quarters) last12Quarters[quarter] += 1;
  });

  const mappedQuarters = Object.entries(last12Quarters).map(([period, value]) => ({
    period,
    'Amount of Deals': value,
  }));

  mappedQuarters.sort(({ period: period1 }, { period: period2 }) => sortByQuarters(period1, period2));
  mappedQuarters.sort(({ period: period1 }, { period: period2 }) => sortByYears(period1, period2));

  return mapLineChartData(
    mappedQuarters.map((quarter, index) => {
      if (index === 0) return { ...quarter };
      let sum = quarter['Amount of Deals'];

      for (let i = 0; i < index; i++) sum += mappedQuarters[i]['Amount of Deals'];
      return {
        ...quarter,
        'Amount of Deals': sum,
      };
    })
  );
}
