import { FieldPath } from 'react-hook-form';
import { atom, selector } from 'recoil';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { CompanyFundMetricsDataModel } from '../../../schemas/CompanyFundMetrics.schema';
import { PreferencesServiceV3 } from '../../../util/preferences-service-v3';
import {
  PanelPreferences,
  PreferenceKey,
  PreferenceScope,
} from '../../../data-models/preferences-v3.data-model';

export const selectedDetailsFieldsOrgState = atom<FieldPath<ICompanyDataModel>[] | undefined>({
  key: 'selectedDetailsFieldsOrgState',
  default: selector({
    key: 'selectedDetailsFieldsOrgState/default',
    get: () => {
      const profilePrefs = PreferencesServiceV3.get().getPreferences<PanelPreferences>(
        PreferenceKey.companySummaryDetails,
        PreferenceScope.ORGANIZATION
      );

      return (profilePrefs?.selectedFields ?? []) as FieldPath<ICompanyDataModel>[];
    },
  }),
});

export const selectedDetailsFieldsState = atom<FieldPath<ICompanyDataModel>[] | undefined>({
  key: 'selectedDetailsFieldsState',
  default: selector({
    key: 'selectedDetailsFieldsState/default',
    get: () => {
      const profilePrefs = PreferencesServiceV3.get().getPreferences<PanelPreferences>(
        PreferenceKey.companySummaryDetails,
        PreferenceScope.ORGANIZATION
      );

      return (profilePrefs?.selectedFields ?? []) as FieldPath<ICompanyDataModel>[];
    },
  }),
});

export const selectedMetricsFieldsOrgState = atom<FieldPath<CompanyFundMetricsDataModel>[] | undefined>({
  key: 'selectedMetricsFieldsOrgState',
  default: selector({
    key: 'selectedMetricsFieldsOrgState/default',
    get: () => {
      const profilePrefs = PreferencesServiceV3.get().getPreferences<PanelPreferences>(
        PreferenceKey.companySummaryInvestments,
        PreferenceScope.ORGANIZATION
      );

      return (profilePrefs?.selectedFields ?? []) as FieldPath<CompanyFundMetricsDataModel>[];
    },
  }),
});
