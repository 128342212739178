import { styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useCurrentUser } from '../../../services/hooks/useCurrentUser';
import { isDrawerOpenState, selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { companyState } from '../../../services/state/CompanyState';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { useCompanyActions } from '../../CompanyProfiles/Summary/hooks/useCompanyActions';
import { ToggleIconButtonWithTooltip } from '../../../components/ToggleIconButton/ToggleIconButton';
import { TrackButton } from '../../CompanyProfiles/Summary/components/ActionButtons/TrackButton';
import { DealActionsBtn } from '../../CompanyProfiles/Summary/components/ActionButtons/DealActionsBtn';
import { TrackCompanyModal } from '../../CompanyProfiles/forms/TrackCompanyModal';

const Wrapper = styled('div')`
  display: flex;
  gap: 0.6rem;
  align-items: center;
`;

export function HeaderActionButtons() {
  const { id: currentUserId } = useCurrentUser();
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const { showActivityDrawer } = useFlags<MaggieFeatureFlags>();
  const [isDrawerOpen, setIsOpenDrawer] = useRecoilState(isDrawerOpenState);
  const { pushErrorToast } = useToastMessageState();
  const { handleUntrackCompany } = useCompanyActions();
  const [showTrackModal, setShowTrackModal] = useState(false);
  const onCloseTrackModel = () => setShowTrackModal(false);

  const isFollowed = useMemo(() => {
    if (!company?.followers || !company.followers.length || !currentUserId) return false;
    return company.followers.includes(currentUserId);
  }, [currentUserId, company?.followers]);

  const onChangeTrackStatus = useCallback(async () => {
    if (!currentUserId || !company) {
      pushErrorToast({ message: 'Failed to update company' });
      return;
    }

    if (isFollowed) {
      await handleUntrackCompany(company);
    } else {
      setShowTrackModal(true);
    }
  }, [currentUserId, company, isFollowed, pushErrorToast, handleUntrackCompany]);

  if (!company) {
    return null;
  }

  return (
    <Wrapper>
      {showActivityDrawer ? (
        <ToggleIconButtonWithTooltip
          value='open-drawer'
          selected={isDrawerOpen}
          onChange={() => {
            setIsOpenDrawer((isOpen) => !isOpen);
          }}
          tooltipText={isDrawerOpen ? 'Hide activity drawer' : 'Show activity drawer'}
        >
          <EditNoteIcon color={'secondary'} />
        </ToggleIconButtonWithTooltip>
      ) : null}
      <TrackButton isTracked={isFollowed} onClick={onChangeTrackStatus} key='track' />
      <DealActionsBtn />
      <TrackCompanyModal
        open={showTrackModal}
        onSubmit={onCloseTrackModel}
        onClose={onCloseTrackModel}
        company={company}
      />
    </Wrapper>
  );
}
