import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Stack } from '@mui/material';
import { fundOptionsState } from '../../PortfolioOverview/state/FundFilterState';
import { DateRangeSelector } from '../../Finance/form-components/DateRangeSelector';
import { FormatterService } from '../../../util/formatter-service';
import { DateFormattersConfig } from '../../../util/formatters/DateFormatters';
import { DefaultShortcuts } from '../../../components/DatePicker/RangeShortcuts';
import { PopupFilter } from '../../../components/PopupFilter';
import {
  fundFilterState,
  transactionTypeFilterState,
  transactionTypeOptionsState,
  transactionsDateFilterState,
} from './AllTransactionsFilterState';

export function AllTransactionFilters() {
  const [selectedFunds, setSelectedFunds] = useRecoilState(fundFilterState);
  const fundOptions = useRecoilValue(fundOptionsState);
  const transactionTypeOptions = useRecoilValue(transactionTypeOptionsState);
  const [selectedTypes, setSelectedTypes] = useRecoilState(transactionTypeFilterState);

  return (
    <Stack
      display={'grid'}
      gridTemplateColumns='auto auto 1fr'
      gap={'1rem'}
      alignItems={'center'}
      justifyContent={'start'}
      width={'100%'}
    >
      <PopupFilter
        selected={selectedFunds}
        setSelected={setSelectedFunds}
        options={fundOptions}
        labels={{ item: 'Fund' }}
        groupable
      />
      <PopupFilter
        selected={selectedTypes}
        setSelected={setSelectedTypes}
        options={transactionTypeOptions}
        labels={{ item: 'Transaction Type' }}
      />
      <TransactionDateFilter />
    </Stack>
  );
}

export function TransactionDateFilter() {
  const [selectedDate, setSelectedDate] = useRecoilState(transactionsDateFilterState);
  const [selectedStartingDate, setSelectedStartingDate] = useState<Date | null>(
    () => new Date(selectedDate.fromDate)
  );
  const [selectedEndingDate, setSelectedEndingDate] = useState<Date | null>(
    () => new Date(selectedDate.toDate)
  );

  const dateFormatter = FormatterService.get().getFormatterForModel(DateFormattersConfig.dateNumeric);

  useEffect(() => {
    if (selectedStartingDate && selectedEndingDate) {
      setSelectedDate({
        fromDate: selectedStartingDate.toISOString(),
        toDate: selectedEndingDate.toISOString(),
      });
    }
  }, [selectedStartingDate, selectedEndingDate, setSelectedDate]);

  return (
    <DateRangeSelector
      setSelectedStartingDate={setSelectedStartingDate}
      setSelectedEndingDate={setSelectedEndingDate}
      textContent={
        selectedStartingDate && selectedEndingDate
          ? `${dateFormatter(selectedStartingDate)} - ${dateFormatter(selectedEndingDate)}`
          : 'Select Date Range'
      }
      style={{ justifySelf: 'end' }}
      initialStartDate={new Date(selectedDate.fromDate)}
      initialEndDate={new Date(selectedDate.toDate)}
      shortcuts={DefaultShortcuts}
    />
  );
}
