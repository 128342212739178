import * as yup from 'yup';
import { merge } from 'lodash-es';
import {
  investmentDateField,
  investmentTypeField,
  seniorityField,
  shareClassNameField,
  ViewModelInvestmentType,
} from './common-schema-defs';
import { abstractShareClassSchema } from './AbstractShareClass.schema';

export const CommonShareClassSchema = {
  create() {
    return abstractShareClassSchema().shape({
      type: investmentTypeField().default(ViewModelInvestmentType.common),
      name: shareClassNameField().default('Common Stock'),
      seniority: seniorityField(),
      authorizedShares: yup.number().nullable().optional().label('Authorized Shares').default(null),
      investmentDate: investmentDateField(),
    });
  },
};

export type CommonShareClass = yup.InferType<ReturnType<typeof CommonShareClassSchema.create>>;

export function createCommonShareClass(overrides: Partial<CommonShareClass> = {}): CommonShareClass {
  return merge({}, CommonShareClassSchema.create().getDefault(), overrides);
}
