import { FC } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { CHART_COMPONENTS } from '../../../../types';
import { IInvestmentDataModel } from '../../../../data-models/investment.data-model';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { useCurrentViewMetrics } from '../../hooks/useCurrentViewMetrics';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { FeatureFlags } from '../../../../util/feature-flags';
import { IrrChart } from './IrrChart/IrrChart';
import { MoicBySectorChart } from './MoicBySectorChart/MoicBySectorChart';
import { IrrBySector } from './IrrBySector';
import { IrrByFundChart } from './IrrByFundChart';
import { MostRecentRounds } from './MostRecentRoundsChart';
import { MostRecentRoundsRoundTracker } from './MostRecentRoundsChartRoundTracker';
import { InvestmentAmountByStateChart } from './InvestmentAmountByStateChart';
import { AmountOfDealsOverTimeChart } from './AmountOfDealsOverTimeChart';
import { InvestmentAmountBySectorChart } from './InvestmentAmountBySectorChart';
import { MostCommonCoInvestors } from './MostCommonCoInvestors';
import { InvestmentAmountOverTime } from './InvestmentAmountOverTime';
import { ValuationBridgeChart } from './ValuationBridgeChart';
import { AmountOfDealsOverTimeChartRoundTracker } from './AmountOfDealsOverTimeChartRoundTracker';
import { MetricsByUserChart } from './MetricsByUserChart/MetricsByUserChart';

export interface IPortfolioChartProps<T> {
  metrics: T[];
  filteredMetrics: T[];
}

const componentsMap: Record<
  CHART_COMPONENTS,
  FC<IPortfolioChartProps<IMetricsDataModel>> | FC<IPortfolioChartProps<IInvestmentDataModel>>
> = {
  [CHART_COMPONENTS.IRR]: IrrChart,
  [CHART_COMPONENTS.MOIC_BY_SECTOR]: MoicBySectorChart,
  [CHART_COMPONENTS.VALUATION_BRIDGE]: ValuationBridgeChart,
  [CHART_COMPONENTS.IRR_BY_FUND]: IrrByFundChart,
  [CHART_COMPONENTS.IRR_BY_SECTOR]: IrrBySector,
  [CHART_COMPONENTS.MOST_RECENT_ROUNDS]: MostRecentRounds,
  [CHART_COMPONENTS.MOST_RECENT_ROUNDS_RT]: MostRecentRoundsRoundTracker,
  [CHART_COMPONENTS.MOST_COMMON_CO_INVESTORS]: MostCommonCoInvestors,
  [CHART_COMPONENTS.INVESTMENT_AMOUNT_BY_STATE]: InvestmentAmountByStateChart,
  [CHART_COMPONENTS.AMOUNT_OF_DEALS_OVER_TIME]: AmountOfDealsOverTimeChart,
  [CHART_COMPONENTS.AMOUNT_OF_DEALS_OVER_TIME_RT]: AmountOfDealsOverTimeChartRoundTracker,
  [CHART_COMPONENTS.INVESTMENT_AMOUNT_BY_SECTOR]: InvestmentAmountBySectorChart,
  [CHART_COMPONENTS.INVESTMENT_AMOUNT_OVER_TIME]: InvestmentAmountOverTime,
  [CHART_COMPONENTS.METRICS_BY_USER]: MetricsByUserChart,
};

interface Props {
  id: CHART_COMPONENTS;
}
export const ChartComponent: FC<Props> = ({ id }) => {
  const { metrics, filteredMetrics } = useCurrentViewMetrics();
  const flags = useFlags();

  if (!flags[FeatureFlags.showMetricsByUserChart]) {
    // eslint-disable-next-line
    // @ts-ignore
    delete componentsMap[CHART_COMPONENTS.METRICS_BY_USER];
  } else {
    componentsMap[CHART_COMPONENTS.METRICS_BY_USER] = MetricsByUserChart;
  }

  const Component = componentsMap[id] as FC<IPortfolioChartProps<unknown>>;

  return !Component ? null : (
    <GenericFallbacksWrapper style={{ gridColumn: 'span 2', maxHeight: '540px' }}>
      <Component metrics={metrics.getValue()} filteredMetrics={filteredMetrics.getValue()} />
    </GenericFallbacksWrapper>
  );
};
