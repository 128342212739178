import * as yup from 'yup';
import { positiveNumMsg, requiredMsg, typeMsg } from '../../utils/validationMessages';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { pricePerShareField } from '../../../../../../../schemas/common-schema-defs';

export function stockIssuanceFields() {
  return {
    transactionDate: transactionDateSchema(),
    pricePerShare: pricePerShareField()
      .nullable()
      .required(requiredMsg('Price Per Share'))
      .min(0, positiveNumMsg('Price Per Share'))
      .typeError(typeMsg('Price Per Share', 'number')),
    valuationImpact: yup
      .boolean()
      .required(requiredMsg('Valuation Impact'))
      .transform((_, val) => (val ? val : false)),
    notes: yup
      .string()
      .nullable()
      .optional()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function stockIssuanceFormSchema() {
  return yup.object().shape(stockIssuanceFields());
}
