import { useMemo } from 'react';
import { AgTable } from '../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { RubricConfig } from '../../../schemas/Rubric.schema';
import { SecurityTableStyleWrapper } from '../../Finance2/Security/SecurityTableStyleWrapper';
import {
  defaultRubricConfigColDefs,
  useRubricConfigColumnDefs,
  useRubricConfigExportParams,
} from './RubricConfigColumnDefs';

interface IRubricConfigGridProps {
  data: RubricConfig[];
}
export function RubricConfigGrid({ data }: IRubricConfigGridProps) {
  const colDefs = useRubricConfigColumnDefs();
  const defaultColDef = useMemo(() => defaultRubricConfigColDefs(), []);
  const { exportParams } = useRubricConfigExportParams();

  return (
    <SecurityTableStyleWrapper style={{ height: '80dvh' }}>
      <FadeInGridWrapper>
        <AgTable
          columnDefs={colDefs}
          rowData={data}
          defaultColDef={defaultColDef}
          defaultExcelExportParams={exportParams}
          defaultCsvExportParams={exportParams}
          rowGroupPanelShow='never'
        />
      </FadeInGridWrapper>
    </SecurityTableStyleWrapper>
  );
}
