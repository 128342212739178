import { useEffect, useState } from 'react';

const getDimensions = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientHeight;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return { width, height };
};

export function useWindowDimensions(): { width: number; height: number } {
  const [windowDimensions, setWindowDimensions] = useState(getDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getDimensions());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
