import { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { updateFund, IUpdateFundFields } from '../../../services/queries/MaggieFundQueries';
import { IFundDataModel } from '../../../data-models/fund.data-model';
import { appConfigState } from '../../../services/state/AppConfigState';
import { getErrorMessage } from '../../../services/queryHelpers';
import { useInvalidateFundMetrics } from '../Waterfall/useInvalidateFundMetrics';
import { getISODate } from '../../../services/queries/MaggieMetricsQueries';
import { selectedDateFPState, selectedFundStateFP } from './FPState';

export function useUpdateFund() {
  const { pushErrorToast } = useToastMessageState();
  const invalidateFundMetrics = useInvalidateFundMetrics();
  const selectedDate = useRecoilValue(selectedDateFPState);

  const syncState = useRecoilCallback(
    ({ set }) =>
      (fund: IFundDataModel) => {
        set(appConfigState, (curr) => ({
          ...curr,
          funds: curr.funds.map((f) => (f.id === fund.id ? fund : f)),
        }));
        set(selectedFundStateFP, fund);
      },
    []
  );

  return useCallback(
    async (fund: IUpdateFundFields) => {
      try {
        const updatedFund = await updateFund(fund);
        syncState(updatedFund);
        await invalidateFundMetrics(getISODate(selectedDate), updatedFund.id);
        return updatedFund;
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to update fund') });
      }
    },
    [syncState, invalidateFundMetrics, selectedDate, pushErrorToast]
  );
}
