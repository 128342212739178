import { useCallback } from 'react';
import { atom, atomFamily, selector, selectorFamily, useResetRecoilState } from 'recoil';
import { endOfDay } from 'date-fns';
import { IFundMetricsResponseDataModel } from '../../../data-models/fund-metrics.data-model';
import {
  getFundContributions,
  getFundMetrics,
  getISODate,
} from '../../../services/queries/MaggieMetricsQueries';
import { fundsByIdMapState } from '../../../services/state/AppConfigState';
import { FundViewModel } from '../../../schemas/FundViewModel.schema';
import { FundData } from '../../../schemas/FundData.schema';
import { fetchFundData } from '../../../services/queries/MaggieFundQueries';
import { Fund } from '../../../schemas/Fund.schema';
import { FundContributionsResponse } from '../../../schemas/FundContributions.schema';
import { forceFundMetricsUpdate } from '../../PortfolioOverview/state/MetricsState';

export const selectedFundIdStateFP = atom<number | null>({
  key: 'selectedFundIdStateFP',
  default: null,
});

export const selectedFundStateFP = atom<Fund | null>({
  key: 'selectedFundStateFP',
  default: selector<Fund | null>({
    key: 'selectedFundStateFP/default',
    get: ({ get }) => {
      const fundId = get(selectedFundIdStateFP);
      return get(fundsByIdMapState).get(fundId ?? -1) ?? null;
    },
  }),
});

export const DefaultSelectedDateFP = endOfDay(new Date());
export const selectedDateFPState = atom<Date>({
  key: 'selectedDateFPState',
  default: DefaultSelectedDateFP,
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue) => {
        setSelf(endOfDay(newValue));
      });
    },
  ],
});

type SelectedFundMetricsFPKey = {
  date: Date;
  fundId: number | null;
};
export const selectedFundMetricsStateFP = atomFamily<
  IFundMetricsResponseDataModel | null,
  SelectedFundMetricsFPKey
>({
  key: 'selectedFundMetricsStateFP',
  default: selectorFamily({
    key: 'selectedFundMetricsStateFP/default',
    get:
      ({ date, fundId }) =>
      ({ get }) => {
        if (!fundId) return null;
        get(forceFundMetricsUpdate);
        return getFundMetrics(getISODate(date), [fundId]);
      },
  }),
});

export const fundDataStateFP = atomFamily<FundData[], number>({
  key: 'selectedFundDataStateFP',
  default: selectorFamily({
    key: 'selectedFundDataStateFP/default',
    get: (fundId) => () => {
      return fetchFundData(fundId);
    },
  }),
});

export const fundDataByDateState = selectorFamily<Map<string, FundData>, number>({
  key: 'fundDataByDateState',
  get:
    (fundId) =>
    ({ get }) => {
      const fundData = get(fundDataStateFP(fundId));
      return fundData.reduce((acc, data) => {
        return acc.set(data.date, data);
      }, new Map<string, FundData>());
    },
});

export const fundDataSortedByDateDescStateFP = selectorFamily<FundData[], number>({
  key: 'fundDataSortedByDateDescStateFP',
  get:
    (fundId) =>
    ({ get }) => {
      return get(fundDataStateFP(fundId)).toSorted(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const latestFundDataStateFP = selectorFamily<FundData | undefined, number>({
  key: 'latestFundDataStateFP',
  get:
    (fundId) =>
    ({ get }) => {
      const asOf = get(selectedDateFPState);
      return get(fundDataSortedByDateDescStateFP(fundId))?.find(
        (data) => new Date(data.date).getTime() <= new Date(asOf).getTime()
      );
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const contributionsDistributionsState = atom<FundContributionsResponse | null>({
  key: 'contributionsDistributionsState',
  default: selector<FundContributionsResponse | null>({
    key: 'contributionsDistributionsState/default',
    get: ({ get }) => {
      const date = get(selectedDateFPState);
      const fundId = get(selectedFundIdStateFP);
      if (!fundId) return null;
      return getFundContributions(getISODate(date), [fundId]).then((data) => data?.at(0) ?? null);
    },
  }),
});

export const fundFormState = atom<Partial<FundViewModel> | null>({
  key: 'FundFormStep1State',
  default: null,
});

export const fundFormCurrentStepState = atom<number>({
  key: 'FundFormCurrentStepState',
  default: 0,
});

export function useResetFundFormState() {
  const resetFundFormState = useResetRecoilState(fundFormState);
  const resetFundFormCurrentStepState = useResetRecoilState(fundFormCurrentStepState);

  return useCallback(() => {
    resetFundFormState();
    resetFundFormCurrentStepState();
  }, [resetFundFormState, resetFundFormCurrentStepState]);
}

export const showFundFormState = atom<boolean>({
  key: 'showFundFormState',
  default: false,
});

export const showWaterfallSettingsState = atom({
  key: 'showWaterfallSettingsState',
  default: false,
});

export const isEditingFundDataState = atom<boolean>({
  key: 'isEditingFundDataState',
  default: false,
});
