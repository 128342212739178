import { styled } from '@mui/material';
import { colors } from '../../../theme/colors';
import { GridStyleWrapper } from '../common-grid-defs/GridStyleWrapper';

export const SecurityTableStyleWrapper = styled(GridStyleWrapper)`
  .ag-root-wrapper {
    --ag-row-background-color: ${colors.primary[0]};
    --ag-odd-row-background-color: ${colors.primary[0]};
    --row-group-expanded-bg: ${colors.primary[10]};
    --row-group-contracted-bg: ${colors.primary[5]};
    --ag-cell-horizontal-border: solid ${colors.neutral[20]};

    --row-group-expanded-bg: #e7e8ffeb;
    --row-group-contracted-bg: #ececfcd5;
  }
`;
