import { ButtonBase, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { theme } from '../../../theme';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { AccountMenu } from '../../AccountMenu/AccountMenu';
import { CurrentUserAvatar } from '../../Avatar/CurrentUserAvatar';
import { Icon, IconTypes } from '../../Icon';
import { isNavigationBarStateOpen } from '../state/NavigationState';
import { PermissionService } from '../../../services/PermissionService';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { useHasAdditionalNav } from '../../../hooks/useHasAdditionalNav';
import { HeaderButton } from '../../../constants/styles';
import { AddDealButton } from './AddModal';
import { NavItem } from './NavItem';
import { MenuToggle } from './MenuToggle';

const MIN_SCROLLABLE_MENU_BREAKPOINT = 174;
export const MENU_WIDTH = 72;
const ITEM_HEIGHT = 72;

const Wrapper = styled('nav')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${MENU_WIDTH}px;
  color: ${theme.colors.primary['0']};
  height: 100vh;
  padding: 1rem 0;
  text-align: left;
  gap: 5%;
  background: #232465;
  backdrop-filter: blur(4px);
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HeaderContentWrapper = styled('div')`
  display: grid;
  grid-template-rows: 34px min-content;
  justify-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 4rem;
`;

const SettingsWrapper = styled('div')<{ height: number }>`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
  z-index: 1;
  background: #232465;
  width: auto;
  height: ${({ height }) => height}px;
`;

const MainMenuWrapper = styled('div')<{ height: number; maxHeight: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  width: 100%;
  height: ${({ height }) => height}px;
  ${({ height, maxHeight }) =>
    height === maxHeight ? '' : 'background: linear-gradient(181.25deg, #232465 0%, #161A46 100%);'}
  ${({ height, maxHeight }) => (height === maxHeight ? '' : 'padding-left: 9px;')}
`;

const AvatarWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 1;
  padding-top: 10px;
  cursor: pointer;
`;

export function Navbar(): ReactElement {
  const { showMappingSection, showDataCollectionModule, showFinance2, showElasticSearchResults } =
    useFlags<MaggieFeatureFlags>();
  const permSvc = PermissionService.get();
  const showAdminPanel = permSvc.hasPermission(PermissionKey.canViewAppAdminPanel);

  const { height } = useWindowDimensions();
  const [mainMenuHeight, setMainMenuHeight] = useState(0);
  const [maxMainMenuHeight, setMaxMainMenuHeight] = useState(0);
  const [scrollableMenuBreakpoint, setScrollableMenuBreakpoint] = useState(MIN_SCROLLABLE_MENU_BREAKPOINT);
  const [settingsMenuHeight, setSettingsMenuHeight] = useState(0);
  const [isOpen, setIsOpen] = useRecoilState(isNavigationBarStateOpen);

  const hasAdditionalNav = useHasAdditionalNav();

  const MAIN_NAV_ITEMS = useMemo(
    () => [
      {
        icon: <Icon type={IconTypes.COMPANIES_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Companies',
        path: ROUTES.COMPANIES,
        showItem: permSvc.hasPermission(PermissionKey.canViewList),
      },
      {
        icon: <Icon type={IconTypes.PORTFOLIO_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Portfolio',
        path: ROUTES.PORTFOLIO,
        showItem: permSvc.hasPermission(PermissionKey.canViewMetrics),
      },
      {
        icon: <Icon type={IconTypes.ANALYSIS_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Analysis',
        path: ROUTES.COMPANY_COMPARISON,
        showItem: permSvc.hasPermission(PermissionKey.hasAnalysisModule),
      },
      {
        icon: <Icon type={IconTypes.FINANCE_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Finance',
        path: `${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}`,
        showItem: showFinance2 && permSvc.hasPermission(PermissionKey.canViewTransaction),
      },
      {
        icon: <Icon type={IconTypes.DEALBOARD_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Pipeline',
        path: `${ROUTES.DEAL_FLOW}/${ROUTES.DEALS}/`,
        showItem: permSvc.hasPermission(PermissionKey.canViewDeal),
      },
      {
        icon: <Icon type={IconTypes.MAPPINGS_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Mappings',
        path: `${ROUTES.MAPPINGS}`,
        showItem: showMappingSection && permSvc.hasPermission(PermissionKey.hasMappingsModule),
      },
      {
        icon: <Icon type={IconTypes.KPI_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'KPIs',
        path: `${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}`,
        showItem: showDataCollectionModule && permSvc.hasPermission(PermissionKey.hasDataCollectionModule),
      },
    ],
    [permSvc, showFinance2, showMappingSection, showDataCollectionModule]
  );

  useEffect(() => {
    let counterMenuHeight = 0;
    for (let index = 0; index < MAIN_NAV_ITEMS.length; index++) {
      const navItem = MAIN_NAV_ITEMS[index];
      if (navItem.showItem) counterMenuHeight += ITEM_HEIGHT;
    }

    const settingsMenuHeight = ITEM_HEIGHT + (showAdminPanel ? 72 : 0);
    setMaxMainMenuHeight(counterMenuHeight);
    setScrollableMenuBreakpoint(MIN_SCROLLABLE_MENU_BREAKPOINT + counterMenuHeight + settingsMenuHeight);
    setSettingsMenuHeight(settingsMenuHeight);
  }, [MAIN_NAV_ITEMS, showAdminPanel]);

  useEffect(() => {
    if (height >= scrollableMenuBreakpoint) {
      setMainMenuHeight(maxMainMenuHeight);
      return;
    }
    const newHeight = maxMainMenuHeight - (scrollableMenuBreakpoint - height);
    setMainMenuHeight(newHeight);
  }, [height, maxMainMenuHeight, scrollableMenuBreakpoint]);

  const handleOnAdditionalMenuClick = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  return (
    <Wrapper data-testid='navbar'>
      <Container>
        <HeaderContentWrapper>
          {hasAdditionalNav ? <MenuToggle isOpen={isOpen} onClick={handleOnAdditionalMenuClick} /> : <div />}
          {permSvc.hasPermission(PermissionKey.canEditDeal) && !showElasticSearchResults && <AddDealButton />}
        </HeaderContentWrapper>
        <MainMenuWrapper height={mainMenuHeight} maxHeight={maxMainMenuHeight}>
          {MAIN_NAV_ITEMS.map(({ icon, text, path, showItem }) =>
            showItem ? <NavItem key={path} icon={icon} text={text} path={path} /> : null
          )}
        </MainMenuWrapper>
        <SettingsWrapper height={settingsMenuHeight}>
          {showAdminPanel && (
            <NavItem
              key={ROUTES.ADMIN_PANEL}
              icon={<Icon type={IconTypes.ADMIN_PANEL_COLORED} color={'rgba(0, 0, 0, 0)'} />}
              text='Admin Panel'
              path={ROUTES.ADMIN_PANEL}
            />
          )}
          {!showElasticSearchResults && <UserMenu />}
        </SettingsWrapper>
      </Container>
    </Wrapper>
  );
}

const RoundedButton = css`
  border-radius: 50%;
  & .MuiAvatar-circular {
    ${HeaderButton};
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showElasticSearchResults } = useFlags<MaggieFeatureFlags>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {showElasticSearchResults ? (
        <ButtonBase
          css={RoundedButton}
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <CurrentUserAvatar size='mediumLarge' />
        </ButtonBase>
      ) : (
        <AvatarWrapper
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <CurrentUserAvatar size={'medium'} style={{ color: 'black' }} />
        </AvatarWrapper>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
      >
        <AccountMenu onCloseMenu={handleCloseMenu} />
      </Menu>
    </>
  );
}
