import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { requiredMsg, urlMsg } from '../../utils/validationMessages';
import { VALID_URL_REGEX } from '../../../../../../../util/regex';
import { ICompanyDataModel } from '../../../../../../../data-models/company.data-model';

export const CompanySchema = yup.object().shape({
  name: yup.string().required(requiredMsg('Name')),
  website: yup
    .string()
    .optional()
    .matches(VALID_URL_REGEX, urlMsg('Website'))
    .nullable()
    .transform((_, val) => (val ? val : '')),
  city: yup
    .string()
    .required(requiredMsg('Location'))
    .transform((_, val) => (val ? val : '')),
  state: yup
    .string()
    .optional()
    .nullable()
    .transform((_, val) => (val ? val : '')),
  country: yup
    .string()
    .required('Please enter a valid location')
    .transform((_, val) => (val ? val : '')),
  ceoName: yup
    .string()
    .required(requiredMsg('CEO Name'))
    .transform((_, val) => (val ? val : '')),
  sectorId: yup
    .number()
    .nullable()
    .required(requiredMsg('Sector'))
    .transform((_, val) => (!isNaN(val) ? val : null)),
  internalDescription: yup
    .string()
    .optional()
    .transform((_, val) => (val ? val : '')),
  shortDescription: yup
    .string()
    .optional()
    .nullable()
    .transform((_, val) => (val ? val : '')),
  dealLeadId: yup
    .number()
    .nullable()
    .required(requiredMsg('Deal Lead'))
    .transform((_, val) => (val ? val : null)),
  dealTeamIds: yup
    .array()
    .min(1, 'Please select at least one deal team member')
    .required(requiredMsg('Deal Team'))
    .transform((_, val) => (val ? val : [])),
  sourceType: yup
    .string()
    .optional()
    .nullable()
    .transform((_, val) => (val ? val : '')),
  internalSourceId: yup
    .number()
    .optional()
    .nullable()
    .transform((_, val) => (val ? val : null)),
  source: yup.string().when('sourceType', {
    is: (sourceType: string) => sourceType !== 'direct',
    then: (schema) =>
      schema
        .optional()
        .nullable()
        .transform((_, val) => (val ? val : '')),
  }),
  sourceCompany: yup.string().when('sourceType', {
    is: (sourceType: string) => sourceType !== 'direct',
    then: (schema) =>
      schema
        .optional()
        .nullable()
        .transform((_, val) => (val ? val : '')),
  }),
  boardStatus: yup
    .string()
    .optional()
    .nullable()
    .transform((_, val) => (val ? val : '')),
  boardDirectorId: yup
    .number()
    .optional()
    .nullable()
    .transform((_, val) => (val ? val : null)),
}) as ObjectSchema<Partial<ICompanyDataModel>>;
