import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useLoadingBarState } from '../../../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../../../components/ToastMessage/ToastMessageProvider';
import {
  IKPIRequestDataModel,
  IKPIResponseActionPayload,
  KPIRequestStatus,
} from '../../../../../../data-models/kpi-requests.data-model';
import {
  acceptKPIRequestResponse,
  bulkAcceptResponses,
  createKPIRequestResponse,
  fetchKPIRequestS3FileUploadUrl,
  rejectKPIRequestResponse,
  updateKPIRequestResponse,
  updateKpiRequest,
} from '../../../../../../services/queries/KPIRequestsQueries';
import { getErrorMessage } from '../../../../../../services/queryHelpers';
import { LoadingId } from '../../../../../../types';
import { uploadToS3 } from '../../../../../CompanyComparison/components/UploadGaCSV/hooks/useUploadToS3';
import {
  selectedRequestState,
  showModalStateKPI,
  KpiAction,
} from '../../../../../../services/state/KPI/KPIRequestsState';
import { ROUTES } from '../../../../../../constants/RouteNameMapping';
import { useInvalidateKPIRequests } from './useInvalidateKPIRequests';

type KPIRequestResponsePayload = {
  id?: number;
  requestId: string;
  responseFormData: IKPIResponseActionPayload;
  autosave?: boolean;
};

export function useKPIRequestResponseActions() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast } = useToastMessageState();
  const invalidateKpiRequests = useInvalidateKPIRequests();

  const handleBulkAccept = useCallback(
    async (requestIds: number[]) => {
      try {
        await bulkAcceptResponses(requestIds);
        await invalidateKpiRequests();
      } catch (err) {
        const message = getErrorMessage(err, 'Bulk accept failed');
        pushErrorToast({ message });
      }
    },
    [invalidateKpiRequests, pushErrorToast]
  );

  const createRequestResponse = useCallback(
    async (payload: KPIRequestResponsePayload) => {
      const { requestId, responseFormData: respondFormData, autosave } = payload;

      actions.startLoading(LoadingId.createKPIRequestResponse);

      try {
        return await createKPIRequestResponse(requestId, respondFormData, autosave);
      } catch (err) {
        const message = getErrorMessage(err, `Failed to submit`);

        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.createKPIRequestResponse);
      }
    },
    [actions, pushErrorToast]
  );

  const updateRequestResponse = useCallback(
    async (payload: KPIRequestResponsePayload) => {
      const { requestId, responseFormData: respondFormData, autosave } = payload;

      actions.startLoading(LoadingId.updateKPIRequestResponse);

      try {
        return await updateKPIRequestResponse(requestId, respondFormData, autosave);
      } catch (err) {
        const message = getErrorMessage(err, `Failed to update response`);

        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.updateKPIRequestResponse);
      }
    },
    [actions, pushErrorToast]
  );

  const acceptRequestResponse = useCallback(
    async (requestId: string) => {
      actions.startLoading(LoadingId.acceptKPIRequestResponse);

      try {
        return await acceptKPIRequestResponse(requestId);
      } catch (err) {
        const message = getErrorMessage(err, `Failed to accept the request`);

        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.acceptKPIRequestResponse);
      }
    },
    [actions, pushErrorToast]
  );

  const rejectRequestResponse = useCallback(
    async (requestId: string) => {
      actions.startLoading(LoadingId.rejectKPIRequestResponse);

      try {
        return await rejectKPIRequestResponse(requestId);
      } catch (err) {
        const message = getErrorMessage(err, `Failed to reject the request`);

        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.rejectKPIRequestResponse);
      }
    },
    [actions, pushErrorToast]
  );

  const uploadFileRequestResponse = useCallback(
    async ({ companyId, file }: { companyId: number; file: File }) => {
      try {
        // Get AWS S3 signed url
        const responseFileUpload = await fetchKPIRequestS3FileUploadUrl({
          contentType: file.type,
          companyId,
        });

        // Upload file to S3 using signed url
        await uploadToS3(file, responseFileUpload.signedUrl);

        return {
          contentType: file.type,
          fileId: responseFileUpload.fileId,
        };
      } catch (err) {
        const message = getErrorMessage(err, `Failed to upload ${file.name}`);
        pushErrorToast({ message });

        return false;
      }
    },
    [pushErrorToast]
  );

  return {
    uploadFileRequestResponse,
    createRequestResponse,
    acceptRequestResponse,
    updateRequestResponse,
    rejectRequestResponse,
    handleBulkAccept,
  };
}

export function useMoveBackToReview() {
  const { pushErrorToast } = useToastMessageState();
  const invalidateKpiRequests = useInvalidateKPIRequests();
  const navigate = useNavigate();

  return useCallback(
    async (request: IKPIRequestDataModel) => {
      try {
        await updateKpiRequest(request.id, {
          ...request,
          status: KPIRequestStatus.Submitted,
          deletedAt: null,
        });
        await invalidateKpiRequests();
        navigate(`/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`);
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, `Failed to move back to review`) });
      }
    },
    [invalidateKpiRequests, navigate, pushErrorToast]
  );
}

export function useRequestActions() {
  const [selectedRequest, setSelectedRequest] = useRecoilState(selectedRequestState);
  const [modalAction, setModalAction] = useRecoilState(showModalStateKPI);
  const resetState = useResetKpiState();

  const onSelectAction = useCallback(
    (request: IKPIRequestDataModel, action: KpiAction) => {
      setSelectedRequest(request);
      setModalAction(action);
    },
    [setSelectedRequest, setModalAction]
  );

  const onCloseModal = useCallback(() => {
    resetState();
  }, [resetState]);

  return { onSelectAction, onCloseModal, modalAction, selectedRequest };
}

export function useResetKpiState() {
  const resetModalState = useResetRecoilState(showModalStateKPI);
  const resetSelectedRequest = useResetRecoilState(selectedRequestState);

  return useCallback(() => {
    resetModalState();
    resetSelectedRequest();
  }, [resetModalState, resetSelectedRequest]);
}
