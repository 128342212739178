import { ColDef } from 'ag-grid-community';
import { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { NumericFormatProps } from 'react-number-format';
import { Column, HeaderGroup } from 'react-table';
import { ICompanySearchResponseDataModel } from './data-models/company-search-result.data-model';
import { ICompanyDataModel } from './data-models/company.data-model';
import { ICurrencyDataModel } from './data-models/currency.data-model';
import { IDealReasonDataModel } from './data-models/deal-reason.data-model';
import { IDealTypeDataModel } from './data-models/deal-type.data-model';
import { IDealDataModel } from './data-models/deal.data-model';
import { IFundDataModel } from './data-models/fund.data-model';
import { IMetricsDataModel } from './data-models/metrics.data-model';
import { IPageConfigDataModel } from './data-models/page-config.data-model';
import { IRoundDataModel } from './data-models/round.data-model';
import { TransactionType } from './data-models/transaction-type.data-model';
import { ITransactionDataModel } from './data-models/transaction.data-model';
import { IUserDataModel } from './data-models/user.data-model';
import { ViewTypeByData } from './data-models/view-config.data-model';
import { IDealStageDataModel } from './pages/DealFlow2/data-models/dealStage.data-model';
import { IInvestmentDataModel } from './data-models/investment.data-model';
import { CompanySearchResponse } from './schemas/CompanySearchResponse.schema';

export interface IPendoConfig {
  visitor: {
    id: string; // Required if user is logged in, default creates anonymous ID
    email?: string; // Recommended if using Pendo Feedback, or NPS Email
    full_name?: string; // Recommended if using Pendo Feedback
    role?: string; // Optional
    environment?: string; // Optional,
    screenSize?: string;
    appSize?: string; // The available application size (excluding browser bars)
  };
  account: {
    id: string; // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
    name?: string;
    is_paying?: string; // Recommended if using Pendo Feedback
    monthly_value?: string; // Recommended if using Pendo Feedback
    planLevel?: string;
    planPrice?: string;
    creationDate?: string;

    // You can add any additional account level key-values here,
    // as long as it's not one of the above reserved names.
  };
}

declare global {
  interface Window {
    pendo: {
      initialize: (config: IPendoConfig) => void;
      track: (trackType: string) => void;
    };
  }
}

export const enum LoadingStatus {
  error = 'error',
  idle = 'idle',
  loading = 'loading',
  success = 'success',
}

export type SelectItem = {
  id: number | string;
  value: string;
  group?: string;
  iconType?: string;
  hasSeparator?: boolean;
};

export type ViewItem = {
  id: number | string;
  value: string;
  iconType?: string;
  hasSeparator?: boolean;
  viewType?: ViewTypeByData;
  isCompany?: boolean;
};

export interface RawPageConfig {
  config: IPageConfigDataModel;
}

export interface TransactionResponse {
  companies: ICompanyDataModel[];
  transactionTypes: TransactionType[];
  rounds: IRoundDataModel[];
  funds: IFundDataModel[];
  transactions: ITransactionDataModel[];
}

// FIXME: remove this when we use id's instead of objects for other fields as well, and switch from ReactQuery to Recoil only
export interface TransactionTWithCompanies {
  transactions: ITransactionDataModel[];
  companies: ICompanyDataModel[];
}

export interface Audit {
  id: number;
  currencyid: number;
  exchangeRate: string;
  transactionId: number;
  companyId: number;
  fundId: number;
  transactionTypeId: number;
  transactionDate: string;
  status: string | null;
  roundId: number | null;
  pricePerShare: number | null;
  noOfShares: number | null;
  valuationImpact: boolean;
  exchangedSharesNo: number | null;
  exchangedPricePerShare: number | null;
  exchangedRoundId: number | null;
  receivedSharesNo: number | null;
  receivedPricePerShare: number | null;
  receivedRoundId: number | null;
  paymentInKind: boolean;
  miscPaymentAmount: number | null;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  deadLeadId: string | number | null;
  newSharesIssued: number | null;
  fullyDilutedShares: null;
  postMoneyValuation: null;
  valuationDate: null;
  fmvBasis: null;
  principalAmount: null;
  coupon: null;
  couponType: 'Simple';
  compoundingFrequency: 'Quarterly';
  maturityDate: null;
  valuationCap: null;
  discountRate: null;
  pikStartDate: null;
  pikEndDate: null;
  pikCadence: 'Quarterly';
  triggerPrice: null;
  convertedRoundId: null;
  convertedPrincipalAmount: null;
  convertedSharesNo: null;
  convertedPricePerShare: null;
  convertedInterestAmount: null;
  exercisedWarrantsNo: null;
  optionsStrikePrice: null;
  interestPaymentAmount: null;
  pricipalPaymentAmount: null;
  linkedNoteId: null;
  linkedWarrantIssuanceId: null;
  linkedOptionId: number | null;
  miscPaymentType: null;
  optionsExercisePrice: null;
  optionsExercisedNo: null;
  optionsExpiryDate: null;
  optionsIssuedNo: null;
  warrantExpiryDate: null;
  warrantVested: boolean | null;
  warrantsIssued: null;
  notes: null;
  currencyId: number | null;
}
export interface TransactionAudit extends Audit {
  currency: ICurrencyDataModel;
  round: IRoundDataModel;
  transactionType: TransactionType;
  fund: IFundDataModel;
  escrowAmount: number | null | undefined;
  exchangedRound: IRoundDataModel | null;
  receivedRound: IRoundDataModel | null;
  convertedRound: IRoundDataModel | null;
  valuationAmount: number | null;
  investmentAmount: number | null;
  receivedAmount: number | null;
  exchangedAmount: number | null;
}

export interface Sector {
  id: number;
  name: string;
  displayName: string;
  sortOrder: number;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
}

export type KPI = Record<KPI_COMPONENTS, Kpi>;

export enum PACKAGE_VIEW_TYPE {
  KPI_CHARTS = 'KPI Charts',
  DASHBOARD = 'Dashboard',
  CAC_PAYBACKS = 'CAC & Paybacks',
  PAYBACK_CHART = 'Payback Chart',
  COHORT_ANALYSIS = 'Cohort Analysis',
}
/**@deprecated */
export enum Formatting {
  Currency = 'currency',
  Percent = 'percent',
  Numeric = 'numeric',
  Multiplier = 'multiplier',
}

export type TableCompany = {
  id: number | string;
  name: string;
  logoUrl: string;
};

export enum KPI_COMPONENTS {
  CAPITAL_LOSS_RATIO = 'kpi_capital_loss_ratio',
  COUNT_OF_ACTIVE_COMPANIES = 'component_kpi_count_of_active_companies',
  COUNT_OF_COMPANIES = 'component_kpi_count_of_companies',
  COUNT_OF_COMPANIES_RT = 'component_kpi_count_of_companies_rt',
  COUNT_OF_DEALS_RT = 'component_kpi_count_of_deals_rt',
  FMV = 'fmv',
  IMPAIRMENT_RATIO = 'kpi_impairment_ratio',
  IRR = 'component_kpi_irr',
  LOSS_RATIO = 'component_loss_ratio',
  MOIC = 'component_kpi_moic',
  NUMBER_OF_MARKUPS = 'component_kpi_number_of_markups',
  POTENTIAL_UPSIDE = 'component_kpi_potential_upside',
  PROJECTED_MOIC = 'component_kpi_projected_moic',
  REALIZED_COST = 'realized_cost',
  TOTAL_COST = 'total_cost',
  TOTAL_INVESTMENT_AMOUNT = 'component_kpi_invested',
  TOTAL_INVESTMENT_AMOUNT_RT = 'component_kpi_invested_rt',
  TOTAL_PROJECTED_VALUE = 'component_kpi_total_projected_val',
  TOTAL_REALIZED_VALUE = 'component_kpi_total_realized_val',
  TOTAL_UNREALIZED_VALUE = 'component_kpi_total_unrealized_val',
  TOTAL_VALUE = 'component_kpi_total_val',
  UNREALIZED_COST = 'unrealized_cost',
}

export enum CHART_COMPONENTS {
  VALUATION_BRIDGE = 'component_chart_valuation_bridge',
  IRR_BY_FUND = 'component_chart_irr_by_fund',
  IRR_BY_SECTOR = 'component_chart_irr_by_sector',
  MOIC_BY_SECTOR = 'component_chart_moic',
  IRR = 'component_chart_irr',
  MOST_RECENT_ROUNDS = 'component_chart_most_recent_rounds',
  MOST_RECENT_ROUNDS_RT = 'component_chart_most_recent_rounds_rt',
  MOST_COMMON_CO_INVESTORS = 'component_chart_most_common_co_investors',
  AMOUNT_OF_DEALS_OVER_TIME = 'component_chart_amount_of_deals_over_time',
  AMOUNT_OF_DEALS_OVER_TIME_RT = 'component_chart_amount_of_deals_over_time_rt',
  INVESTMENT_AMOUNT_BY_STATE = 'component_chart_investment_amount_by_state',
  INVESTMENT_AMOUNT_BY_SECTOR = 'component_chart_investment_amount_by_sector',
  INVESTMENT_AMOUNT_OVER_TIME = 'component_chart_investment_amount_over_time',
  METRICS_BY_USER = 'component_chart_metrics_by_user',
}

export type KpiSourceData = {
  rawCompanies: IMetricsDataModel[]; // TODO: rename to metrics
  filteredData?: IInvestmentDataModel[];
  date: Date;
};

export interface KpiConfigMeta {
  id: KPI_COMPONENTS;
  title: string;
  type: string;
  description?: string;
}

export interface Kpi extends KpiConfigMeta {
  value: number;
  formatted: string;
}

export type KpiCalculationParams = {
  data?: KpiSourceData;
  kpis?: KPI;
};
export interface KpiConfig {
  getMeta: () => KpiConfigMeta;
  calculate: (dataAndKpis: KpiCalculationParams) => Kpi;
}

export type TQuoter = { start: Date; end: Date; label: string };

export interface ColumnMenuItem {
  id: string | number;
  name: string;
  displayName: string;
  description: string;
}

export type ColumnType = 'string' | 'link' | 'date' | 'number' | 'stringArray';

export interface ColumnMeta {
  aggFunc: string | null;
  aggregation: boolean;
  createdAt: string;
  createdBy: string | null;
  default: boolean;
  description: string;
  displayName: string;
  editable: boolean;
  exportable: boolean;
  filterable: boolean;
  formatting: string;
  groupName: string;
  groupOrder: number;
  groupPriority: number;
  groupable: boolean;
  id: number;
  meta?: { isSet?: boolean; size?: 'S' | 'M' | 'L'; includeInQuickSearch?: boolean };
  name: string;
  options: string;
  path: string;
  required: boolean;
  sortable: boolean;
  tableOrder: number | null;
  type: ColumnType;
  updatedAt: string;
  updatedBy: string | null;
  visible: boolean;
}

export function createColumMeta(overrides: Partial<ColumnMeta> = {}) {
  return {
    aggFunc: null,
    aggregation: false,
    createdAt: '',
    createdBy: null,
    default: false,
    description: '',
    displayName: '',
    editable: false,
    exportable: false,
    filterable: false,
    formatting: 'text',
    groupName: '',
    groupOrder: 0,
    groupPriority: 0,
    groupable: false,
    id: 0,
    name: '',
    options: '',
    path: '',
    required: false,
    sortable: false,
    tableOrder: null,
    type: 'string' as ColumnType,
    updatedAt: '',
    updatedBy: null,
    visible: false,
    ...overrides,
  };
}

export enum LoadingId {
  bulkAssignTemplate = 'bulkAssignTemplate',
  createCompany = 'createCompany',
  createCustomField = 'createCustomField',
  createOrEditRubric = 'createOrEditRubric',
  createTemplateAssignmentRequest = 'createTemplateAssigmentRequest',
  createDashboard = 'createDashboard',
  createDeal = 'createDeal',
  createDealLabel = 'createDealLabel',
  createTransaction = 'createTransaction',
  deleteTemplateAssignmentRequest = 'deleteTemplateAssignmentRequest',
  deleteDeal = 'deleteDeal',
  deleteTransaction = 'deleteTransaction',
  deleteView = 'deleteView',
  duplicateDashboard = 'duplicateDashboard',
  duplicateView = 'duplicateView',
  loadCompany = 'loadCompany',
  refreshAllDeals = 'refreshAllDeals',
  saveNote = 'saveNote',
  sendKPIRequests = 'sendKPIRequests',
  deleteKPIRequest = 'deleteKPIRequest',
  deleteNote = 'deleteNote',
  deleteCustomField = 'deleteCustomField',
  moveBackToReview = 'moveBackToReview',
  updateTemplateAssignmentRequest = 'updateTemplateAssignmentRequest',
  updateCustomField = 'updateCustomField',
  updateCompany = 'updateCompany',
  updateCompanyFund = 'updateCompanyFund',
  updateKPITemplate = 'updateKPITemplate',
  createKPITemplate = 'createKPITemplate',
  updateDeal = 'updateDeal',
  updateListItem = 'updateListItem',
  updateTransaction = 'updateTransaction',
  updateView = 'updateView',
  uploadFile = 'uploadFile',
  addListItem = 'addListItem',
  deleteCompanyList = 'deleteCompanyList',
  createKPIRequestResponse = 'createKPIRequestResponse',
  updateKPIRequestResponse = 'updateKPIRequestResponse',
  acceptKPIRequestResponse = 'acceptKPIRequestResponse',
  rejectKPIRequestResponse = 'rejectKPIRequestResponse',
  updateScenario = 'updateScenario',
  createScenario = 'createScenario',
  deleteScenario = 'deleteScenario',
  updateFundData = 'waterfallSettings',
  updateFundMetrics = 'updateFundMetrics',
}
export interface ToastProps {
  message: string;
  linkText?: string;
  linkUrl?: string;
  title?: string;
  autoHideDuration?: number;
  buttonLabel?: string;
  buttonAction?: () => void;
  buttonIconType?: string;
}

export enum ToastMessageType {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success',
}

export enum PortalPosition {
  left = 'left',
  right = 'right',
}

export interface Coords {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

export interface PredefinedColumn {
  getMeta: () => ColumnMeta;
  getDefinition: () => ColDef | null;
}

export enum SharedButtonStatus {
  shared = 'Remove',
  removed = 'Undo',
  owner = 'Owner',
}

export interface TSharedUser extends SelectItem {
  type: SharedButtonStatus;
}

export enum ANALYSIS_TYPE {
  GROWTH_ANALYSIS = 'growth_analysis',
  COMPETITIVE_INTELLIGENCE = 'competitive_intelligence',
}

export type CustomColumn = Column<TableData>;

export type TableData = {
  rowConfig?: RowConfig;
  [x: string]: unknown;
};

export type TransactionHistoryTableData = ITransactionDataModel | TransactionAudit;

export type RowConfig = {
  highlighted?: boolean;
  footer?: boolean;
  indent?: boolean;
};
export interface CustomHeader<D extends object> extends HeaderGroup<D> {
  logoUrl: string;
}

export type GAKpiRecord = {
  data: {
    header: string;
    value: number | null;
  }[];
  formatting: Formatting;
};

export interface GATableData {
  headers: string[];
  rows: string[];
  rowItem: Record<string, GAKpiRecord>;
}
export interface GATableDataRaw extends GATableData {
  name: string;
}

export type GAKpiItem = {
  header: string;
  value: number | null;
};

export interface GAKpiChartData {
  headers: string[];
  formatting: string;
  rows?: string[];
  items: GAKpiItem[];
}

export interface GAKpiChartRawData extends GAKpiChartData {
  name: string;
}

export type ChartFormatData = Record<string, string | number | null> & { period: string };

export type GARaw = (GATableDataRaw | GAKpiChartRawData)[];

export type GA = Record<string, GATableData | GAKpiChartData>;

export type MappedGA = {
  companyId: string | number;
  companyName: string;
  isActive: boolean;
  logo: string;
  data: GA;
};

export type TStatus = {
  month: number;
  year: number;
  uploadedDate: string;
};

export type TGaStatus = {
  arrStatus: TStatus | null;
  kpiStatus: TStatus | null;
};

export type UploadItem = {
  company: ICompanySearchResponseDataModel;
  gaStatus: TGaStatus;
  hasData: boolean;
  isUploaded: boolean;
};

export enum TRANSACTION_SUB_TYPES {
  // Equity
  BUY = 'Buy',
  SECONDARY_PURCHASE = 'Secondary Purchase',
  SELL = 'Sell',
  SHARE_EXCHANGE = 'Share Exchange',

  // Escrow
  ESCROW_RECEIVED = 'Escrow Received',
  ESCROW_WRITE_OFF = 'Escrow WriteOff',

  // Fund
  CAPITAL_CALL = 'Capital Call',

  // Misc
  INTERNAL_VALUATION = 'Internal Valuation',
  STOCK_ISSUANCE = 'Stock Issuance',
  MISC_OWNERSHIP_CHANGE = 'Ownership Change',
  MISC_PAYMENT = 'Misc Payment',
  MISC_WRITE_OFF = 'Write Off',

  // Note
  NOTE_CONVERSION = 'Note Conversion',
  NOTE_ISSUANCE = 'Note Issuance',
  SAFE_CONVERSION = 'SAFE Conversion',
  SAFE_ISSUANCE = 'SAFE',
  NOTE_EXPIRATION = 'Note Expiration',
  SAFE_EXPIRATION = 'SAFE Expiration',

  // Options
  OPTION_EXERCISE = 'Option Exercise',
  OPTION_ISSUANCE = 'Option Issuance',

  // Token
  TOKEN_BUY = 'Token Buy',
  TOKEN_SAFT = 'SAFT',
  TOKEN_SAFT_CONVERSION = 'SAFT Conversion',

  // Warrant
  WARRANT_EXERCISE = 'Warrant Exercise',
  WARRANT_EXPIRATION = 'Warrant Expiration',
  WARRANT_ISSUANCE = 'Warrant Issuance',
}

export type TransactionPayload = {
  acquirer?: CompanySearchResponse | null;
  companyId: string | number;
  currencyId: number;
  exchangeRate: number;
  fundId: number;
  transactionTypeId: number;
  transactionDate: Date;
  status: string;
  notes?: string;
  dealLeadId?: string;
  roundId?: number;
  pricePerShare?: number;
  noOfShares?: number;
  newSharesIssued?: number;
  fullyDilutedShares?: number;
  valuationImpact?: boolean;
  postMoneyValuation?: number;
  valuationDate?: Date;
  fmvbasis?: string;
  exchangedSharesNo?: number;
  exchangedPricePerShare?: number;
  transactionStatus?: string;
};

export type CoInvestorChartItem = {
  name: string;
  deals: number;
};

export type DataPoint = {
  x: number | string;
  y: number | null;
};

export interface LineChartData {
  id: string;
  data: DataPoint[];
}

export interface PaybackChartData {
  companyName: string;
  companyId: number;
  logo: string;
  data: LineChartData[];
}

export interface HeatMapParams {
  min: number | undefined | null;
  max: number | undefined | null;
  mean: number | undefined | null;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  invalid?: boolean;
  message?: string;
  success?: boolean;
  icon?: JSX.Element;
  flex?: boolean;
  width?: string | number;
}

export type NumericInputProps = InputProps & NumericFormatProps;

export enum INDICATOR_TYPES {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
}

export enum DASHBOARD_TYPES {
  CompetitiveIntelligence = 'competitive-intelligence',
  CompanyProfile = 'company-profile',
}
export interface Dashboard {
  id: number;
  dashboardId: string;
  name: string;
  description: string;
  version: number;
  displayName: string;
  isDefault: boolean;
  config: DashboardConfigObject;
  createdBy?: string | null;
  updatedBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  type?: DASHBOARD_TYPES;
}

export function createDashboard(overrides: Partial<Dashboard> = {}): Dashboard {
  return {
    config: createDashboardConfig(),
    dashboardId: '',
    description: '',
    displayName: '',
    id: 0,
    isDefault: false,
    name: '',
    version: 0,
    ...overrides,
  };
}

export function createDashboardConfig(overrides: Partial<DashboardConfigObject> = {}): DashboardConfigObject {
  return {
    selectedCategories: [],
    ...overrides,
  };
}

export type DashboardCreateDataModel = Omit<Dashboard, 'id'>;

export interface DashboardConfigObject {
  selectedCategories: string[];
  // origin: Dashboard | null; will need for reset dashboards
}
export interface SourceType {
  id: number;
  name: string;
  displayName: string;
  sortOrder: number;
  meta: { required: string[] };
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface ICreatedByAndAt {
  createdBy?: string | null;
  createdAt?: string | null;
}

export interface IUpdatedByAndAt {
  updatedBy?: string | null;
  updatedAt?: string | null;
}

export interface IUpdatedByAtAndCreatedByAt extends IUpdatedByAndAt, ICreatedByAndAt {}

export interface TodoItem {
  id: number;
  name: string;
  sortOrder: number;
  meta: unknown | null; // TODO
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface DealBoardConfig {
  dealStages: IDealStageDataModel[];
  dealTypes: IDealTypeDataModel[];
  sourceTypes: SourceType[];
  passReasons: IDealReasonDataModel[];
  missedReasons: IDealReasonDataModel[];
  todoList: TodoItem[];
}

export interface DealResponse {
  deals: IDealDataModel[];
  companies: ICompanyDataModel[];
  dealTeamUsers: IUserDataModel[];
  round: IRoundDataModel;
}

export interface History {
  id: number;
  dealId: number;
  stageId: number;
  comment: string | null;
  createdBy: string | null;
  createdById: number | null;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface DealComplete extends IDealDataModel {
  round: IRoundDataModel | null;
  fund: IFundDataModel | null;
  stage: IDealStageDataModel | null;
  sector: Sector | null;
}

/**
 * @deprecated use PropsWithChildren from 'react' instead
 */
export interface ComponentWithChildren {
  children: ReactNode;
}

export enum DealBoardViewMode {
  KANBAN = 'kanban',
  GRID = 'grid',
}

export enum DealBoardDealCategory {
  CURRENT = 'current',
  PASS = 'pass',
  TRACK = 'track',
  CLOSED = 'closed',
  MISSED = 'missed',
  UNKNOWN = 'unknown', // Used as a fallback
}

export interface NotificationConfigResponse {
  id: number;
  user: string;
  deal: DealConfig;
  createdAt: Date;
  updatedAt: Date;
}

export interface DealConfig {
  types: Event[];
  events: Event[];
  config: Record<string, string[]>;
}

export interface Event {
  id: string;
  displayName: string;
  sortOrder: number;
}

export type ViewAction = { id: ViewActionName; value: string; icon: ReactElement; hasSeparator?: boolean };

export enum ViewActionName {
  SHARE = 'share',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
  RENAME = 'rename',
  RESET = 'reset',
}
