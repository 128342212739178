import { useCallback, useMemo } from 'react';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainer } from '../../../components/Form/FormComponents';
import { FundViewModel, fundViewModelStep1Schema } from '../../../schemas/FundViewModel.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { fundFormCurrentStepState, fundFormState } from '../state/FPState';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { useSyncFields } from '../../../components/Form/rhf-utils/useSyncFields';
import { IFundFormProps } from './FundForm';

const step1Fields: FieldPath<FundViewModel>[] = [
  '_viewModel.distributionsCalcType',
  '_viewModel.contributionsCalcType',
  'isProceedsPercentAdjusted',
  'lpCommitmentSplit',
  '_viewModel.gpCommitmentPercentage',
  'commitments',
];

interface IFundFormStep1Props extends Pick<IFundFormProps, 'defaultValues'> {}

export function FundFormStep1({ defaultValues }: IFundFormStep1Props) {
  const [formData, setFormData] = useRecoilState(fundFormState);
  const fields = useMemo(() => {
    return schemaToFormFields(fundViewModelStep1Schema(), step1Fields);
  }, []);
  const methods = useForm<FundViewModel>({
    defaultValues: formData ?? defaultValues,
    mode: 'all',
    resolver: yupResolver(fundViewModelStep1Schema()),
  });

  const handleNext = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      setFormData((curr) => ({ ...curr, ...methods.getValues() }));
      return true;
    } else {
      return false;
    }
  }, [methods, setFormData]);

  const { forceRerenderField1, forceRerenderField2 } = useSyncFields({
    methods,
    field1Path: 'lpCommitmentSplit',
    field2Path: '_viewModel.gpCommitmentPercentage',
    calculateField1Value: calculateRemainingPercentage,
    calculateField2Value: calculateRemainingPercentage,
  });

  return (
    <FormProvider {...methods}>
      <FormContainer>
        {fields.map((field) => {
          let key = field.key;
          if (field.key === 'lpCommitmentSplit') {
            key = `${field.key}-${forceRerenderField1}`;
          }
          if (field.key === '_viewModel.gpCommitmentPercentage') {
            key = `${field.key}-${forceRerenderField2}`;
          }
          return <FormFieldWithLabelFactory key={key} formField={field} />;
        })}
      </FormContainer>
      <StepperFormButtons stepIsValid={handleNext} stepState={fundFormCurrentStepState} />
    </FormProvider>
  );
}

function calculateRemainingPercentage(val?: unknown) {
  return typeof val === 'number' ? 100 - val : null;
}
