import { object } from 'yup';
import { merge } from 'lodash-es';
import { Fund, fundFields } from '../../../schemas/Fund.schema';
import { ISimpleChoice } from '../../../data-models/field2.data-model';
import { useUserIdField } from '../../../data-fields/CommonFields';

export function useFPDigestSchema(fund: Fund | null) {
  const fields = fundFields();

  const partnersMeta = useUserIdField({
    key: 'generalPartners',
    multi: true,
    label: 'General Partners',
  });

  if (!fund) return object();

  const strategyValues: ISimpleChoice<string>[] =
    fund.strategy?.map((strategy) => ({
      value: strategy as string,
      displayName: strategy,
    })) ?? [];

  const { generalPartners, strategy } = fields;

  return object()
    .shape({
      ...fields,
      generalPartners: generalPartners.meta({
        ...merge({}, partnersMeta),
      }),

      strategy: strategy.meta({
        ...strategy.meta,
        rendererMeta: {
          allowCustomAdd: true,
          values: strategyValues,
        },
      }),
    })
    .pick([
      'vintage',
      'investedManagementFee',
      'legalName',
      'stepDownDate',
      'taxId',
      'managementFeeTerminationDate',
      'generalPartners',
      'initialManagementFee',
      'investmentPeriodEndDate',
      'lineOfCredit',
      'finalCloseDate',
      'strategy',
    ]);
}
