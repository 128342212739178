import { useCallback, useMemo } from 'react';
import { fetchTransactions, fetchTransactionsByCompany } from '../queries/MaggieTransactionQueries';
import { useSaveCompanyData } from './useSaveCompanyData';

export function useFetchTransactionsAndSaveCompanies(companyId?: number | null) {
  const { batchSaveCompanyData } = useSaveCompanyData();

  const transactionQuery = useMemo(
    () => (companyId != null ? fetchTransactionsByCompany : fetchTransactions),
    [companyId]
  );

  return useCallback(async () => {
    const response = await transactionQuery(companyId != null ? companyId : undefined);
    const companies = response.companies;
    batchSaveCompanyData(companies);
    return response.transactions;
  }, [batchSaveCompanyData, companyId, transactionQuery]);
}
