import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useEffect, useMemo } from 'react';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { selectedCompanyIdProfile } from '../state/UIState';

export function useSyncFromPath() {
  const { pathname } = useLocation();
  const setProfileSelectedCompanyIDState = useSetRecoilState(selectedCompanyIdProfile);

  const companyId = useMemo(() => {
    if (!pathname.startsWith(`/${ROUTES.COMPANY_PROFILES}`)) {
      return -1;
    }
    const rawId = pathname.split('/')[2];
    const matches = rawId.match(/-\d+/gi) as string[];

    return Number(matches[matches.length - 1].slice(1)) ?? -1;
  }, [pathname]);

  useEffect(() => {
    setProfileSelectedCompanyIDState(companyId);
  }, [companyId, setProfileSelectedCompanyIDState]);
}
